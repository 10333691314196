import React, { FC } from "react";
import { Content } from "../components";
import { Aligment, Card, Icon, Section, Text } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";

export const ServerError: FC = () => {
  return (
    <Content>
      <Card title={t(dictionary.serverErrorTitle)} shadow={false}>
        <Section>
          <Aligment direction="column" gap={20}>
            <Icon name="error" size={56} iconColor="#ad1616" />
            <Text>{t(dictionary.serverError)}</Text>
          </Aligment>
        </Section>
      </Card>
      ;
    </Content>
  );
};

export interface IInvoicesType {
  id: string;
  number: string;
  url: string;
  price: string;
  created: number;
}

export enum PAYMENT_MODE {
  ACTIVE = "active",
  NEW = "new",
  PAYMENT_ERROR = "payment_error",
  CANCEL= "cancel"
}

export const getInvoiceUrl = (
  invoices: IInvoicesType[],
  id: string
): string => {
  const downloadLink = invoices.find((item) => item.id === id);
  return downloadLink ? downloadLink.url : "";
};

import React, { FC, useState } from "react";
import { useStoreActions, useStoreState } from "../../store";
import { Aligment, Table } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { apiCall } from "../../untils/apiCall";
import { EmptyState } from "../UI/EmptyState";
import {
  OrganizationDetailsWrapper,
  OrganizationsWrapper,
} from "./organizations.style";
import { IAction } from "venice-ui/dist/types/types";
import { ContractDetailsPanel } from "./ContractDetailsPanel";
import { RemoveMemberFromContractModal } from "../Modals/RemoveMemberFromContractModal";
import { AddMemberToContract } from "../Modals/AddMemberToContract";
import { getContractName } from "../../helpers/projectHelper";
import { ContractModal } from "../Modals/ContractModal";
import { DeleteContractModal } from "../Modals/DeleteContractModal";
import { ITableHeaderProps } from "../../definitions/ListDefinition";

interface IOrganizationsContracts {
  handleClick: () => void;
}

export const OrganizationsContracts: FC<IOrganizationsContracts> = ({
  handleClick,
}) => {
  const [selectedContractId, setSelectedContractId] = useState("");

  const { id: orgID, contracts } = useStoreState((state) => state.organization);
  const { uid } = useStoreState((state) => state.user.userDetails);

  const [removeMemberDetails, setRemoveMemberDetails] = useState({
    show: false,
    memberID: "",
    contractID: "",
  });

  const [addModal, setAddModal] = useState({
    show: false,
    contractID: "",
  });

  const [editModal, setEditModal] = useState({
    show: false,
    contractID: "",
  });

  const [deleteContractModal, setDeleteContractModal] = useState({
    show: false,
    contractID: "",
  });
  const { addMemeberFromContract } = useStoreActions(
    (actions) => actions.organization
  );

  const { addContractToUser } = useStoreActions((actions) => actions.user);

  const showAddModal = (id?: string) => {
    if (id) {
      setAddModal({
        show: true,
        contractID: id,
      });
    }
  };

  const hideAddModal = () => {
    setAddModal({
      show: false,
      contractID: "",
    });
  };

  const showEditModal = (id?: string) => {
    if (id) {
      setEditModal({ show: true, contractID: id });
    }
  };

  const hideEditModal = () => {
    setEditModal({ show: false, contractID: "" });
  };
  const showDeleteContractModal = (id?: string) => {
    if (id) {
      setDeleteContractModal({ show: true, contractID: id });
    }
  };
  const hideDeleteContractModal = () => {
    setDeleteContractModal({ show: false, contractID: "" });
  };

  const showRemoveModal = (memberID: string) => {
    setRemoveMemberDetails({
      show: true,
      memberID: memberID,
      contractID: selectedContractId,
    });
  };
  const hideRemoveModal = () => {
    setRemoveMemberDetails({ show: false, memberID: "", contractID: "" });
  };

  const addMember = async (contractID: string, memberID: string) => {
    const options = {
      orgID: orgID,
      contractID: contractID,
      memberID: memberID,
      value: "ok",
    };
    await apiCall(
      "organization/toogleMemberInContract",
      options,
      (response: any) => {
        addMemeberFromContract({
          contractID: contractID,
          memberID: memberID,
        });
        if (uid === memberID) {
          addContractToUser({
            name: getContractName(contractID, contracts),
            id: contractID,
          });
        }
        hideAddModal();
      }
    );
  };

  const contractHeaders: ITableHeaderProps[] = [
    {
      name: t(dictionary.contractName),
      valueSource: "name",
    },
    {
      name: t(dictionary.users),
      valueSource: "members",
      isCount: true,
    },
  ];
  const moreActions: IAction[] = [
    {
      label: t(dictionary.addMemeberAction),
      action: showAddModal,
    },
    {
      label: t(dictionary.changeName),
      action: showEditModal,
    },
    {
      label: t(dictionary.deleteContract),
      action: showDeleteContractModal,
    },
  ];
  return (
    <>
      {contracts.length > 0 ? (
        <Aligment>
          <OrganizationsWrapper>
            <Table
              headers={contractHeaders}
              elements={contracts}
              onRowClick={setSelectedContractId}
              sortable={true}
              moreActions={moreActions}
            ></Table>
          </OrganizationsWrapper>
          <OrganizationDetailsWrapper>
            <ContractDetailsPanel
              contractID={selectedContractId}
              handleDelete={showDeleteContractModal}
              handleAddModal={showAddModal}
              handleEditModal={showEditModal}
            />
          </OrganizationDetailsWrapper>
        </Aligment>
      ) : (
        <EmptyState
          title={t(dictionary.emptyStateContractsListTitle)}
          actionLabel={t(dictionary.addContract)}
          action={handleClick}
        />
      )}

      {addModal.show && (
        <AddMemberToContract
          contractID={addModal.contractID}
          handleCancel={hideAddModal}
          handleConfirm={addMember}
        />
      )}
      {editModal.show && (
        <ContractModal
          handleClose={hideEditModal}
          contractID={editModal.contractID}
        />
      )}
      {removeMemberDetails.show && (
        <RemoveMemberFromContractModal
          handleCancel={hideRemoveModal}
          memberID={removeMemberDetails.memberID}
          contractID={removeMemberDetails.contractID}
        />
      )}
      {deleteContractModal.show && (
        <DeleteContractModal
          id={deleteContractModal.contractID}
          handleCancel={hideDeleteContractModal}
        />
      )}
    </>
  );
};

import React, { FC } from "react";
import { Aligment } from "venice-ui";
import {
  ADDITIONAL_TYPES,
  IAddtionalElements,
  TAdditionalType,
} from "../../definitions/additional";
import { ListItem } from "./ItemDetailsPanel.styles";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";

interface isMapElementsList {
  additional: IAddtionalElements[];
  showDetails: (id: string, isMapElement: boolean,isReference:boolean) => void;
}
//mapElementList
export const MapElementsList: FC<isMapElementsList> = ({
  additional,
  showDetails,
}) => {
  const getName = (type: TAdditionalType) => {
    switch (type) {
      case ADDITIONAL_TYPES.dot:
        return t(dictionary.mapElementDot);
      case ADDITIONAL_TYPES.edge:
        return t(dictionary.mapElementLine);
      case ADDITIONAL_TYPES.triage:
        return t(dictionary.mapElementTriage);
      case ADDITIONAL_TYPES.cloud:
        return t(dictionary.mapElementCloud);
    }
  };
  return (
    <Aligment direction="column" align="stretch">
      {additional.map((item) => (
        <ListItem onClick={() => showDetails(item.id,true,false)} key={item.id}>
          {getName(item.type)}
        </ListItem>
      ))}
    </Aligment>
  );
};

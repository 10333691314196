import {
  IOrgContract,
  IOrgMember,
  IOrgMemberSimple,
} from "../store/interface/organization";
import { UserContract } from "../store/interface/user";

export const getContractMembers = (
  id: string,
  contracts: IOrgContract[],
  members: IOrgMember[]
): IOrgMember[] => {
  const orgMembers: IOrgMemberSimple[] = [];
  const finded = contracts.find((item) => item.id === id);
  if (finded) {
    if (finded.members) {
      finded.members.forEach((memberID) => {
        const member = members.find((member) => member.id === memberID);
        if (member) {
          orgMembers.push({
            id: member.id,
            email: member.email,
          });
        }
      });
    }
  }
  return orgMembers;
};

export const getUnassignMemeber = (
  contractID: string,
  contracts: IOrgContract[],
  members: IOrgMember[]
): IOrgMember[] => {
  const unassingMemebers: IOrgMember[] = [];
  const finded = contracts.find((item) => item.id === contractID);
  if (finded) {
    members.forEach((member) => {
      if (!finded.members.includes(member.id)) {
        unassingMemebers.push(member);
      }
    });
  }

  return unassingMemebers;
};

export const checkIfUserHaveContract = (
  contractID: string | null | undefined,
  contracts: UserContract[]
) => {
  if (contractID === null || contractID === undefined || contractID === "") {
    return false;
  }

  return contracts.some((_contract) => _contract.id === contractID);
};

export const getMemberDetails = (members: IOrgMember[], memberID: string) => {
  const member = {
    email: "",
    role: "",
  };
  const found = members.find((_member) => _member.id === memberID);
  if (found) {
    member.email = found.email;
    member.role = found.role || "";
  }
  return member;
};

export const getContractsForMemeber = (
  memberID: string,
  contracts: IOrgContract[]
) => {
  const userContracts: UserContract[] = [];
  contracts.forEach((_contract) => {
    if (_contract.members.includes(memberID)) {
      userContracts.push({ name: _contract.name, id: _contract.id });
    }
  });
  return userContracts;
};

export const getUnassignContracts = (
  memberID: string,
  contracts: IOrgContract[]
): IOrgContract[] => {
  return contracts.filter((_contract) => !_contract.members.includes(memberID));
};

export const getMemberEmail = (members: IOrgMember[], memberID: string) => {
  let memberEmail = "";
  const found = members.find((_memeber) => _memeber.id === memberID);
  if (found) {
    memberEmail = found.email;
  }
  return memberEmail;
};

export const getLimits = (planKey: string) => {
  const limits = {
    users: 1,
    space: 1,
  };
  switch (planKey) {
    case "standard":
      limits.users = 5;
      limits.space = 250;
      break;
  }
  return limits;
};

export const checkUserLimit = (planKey: string, membersCount: number) => {
  const limits = getLimits(planKey);
  return membersCount < limits.users;
};

export const encryptNip = (fullNip: string): number => {
  const prefixPattern = /^[A-Z]{2}/;
  const result = prefixPattern.test(fullNip) ? fullNip.slice(2) : fullNip;
  return parseInt(result) || 0;
};

import React, { FC, useEffect, useRef, useState } from "react";
import { IElementType } from "../../definitions/element";
import {
  getLabelCordX,
  getLabelCordY,
  getLineArc,
  getLineLength,
} from "../../helpers/mathHelpers";
import { validatePosition } from "../../helpers/pdfHelpers";
import { ItemPoint, PointLabel, PointLine } from "./Point.styles";
import { createRoot } from "react-dom/client";

interface PointProps {
  element: IElementType;
  scale: number;
  onClick?: any;
  active: boolean;
  showDetails: (
    id: string,
    isMapeElement: boolean,
    isReference: boolean
  ) => void;
  saveChanges: (element: IElementType, isSource: boolean) => void;
  pdfHeight?: number;
  editMode: boolean;
  pdfWidth?: number;
}

export const getCordinates = (dimension: number) => {
  return dimension - 2;
};

const Point: FC<PointProps> = ({
  element,
  onClick,
  active,
  scale,
  showDetails,
  saveChanges,
  editMode,
  pdfHeight = 0,
  pdfWidth = 0,
}: PointProps) => {
  interface IPointsData {
    move: boolean;
    alt: boolean;
    x: number;
    y: number;
    editMode: boolean;
    label_x: number;
    label_y: number;
    pageHeight: number;
    pageWidth: number;
    lock: boolean;
  }
  const [pointsData, _setPointsData] = useState<IPointsData>({
    move: false,
    alt: false,
    editMode: editMode,
    x: element.x * scale,
    y: element.y * scale,
    label_x: element.label_x,
    label_y: element.label_y,
    pageHeight: pdfHeight,
    pageWidth: pdfWidth,
    lock: element.lock,
  });
  const pointsDataRef = useRef(pointsData);
  const setPointsData = (data: IPointsData) => {
    pointsDataRef.current = data;
    _setPointsData(data);
  };

  useEffect(() => {
    setPointsData({
      ...pointsDataRef.current,
      x: element.x * scale,
      y: element.y * scale,
      pageHeight: pdfHeight,
      pageWidth: pdfWidth,
      editMode: editMode,
    });
  }, [pdfHeight, pdfWidth, scale, editMode]);

  const udpdateElement = (
    elementX: number,
    elementY: number,
    labelX: number,
    labelY: number
  ) => {
    const updatedElement = { ...element };
    updatedElement.x = elementX;
    updatedElement.y = elementY;
    updatedElement.label_x = labelX;
    updatedElement.label_y = labelY;
    saveChanges(updatedElement, false);
  };

  let startX = 0;
  let startY = 0;
  let pointLeft = 0;
  let pointTop = 0;
  let labelLeft = 0;
  let labelTop = 0;
  let deltaLeft = 0;
  let deltaTop = 0;

  const setAtcive = (e: any) => {
    showDetails(element.id, false, element.sourceElementID !== undefined);
  };

  const startMove = (event: any) => {
    pointLeft = pointsDataRef.current.x;
    pointTop = pointsDataRef.current.y;
    labelLeft = pointsDataRef.current.label_x;
    labelTop = pointsDataRef.current.label_y;
    showDetails(element.id, false, element.sourceElementID !== undefined);
    event.stopPropagation();
    if (!pointsDataRef.current.lock) {
      setPointsData({
        ...pointsDataRef.current,
        move: true,
        alt: event.altKey,
      });
      startX = event.clientX;
      startY = event.clientY;
    }
  };

  const move = (event: any) => {
    if (pointsDataRef.current.lock) return;
    if (pointsDataRef.current.move) {
      event.stopPropagation();
      deltaLeft = event.clientX - startX;
      deltaTop = event.clientY - startY;

      if (
        deltaLeft !== 0 &&
        deltaTop !== 0 &&
        event.clientX !== 0 &&
        event.clientY !== 0
      ) {
        const { alt } = pointsDataRef.current;
        if (alt) {
          setPointsData({
            ...pointsDataRef.current,
            label_x: labelLeft + deltaLeft,
            label_y: labelTop + deltaTop,
          });
          startX = labelLeft + deltaLeft
          startY = labelTop + deltaTop
        } else {
          setPointsData({
            ...pointsDataRef.current,
            x: pointLeft + deltaLeft,
            y: pointTop + deltaTop,
          });
          
        }
      }
    }
  };

  const endMove = (event: any) => {
    
    if (pointsDataRef.current.move) {
      console.log('endMove')
      let elementX = pointsDataRef.current.x;
      let elementY = pointsDataRef.current.y;
      let labelX = pointsDataRef.current.label_x;
      let labelY = pointsDataRef.current.label_y;
      event.stopPropagation();
      if (!pointsDataRef.current.lock) {
        const deltaLeft = event.clientX - startX;
        const deltaTop = event.clientY - startY;
        if (pointsDataRef.current.alt) {
          const { left, top } = validatePosition(
            pointsDataRef.current.y,
            pointsDataRef.current.x,
            labelTop + deltaTop,
            labelLeft + deltaLeft,
            0,
            pointsDataRef.current.pageHeight,
            0,
            pointsDataRef.current.pageWidth,
            false
          );
          labelX = left;
          labelY = top;
          setPointsData({
            ...pointsDataRef.current,
            label_x: labelX,
            label_y: labelY,
            move: false,
            alt: false,
          });
        } else {
          const { left, top } = validatePosition(
            pointTop + deltaTop,
            pointLeft + deltaLeft,
            pointsDataRef.current.label_y,
            pointsDataRef.current.label_x,
            0,
            pointsDataRef.current.pageHeight,
            0,
            pointsDataRef.current.pageWidth,
            true
          );
          elementX = left;
          elementY = top;
          setPointsData({
            ...pointsDataRef.current,
            x: elementX,
            y: elementY,
            move: false,
            alt: false,
          });
        }
      }
      udpdateElement(elementX / scale, elementY / scale, labelX, labelY);
    }
  };

  const pointRef = useRef<HTMLDivElement>(null);
  let point = pointRef.current;

  useEffect(() => {
    point = pointRef.current;
    if (point) {
      point.addEventListener("mousedown", startMove);
      point.addEventListener("mousemove", move);
      point.addEventListener("mouseup", endMove);
      point.addEventListener("mouseleave", endMove);
    }
    return () => {
      if (point) {
        point.removeEventListener("mousedown", startMove);
        point.removeEventListener("mousemove", move);
        point.removeEventListener("mouseup", endMove);
        point.removeEventListener("mouseleave", endMove);
      }
    };
  }, [pointRef, scale]);
  return (
    <ItemPoint
      onClick={onClick}
      active={active}
      isEditMode={pointsDataRef.current.editMode}
      style={{
        left: getCordinates(pointsDataRef.current.x) + "px",
        top: getCordinates(pointsDataRef.current.y) + "px",
      }}
    >
      <PointLine
        active={active}
        isEditMode={pointsDataRef.current.editMode}
        style={{
          height:
            getLineLength(
              pointsDataRef.current.label_x,
              pointsDataRef.current.label_y
            ) + "px",
          transform: `rotate(${getLineArc(
            pointsDataRef.current.label_x,
            pointsDataRef.current.label_y
          )}deg)`,
        }}
      />
      <PointLabel
        ref={pointRef}
        isEditMode={pointsDataRef.current.editMode}
        active={active}
        label={element.name}
        move={pointsDataRef.current.move}
        onClick={(e) => setAtcive(e)}
        style={{
          left: getLabelCordX(pointsDataRef.current.label_x) + "px",
          top: getLabelCordY(pointsDataRef.current.label_y) + "px",
        }}
      />
    </ItemPoint>
  );
};

export default Point;

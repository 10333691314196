import React, { FC } from "react";
import styled from "styled-components";
import { Aligment, Icon } from "venice-ui";

interface IPasswordCondition {
  children: React.ReactNode;
  fulfilled: boolean;
}
interface IPasswordStatus {
  fulfilled: boolean;
}
const PasswordStatus = styled.div<IPasswordStatus>`
  font-size: 14px;
  color:${(p) => (p.fulfilled ? "#45bb04" : "#d30c0c")}};
`;
export const PasswordCondition: FC<IPasswordCondition> = ({
  children,
  fulfilled,
}) => {
  return (
    <Aligment justify="flex-start">
      <Icon
        name={fulfilled ? "check_circle" : "error"}
        iconColor={fulfilled ? "#45bb04" : "#d30c0c"}
        size={16}
      />
      <PasswordStatus fulfilled={fulfilled}>{children}</PasswordStatus>
    </Aligment>
  );
};

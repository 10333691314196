import {
  ADDITIONAL_TYPES,
  IAddtionalElements,
  IElementData,
  TAdditionalType,
} from "../../definitions/additional";

export const getWidth = (element: IAddtionalElements) => {
  let width = 0;
  switch (element.type) {
    case ADDITIONAL_TYPES.dot:
    case ADDITIONAL_TYPES.triage:
      width = element.size || 0;
      break;
    case ADDITIONAL_TYPES.edge:
      width = 0;
      break;
    case ADDITIONAL_TYPES.cloud:
      width = element.end_x - element.x;
      break;
    default:
      break;
  }
  return width;
};

export const calculateMapElementWidth = (elementData: IElementData) => {
  switch (elementData.elementMode) {
    case ADDITIONAL_TYPES.edge:
      const a = Math.abs(elementData.x - elementData.end_x);
      const b = Math.abs(elementData.y - elementData.end_y);
      return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
    case ADDITIONAL_TYPES.cloud:
      return elementData.end_x - elementData.x;
    default:
      return elementData.width;
  }
};

export const getHeight = (element: IAddtionalElements) => {
  switch (element.type) {
    case ADDITIONAL_TYPES.dot:
    case ADDITIONAL_TYPES.triage:
      return element.size;
    case ADDITIONAL_TYPES.edge:
      return element.height || 0;
    case ADDITIONAL_TYPES.cloud:
      return element.end_y - element.y;
    default:
      return 0;
  }
};

export const calculateMapElementHeight = (elementData: IElementData) => {
  switch (elementData.elementMode) {
    case ADDITIONAL_TYPES.cloud:
      return elementData.end_y - elementData.y;
    default:
      return elementData.height;
  }
};

export const getArc = (element: IAddtionalElements) => {
  switch (element.type) {
    case ADDITIONAL_TYPES.triage:
    case ADDITIONAL_TYPES.cloud:
      return element.rotation;
    default:
      return 0;
  }
};

export const calculateMapElementArc = (elementData: IElementData) => {
  if (elementData.elementMode === ADDITIONAL_TYPES.edge) {
    const deltaX = elementData.end_x - elementData.x;
    const deltaY = elementData.end_y - elementData.y;
    const angleRadians = Math.atan2(deltaY, deltaX);
    const angleDegrees = angleRadians * (180 / Math.PI);
    return angleDegrees;
  }
  return elementData.rotate;
};

export const getCordinates = (
  dimension: number,
  size: number,
  type: TAdditionalType
) => {
  switch (type) {
    case ADDITIONAL_TYPES.dot:
    case ADDITIONAL_TYPES.triage:
      return dimension - size / 2;
    case ADDITIONAL_TYPES.edge:
    case ADDITIONAL_TYPES.cloud:
      return dimension;
    default:
      return 0;
  }
};

export const checkPropInElement = (
  element: IAddtionalElements,
  propName: string,
  propType: string
): number | string | boolean | undefined => {
  if (propName in element) {
    const propValue = element[propName as keyof typeof element];
    if (propValue !== undefined && propValue !== null) {
      return propValue;
    } else {
      return propType === "string" ? "" : 0;
    }
  }
  return undefined;
};

import { action } from "easy-peasy";
import { ACCOUNT_TYPE, IUser, IUserDetails } from "../interface/user";

export const emptyUser: IUserDetails = {
  authenticated: false,
  uid: "",
  accountType: ACCOUNT_TYPE.normal,
  loading: true,
  email: "",
  orgStatus: '',
  flags:[],
  lang: "pl",
  orgRole: "user",
  orgID:'',
  contracts:[],
  orgSesionID: '',
  orgPaymentID:''
};

export const user: IUser = {
  userDetails: { ...emptyUser },

  //actions
  clearUser: action((state) => {
    state.userDetails = { ...emptyUser };
  }),

  setAuthenticated: action((state, payload) => {
    state.userDetails.authenticated = payload;
  }),

  setUserDetails: action((state, payload) => {
    state.userDetails = payload;
  }),

  addContractToUser: action((state, payload) => {
    state.userDetails.contracts = [...state.userDetails.contracts, payload];
  }),

  removeContractFromUser: action((state, payload) => {
    const contracts = state.userDetails.contracts.filter(_contract => _contract.id !== payload)
    state.userDetails.contracts = contracts;
  }),

  editContractName: action((state, payload) => {
    const newContracts = [...state.userDetails.contracts];
    const contact = newContracts.find((item) => item.id === payload.contractID);
    if (contact) {
      contact.name = payload.name;
    }
    state.userDetails.contracts = newContracts;
  }),
  
  setOrgStatus: action((state, payload) => {
    state.userDetails.orgStatus = payload;
  }),

};

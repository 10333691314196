import {IAppState} from './interface';
import { panel } from './state/panel';
import { user } from './state/user';
import { organization } from './state/organization';
import { projects } from './state/projects';
import { projectDetails } from './state/projectDetails';

export const appModel: IAppState = {
  user,
  panel,
  organization,
  projects,
  projectDetails,
};

import React, { FC } from "react";
import { Progress, ProgressBarWrapper } from "./progressBar.styles";

interface IProgressBar {
  value: number;
  width?: string;
}
export const ProgressBar: FC<IProgressBar> = ({ value, width = "100%" }) => {
  return (
    <ProgressBarWrapper width={width}>
      <Progress value={value} />
    </ProgressBarWrapper>
  );
};

import React, { FC, ReactText, useState } from "react";
import {
  confirmPasswordReset,
  getAuth,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Aligment, Button, Input, TextSmall } from "venice-ui";
import {
  ErrorArea,
  IntputWrapperBig,
  LoginActionLink,
  LoginFooterSection,
  LoginFormSection,
  LoginSubtitle,
  LoginTite,
} from "./Login.styles";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { PasswordCheck } from "../PasswordCheck";
import { checkStatusExtend } from "../PasswordCheck/checks";

export const ResetPasswordForm: FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get("oobCode");

  const [sectionData, updateSectionData] = useState({
    password: "",
    repassword: "",
    loading: false,
    error: false,
    send: false,
  });
  const navigate = useNavigate();

  const setLoginValue = (field: string, value: string) => {
    updateSectionData({
      ...sectionData,
      [field]: value,
    });
  };

  const auth = getAuth();

  const resetAction = async () => {
    if (
      !checkStatusExtend(sectionData.password, sectionData.repassword) &&
      oobCode
    ) {
      updateSectionData({
        ...sectionData,
        error: false,
        loading: true,
        send: false,
      });
      confirmPasswordReset(auth, oobCode, sectionData.password).then(() => {
        updateSectionData({
          ...sectionData,
          error: false,
          loading: false,
          send: true,
        });
      });
    }
  };
  return (
    <Aligment justify="center" direction="column">
      <>
        <LoginTite>
          {sectionData.send
            ? t(dictionary.afterResetTitle)
            : t(dictionary.resetTitle)}
        </LoginTite>
        {sectionData.send && (
          <LoginSubtitle>{t(dictionary.afterResetSubtitle)}</LoginSubtitle>
        )}
        <LoginFormSection>
          {!sectionData.send && (
            <>
              <IntputWrapperBig>
                <Input
                  label={t(dictionary.inputLabelPassword)}
                  type="password"
                  labelPosition="top"
                  value={sectionData.password}
                  name="password"
                  handleChange={(name: string, value: ReactText) =>
                    setLoginValue(name, value as string)
                  }
                  autoFocus={true}
                />
              </IntputWrapperBig>
              <IntputWrapperBig>
                <Input
                  label={t(dictionary.inputLabelRePassword)}
                  labelPosition="top"
                  type="password"
                  value={sectionData.repassword}
                  name="repassword"
                  handleChange={(name: string, value: ReactText) =>
                    setLoginValue(name, value as string)
                  }
                  handleSubmit={resetAction}
                />
                <PasswordCheck
                  password={sectionData.password}
                  extend={true}
                  repassword={sectionData.repassword}
                />
              </IntputWrapperBig>
            </>
          )}
        </LoginFormSection>

        {!sectionData.send && (
          <>
            {sectionData.error && (
              <LoginFormSection>
                <ErrorArea>{t(dictionary.errorWrongLoginOrPassword)}</ErrorArea>
              </LoginFormSection>
            )}
            <Button
              text={t(dictionary.setPassword)}
              onClick={resetAction}
              loader={sectionData.loading}
            />
          </>
        )}
        {sectionData.send && (
          <Button
            text={t(dictionary.backToHomePage)}
            onClick={() => navigate("/")}
          />
        )}
      </>

      {!sectionData.send && (
        <LoginFooterSection>
          <TextSmall>
            <LoginActionLink onClick={() => navigate("/")}>
              {t(dictionary.backToLogin)}
            </LoginActionLink>
          </TextSmall>
        </LoginFooterSection>
      )}
    </Aligment>
  );
};

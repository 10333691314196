import { IAddtionalElements } from "../../definitions/additional";
import { IItemType } from "../../definitions/element";
import { IDetailsProps, ModalProps, SiteProps } from "./Section";

export const emptyModalProps: ModalProps = {
  show: false,
  x: 0,
  y: 0,
  type: "",
  mode: "add",
  data: undefined,
};

export const emptyDetailsProps: IDetailsProps = {
  type: "",
  data: undefined,
  active: "",
};

export const initialSiteProps: SiteProps = {
  file: null,
  fileName: "",
  fileUrl: "",
  fileDBName: "",
  kks: "",
  loading: true,
  name: "",
  drawMode: "edge",
  editMode: false,
  referal: undefined,
  scale: 1,
  scaleDirection: "none",
  buttonLoader:false,
  export: {
    show: false,
  },
  modal: {
    ...emptyModalProps,
  },
  details: {
    ...emptyDetailsProps,
  },
  delete: {
    show: false,
    id: "",
  },
  deleteSection: {
    show: false,
    id: "",
  },
};

export const emptyElement: IItemType = {
  id: "",
  name: "",
  sectionID: "",
  page: 0,
  x: 0,
  y: 0,
  label_x: 0,
  label_y: 0,
  type: "empty",
  lock: false,
  createdDate: 0,
};

export const emptyMapElement: IAddtionalElements = {
  id: "",
  type: "dot",
  sectionID: "",
  page: 1,
  x: 0,
  y: 0,
  lock: false,
  size: 0,
  rotation: 0,
  height: 0,
  end_x: 0,
  end_y: 0,
};

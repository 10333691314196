import React, { FC, useState, useRef, useEffect, useCallback } from "react";
import { AdditionalElementsWrapper, Dot } from "./AdditionalElements.styles";
import {
  getCordinates,
  calculateMapElementHeight,
  calculateMapElementWidth,
  calculateMapElementArc,
  getArc,
  getHeight,
  getWidth,
} from "./additionalHelper";
import {
  ADDITIONAL_TYPES,
  IAdditionalElements,
  IElementData,
  TAdditionalType,
} from "../../definitions/additional";

export const DotElement: FC<IAdditionalElements> = ({
  element,
  scale,
  active,
  editMode,
  pdfHeight = 0,
  pdfWidth = 0,
  showDetails,
  saveChanges,
}) => {
  const pointRef = useRef<HTMLDivElement>(null);

  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [pointPos, setPointPos] = useState({ left: 0, top: 0 });

  const [elementData, setElementData] = useState<IElementData>({
    move: false,
    x: element.x,
    y: element.y,
    editMode: editMode,
    elementMode: element.type,
    pageHeight: pdfHeight,
    pageWidth: pdfWidth,
    rotate: getArc(element),
    width: getWidth(element),
    height: getHeight(element),
    size: element.size || 0,
    end_x: element.end_x || 0,
    end_y: element.end_y || 0,
    mode: "element",
    scale: scale,
  });

  const updateElement = useCallback(() => {
    saveChanges(
      {
        ...element,
        x: elementData.x,
        y: elementData.y,
        end_x: elementData.end_x,
        end_y: elementData.end_y,
        size: elementData.size,
        rotation: elementData.rotate,
        height: elementData.height,
      },
      false
    );
  }, [element, elementData, saveChanges]);

  const startMove = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (element.lock) return;

      showDetails(element.id!, true, false);
      setStartPos({ x: event.clientX, y: event.clientY });
      setPointPos({
        left: elementData.x * elementData.scale,
        top: elementData.y * elementData.scale,
      });
      setElementData((prev) => ({
        ...prev,
        size: element.size,
        move: true,
      }));
    },
    [element, elementData, showDetails]
  );

  const move = useCallback(
    (event: MouseEvent) => {
      if (!elementData.move || element.lock) return;
      event.stopPropagation();
      const newDelta = {
        left: event.clientX - startPos.x,
        top: event.clientY - startPos.y,
      };

      setElementData((prev) => ({
        ...prev,
        x: (pointPos.left + newDelta.left)/ elementData.scale,
        y: (pointPos.top + newDelta.top) / elementData.scale,
      }));
    },
    [elementData.move, element.lock, element.height, startPos, pointPos]
  );

  const endMove = useCallback(
    (event: MouseEvent) => {
      if (!elementData.move) return;
      event.stopPropagation();
      setElementData((prev) => ({
        ...prev,
        move: false,
      }));

      updateElement();
    },
    [elementData.move, updateElement]
  );

  useEffect(() => {
    const point = pointRef.current;
    if (!point) return;

    point.addEventListener("mousedown", startMove);
    window.addEventListener("mousemove", move,{ passive: true });
    window.addEventListener("mouseup", endMove);
    window.addEventListener("mouseleave", endMove);

    return () => {
      point.removeEventListener("mousedown", startMove);
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", endMove);
      window.removeEventListener("mouseleave", endMove);
    };
  }, [startMove, move, endMove]);

  useEffect(() => {
    setElementData((prev)=>({
      ...prev,
      x: element.x,
      y: element.y,
      width: getWidth(element),
      height: getHeight(element),
      pageHeight: pdfHeight,
      pageWidth: pdfWidth,
      editMode:editMode,
      scale: scale,
    }));
  }, [element, pdfHeight, pdfWidth, scale,editMode]);

  return (
    <AdditionalElementsWrapper
      isActive={active}
      isEditMode={elementData.editMode}
      origin={false}
      style={{
        left: `${getCordinates(
          elementData.x * elementData.scale,
          elementData.width * elementData.scale,
          ADDITIONAL_TYPES.dot as TAdditionalType
        )}px`,
        top: `${getCordinates(
          elementData.y * elementData.scale,
          elementData.height * elementData.scale,
          ADDITIONAL_TYPES.dot as TAdditionalType
        )}px`,
        height: `${
          calculateMapElementHeight(elementData) * elementData.scale
        }px`,
        width: `${calculateMapElementWidth(elementData) * elementData.scale}px`,
        transform: `rotate(${calculateMapElementArc(elementData)}deg)`,
      }}
    >
      <Dot isActive={active} ref={pointRef} />
    </AdditionalElementsWrapper>
  );
};

import React, { FC } from "react";
import { Aligment } from "venice-ui";
import {
  digitCheck,
  lengthCheck,
  lowercaseCheck,
  repasswordCheck,
  specialCharCheck,
  uppercaseCheck,
} from "./checks";
import { PasswordCondition } from "./PasswordCondition";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";

interface IPasswordCheckProps {
  password: string;
  extend?: boolean;
  repassword?: string;
}
export const PasswordCheck: FC<IPasswordCheckProps> = ({
  password,
  extend = false,
  repassword = "",
}) => {
  return (
    <Aligment direction="column" align="flex-start" vPadding="20px">
      <PasswordCondition fulfilled={lengthCheck(password)}>
        {t(dictionary.passwordCheckLength)}
      </PasswordCondition>
      <PasswordCondition fulfilled={lowercaseCheck(password)}>
        {t(dictionary.passwordCheckLowercase)}
      </PasswordCondition>
      <PasswordCondition fulfilled={uppercaseCheck(password)}>
        {t(dictionary.passwordCheckUppercase)}
      </PasswordCondition>
      <PasswordCondition fulfilled={digitCheck(password)}>
        {t(dictionary.passwordCheckDigit)}
      </PasswordCondition>
      <PasswordCondition fulfilled={specialCharCheck(password)}>
        {t(dictionary.passwordCheckSpecial)}
      </PasswordCondition>
      {extend &&
      <PasswordCondition fulfilled={repasswordCheck(password,repassword)}>
      {t(dictionary.passwordMatchCheckSpecial)}
    </PasswordCondition>
      }
    </Aligment>
  );
};

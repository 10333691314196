import {createStore, createTypedHooks, persist} from 'easy-peasy';
import { appModel } from './state';

const storeModel = {
  ...appModel,
};

export const {useStore, useStoreState, useStoreActions, useStoreDispatch} =
  createTypedHooks<typeof storeModel>();

export default createStore(persist(storeModel, {}), {name: 'budgetNest'});

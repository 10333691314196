import styled from "styled-components";

interface ITableWrapperStyle {
  top:number
}
export const TableWrapper = styled.div<ITableWrapperStyle>`
  overflow:hidden;
  display:flex;
  width:100%;
  height: calc( 100vh - (${p=> p.top}px + 26px) );
`
export const ItemListContent = styled.div`
  height:100%;
  overflow:Auto;
  width:100%;
`
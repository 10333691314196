import React, { FC } from "react";
import { Modal, Text } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { SiteProps } from "../Section/Section";
import { ifHaveReferences, isReferencePoint } from "../../helpers/weldHelpers";
import { IReferenceElement } from "../../definitions/element";
import { useStoreState } from "../../store";

interface IDeleteModalProps {
  handleCancel: () => any;
  handleDelete: (
    id: string,
    isMapeElement: boolean,
    isReference: boolean
  ) => void;
  id: string;
  name: string;
  type: string;
}

export const DeleteModal: FC<IDeleteModalProps> = ({
  handleCancel,
  handleDelete,
  name,
  id,
  type,
}) => {
  const { references } =
    useStoreState((state) => state.projectDetails.projectDetails);

  const deleteElement = (id: string) => {
    handleDelete(
      id,
      type !== "joint",
      isReferencePoint(id, references)
    );
  };

  const setDeleteContent = () => {
    return ifHaveReferences(id, references)
      ? t(dictionary.doYouWantDeletePointWithReferences)
      : t(dictionary.doYouWantDeletePoint);
  };

  return (
    <Modal
      title={t(dictionary.confirmDelete)}
      labelClose={t(dictionary.cancel)}
      handleClose={handleCancel}
      handleConfirm={() => deleteElement(id)}
      labelConfirm={t(dictionary.delete)}
    >
      {type === "joint" && <Text>{name}</Text>}
      <Text>
        {type !== "joint"
          ? t(dictionary.doYouWantDeleteElement)
          : setDeleteContent()}
      </Text>
    </Modal>
  );
};

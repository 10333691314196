import axios from "axios";
import { apiURL, appURL } from "../untils/const";
import { UserContract } from "../store/interface/user";

export const createUser = async (
  email: string,
  userID: string,
  productId: string,
  companyName: string,
  companyAdressLine1: string,
  companyAdressLine2: string,
  companyPostCode: string,
  companyCity: string,
  companyCountry: string,
  companyNip: string,
  invoiceEmail: string,
  acceptTerms: boolean,
  emailSubscription: boolean
) => {
  const options = {
    email: email,
    userID: userID,
    productId: productId,
    creationTime: Date.now(),
    companyName: companyName,
    companyAdressLine1: companyAdressLine1,
    companyAdressLine2: companyAdressLine2,
    companyPostCode: companyPostCode,
    companyCity: companyCity,
    companyCountry: companyCountry,
    companyNip: companyNip,
    invoiceEmail: invoiceEmail,
    acceptTerms: acceptTerms,
    emailSubscription: emailSubscription,
  };
  let status = false;
  await axios
    .post(`${apiURL}/user/createUserAndOrgaznization`, {
      ...options,
    })
    .then((response) => {
      localStorage.removeItem("registerFlow");
      window.location.href = response.data.session.url;
    })
    .catch(function (error) {
      console.log(error);
    });
  return status;
};

export const joinUser = async (
  email: string,
  userID: string,
  invitationID: string,
  acceptTerms: boolean,
  emailSubscription: boolean
) => {
  const options = {
    email: email,
    userID: userID,
    invitationID: invitationID,
    acceptTerms:acceptTerms,
    emailSubscription:emailSubscription
  };
  let status = false;
  await axios
    .post(`${apiURL}/user/joinUserFronInvitation`, {
      ...options,
    })
    .then((response) => {
      localStorage.removeItem("registerFlow");
      window.location.href = appURL;
    })
    .catch(function (error) {
      console.log(error);
    });
  return status;
};

export interface ILimitationProps {
  projects: number;
  sections: number;
  points: number;
}

interface IResponseTagsProps {
  type: string;
  lang: string;
}

export interface IResponsePricingProps {
  id: string;
  name: string;
  priceId: string;
  price: number;
  currency: string;
  tags: IResponseTagsProps;
}

export const getLang = (detectedLang: string) => {
  console.log("getLang", detectedLang);
  let lang = "en";
  if (detectedLang) {
    if (detectedLang === "pl" || detectedLang === "pl-PL") {
      lang = "pl";
    }
  }
  return lang;
};

interface ITLangObj {
  pl: string;
  en: string;
}
export const t = (obj: ITLangObj) => {
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : navigator.language;

  return obj[lang as keyof typeof obj];
};

//ToDo add unit test for this function
export const getContractsIDs =(userID:string, contracts:UserContract[])=>{
  const ids:string[]=[]
  contracts.forEach(_contract => {
    ids.push(_contract.id)
  })
  return ids;
}
import React, { FC, useEffect, useState } from "react";
import { Form, Modal } from "venice-ui";
import { apiCall } from "../../untils/apiCall";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { useStoreState } from "../../store";

export interface EditSectionData {
  show: boolean;
  projectID: string;
  sectionID: string;
  name: string;
  kks: string;
}

interface IEditSectionModalProps {
  onClose: () => void;
  onConfirm: (newData: EditSectionData) => void;
  dataSet: EditSectionData;
}

export const EditSectionModal: FC<IEditSectionModalProps> = ({
  onClose,
  onConfirm,
  dataSet,
}) => {
  const { uid,orgID } = useStoreState((state) => state.user.userDetails);
  const [editData, setEditData] = useState<EditSectionData>({
    show: true,
    sectionID: dataSet.sectionID,
    projectID: dataSet.projectID,
    name: dataSet.name,
    kks: dataSet.kks,
  });

  useEffect(() => {
    setEditData({ ...dataSet });
  }, [dataSet]);

  const validationSection = () => {
    const name = editData.name as string;
    const kks = editData.kks as string;
    return name.trim().length === 0 || kks.trim().length === 0;
  };

  const updateForm = (field: string, value: string) => {
    setEditData({
      ...editData,
      [field]: value,
    });
  };
  const updateProject = async () => {
    await apiCall(
      "projects/updateSection",
      {
        orgID: orgID,
        projectID: editData.projectID,
        sectionID: editData.sectionID,
        sectionUpdates: {
          name: editData.name.trim(),
          kks: editData.kks.trim(),
        },
      },
      () => {
        onConfirm(editData);
      }
    );
  };

  const editForm = {
    action: updateForm,
    data: [
      {
        type: "text",
        label: t(dictionary.sectionName),
        value: editData?.name,
        name: "name",
        autofocus:true,
        submit:() => updateProject()
      },
      {
        type: "text",
        label: t(dictionary.kks),
        value: editData?.kks,
        name: "kks",
      },
    ],
  };
  return (
    <>
      {editData.show && (
        <Modal
          title={`${t(dictionary.editModalTitle)}: ${editData.name}`}
          handleClose={onClose}
          handleConfirm={() => updateProject()}
          labelClose={t(dictionary.cancel)}
          labelConfirm={t(dictionary.save)}
          submitDisabled={validationSection()}
        >
          <Form formData={editForm} />
        </Modal>
      )}
    </>
  );
};

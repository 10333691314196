import { emptySection } from "../components/Home/projectsConst";
import { EditSectionData } from "../components/Modals/EditSectionModal";
import { IProject } from "../store/interface/projects";
import { UserContract } from "../store/interface/user";
import { ListSectionProps } from "../types/sectionTypes";

interface IGetProjectParamsReturn {
  name: string;
  contract: string;
}
export const getProjectParams = (
  projects: IProject[],
  id: string
): IGetProjectParamsReturn => {
  const params: IGetProjectParamsReturn = {
    name: "",
    contract: "",
  };
  const project = projects.find((item) => item.id === id);
  if (project) {
    params.name = project.name;
    params.contract = project.contract;
  }
  return params;
};

export const reduceProjectList = (projects: IProject[], id: string) => {
  return projects.filter((item) => item.id !== id);
};

interface IUpdateProjectData {
  id: string;
  name: string;
}
export const updateProjectDetails = (
  projects: IProject[],
  updateSet: IUpdateProjectData
) => {
  const updatedProject = projects.find((item) => item.id === updateSet.id);
  if (updatedProject) {
    updatedProject.name = updateSet.name;
  }
  return projects;
};

export const getSectionObj = (
  sections: ListSectionProps[],
  id: string
): ListSectionProps => {
  const project = sections.find((item) => item.id === id);
  return project ? project : emptySection;
};

export const reduceSectionList = (sections: ListSectionProps[], id: string) => {
  return sections.filter((item) => item.id !== id);
};

export const updateSectionDetails = (
  sections: ListSectionProps[],
  updateSet: EditSectionData
) => {
  const updatedSection = sections.find(
    (item) => item.id === updateSet.sectionID
  );
  if (updatedSection) {
    updatedSection.name = updateSet.name;
    updatedSection.kks = updateSet.kks;
  }
  return sections;
};

export const getContractName = (
  contractID: string,
  contracts: UserContract[]
) => {
  if (contracts.length === 0) {
    return "";
  }
  return contracts.find((_contract) => _contract.id === contractID)?.name || "";
};

export const getContactsNames = (
  projects: IProject[],
  contracts: UserContract[]
) => {
  projects.forEach((_project) => {
    _project.contractLabel = getContractName(_project.contract, contracts);
  });

  return projects;
};

export const getSectionDetails = (
  sectionID: string,
  sections: ListSectionProps[]
) => {
  const defaultDetails = {
    name: "",
    file: "",
    fileName: "",
    kks: "",
  };
  const foundSection = sections.find((section) => section.id === sectionID);

  if (foundSection) {
    const { name, file = "", fileName = "", kks } = foundSection;
    return { name, file, fileName, kks };
  }

  return defaultDetails;
};

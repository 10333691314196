import styled from "styled-components";
import { TextLabel, Text } from "venice-ui";
import { Colors } from "../../untils/Theme";

interface IDetailPanelWrapperProps {
  top: number;
}
export const DetailPanelWrapper = styled.div<IDetailPanelWrapperProps>`
  padding-left: 16px;
  width: 24rem;
  height: calc(100vh - (${(p) => p.top}px + 26px));
`;

interface IItemDetailsActionProps{
  isDisabled?:boolean
}
export const ItemDetailsAction = styled.div<IItemDetailsActionProps>`
  display: flex;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  width: 100%;
  align-items: center;
  color: ${p=> p.isDisabled ? Colors.disableText: Colors.seaBlue};
  justify-content: center;
  cursor: ${p=> p.isDisabled ? 'not-allowed': 'pointer'};
`;

export const ItemDetailsLabel = styled(TextLabel)`
  padding-bottom: 4px;
`;

export const ItemDetailsText = styled(Text)`
  padding-bottom: 12px;
  min-height: 18px;
  width: 100%;
`;

export const ListItem = styled.div`
  padding: 2px 0;
  font-size:1.4rem;
  cursor: pointer;
  color: ${Colors.text}
  transition:300ms;
  :hover{
    color: ${Colors.seaBlue};
    transition:300ms;
  }
`;

import React, { FC, useState } from "react";
import { LOGIN_MODE } from "../../helpers/loginHelper";
import { PublicContentArea, PublicWrapper } from "../publicComponents";
import logo from ".././../weldingApp_logo_white.svg";
import { Section } from "venice-ui";
import { LoginTile, WelcomeLogo } from "./Login.styles";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";
import { useParams } from "react-router-dom";
import { ForgotForm } from "./ForgotForm";

const Login: FC = () => {
  const { pageMode } = useParams();

  const isJoinMode = pageMode !== undefined && pageMode === "join";

  const [mode, changeMode] = useState(
    isJoinMode ? LOGIN_MODE.singin : LOGIN_MODE.login
  );

  return (
    <PublicWrapper>
      <PublicContentArea>
        <WelcomeLogo src={logo} />
        <LoginTile>
          <Section>
            {mode === LOGIN_MODE.login && <LoginForm changeMode={changeMode} />}
            {mode === LOGIN_MODE.singin && (
              <RegisterForm changeMode={changeMode}  />
            )}
            {mode === LOGIN_MODE.forgot &&
             <ForgotForm changeMode={changeMode}/>
            }
          </Section>
        </LoginTile>
      </PublicContentArea>
    </PublicWrapper>
  );
};

export default Login;

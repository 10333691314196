import React, { FC, useState } from "react";
import { Modal, Form } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { emailValidation } from "../PasswordCheck/checks";

interface IEditOrgMailModal {
  orgEmail: string;
  onClose: () => void;
  handleSave: (name: string) => void;
}
export const EditOrgMailModal: FC<IEditOrgMailModal> = ({
  orgEmail,
  onClose,
  handleSave,
}) => {
  const [email, setEmail] = useState(orgEmail);

  const updateOrgEmail = () => {
    handleSave(email);
  };
  const updateValue = (name: string, value: string) => {
    setEmail(value);
  };
  const editForm = {
    action: updateValue,
    data: [
      {
        row: [
          {
            type: "text",
            label: t(dictionary.invoiceEmail),
            value: email,
            name: "email",
            autofocus: true,
            handleSave: updateOrgEmail,
          },
        ],
      },
    ],
  };

  return (
    <Modal
      title={`${t(dictionary.editOrgNameModalTitle)}`}
      handleClose={onClose}
      handleConfirm={updateOrgEmail}
      labelClose={t(dictionary.cancel)}
      labelConfirm={t(dictionary.save)}
      submitDisabled={emailValidation(email)}
    >
      <Form formData={editForm} />
    </Modal>
  );
};

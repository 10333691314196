import React, { FC } from "react";
import { Icon } from "venice-ui";
import { Colors } from "../../untils/Theme";
import {
  NavigatorController,
  NavigatorElement,
  NavigatorWrapper,
  ZoomWrapper,
} from "./NavigatorComponents";
interface IMapNAvigator {
  handleMove: (direction: string) => void;
}
export const MapNavigator: FC<IMapNAvigator> = ({
  handleMove,
}: IMapNAvigator) => {
 

  return (
    <>
      <ZoomWrapper>
      </ZoomWrapper>
      <NavigatorWrapper>
        <NavigatorElement>
          <NavigatorController>
            <Icon
              name="forward"
              onClick={() => handleMove("N")}
              iconColor={Colors.white}
              iconHoverColor={Colors.seaBlue}
              iconBgHoverColor="transparent"
            />
          </NavigatorController>

          <NavigatorController>
            <Icon
              name="back"
              onClick={() => handleMove("W")}
              iconColor={Colors.white}
              iconHoverColor={Colors.seaBlue}
              iconBgHoverColor="transparent"
            />
          </NavigatorController>
          <NavigatorController>
            <Icon
              name="forward"
              onClick={() => handleMove("E")}
              iconColor={Colors.white}
              iconHoverColor={Colors.seaBlue}
              iconBgHoverColor="transparent"
            />
          </NavigatorController>

          <NavigatorController>
            <Icon
              name="back"
              onClick={() => handleMove("S")}
              iconColor={Colors.white}
              iconHoverColor={Colors.seaBlue}
              iconBgHoverColor="transparent"
            />
          </NavigatorController>
        </NavigatorElement>
      </NavigatorWrapper>
    </>
  );
};

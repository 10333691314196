import React, { FC, useState } from "react";
import { ContentFull } from "../components";
import { LoadingContent } from "../layouts/Loading";
import { useStoreState } from "../../store";
import { PaymentTemplate } from "./Partial/PaymentTemplate";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";

export const PaymenetMiss: FC = () => {
  const { orgRole } = useStoreState((state) => state.user.userDetails);
  const [pageData, setPageData] = useState({
    loading: false,
    sessionLink: "",
  });
  const handleClick = () => {
    console.log("handleClick");
  };
  return (
    <ContentFull>
      {pageData.loading ? (
        <LoadingContent />
      ) : (
          <PaymentTemplate
            title={t(dictionary.paymentTitleMissed)}
            content={
              orgRole === "admin"
                ? t(dictionary.finishSubskrypcjeAdmin)
                : t(dictionary.finishSubskrypcjeUser)
            }
            isAdmin={orgRole === "admin"}
            onClick={handleClick}
            butonLabel={t(dictionary.finishSubsrybcionAction)}
          />
      )}
    </ContentFull>
  );
};

import React, { FC } from "react";
import { IConnectedWeldWrapper } from "./IConnectedWeld.styles";
import { Table } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { IExtendElementType } from "../../definitions/element";

interface IConnectedWeld {
  elements: IExtendElementType[];
  onSelect: (id: string) => void;
}
export const ConnectedWeld: FC<IConnectedWeld> = ({ elements, onSelect }) => {
  const handleSelect = (id: string) => {
    onSelect(id);
  };
  const tableHeaders = [
    {
      name: t(dictionary.jointNumber),
      valueSource: "name",
    },
    {
      name: t(dictionary.kks),
      valueSource: "kks",
    },
    {
      name: t(dictionary.page),
      valueSource: "page",
    },
  ];
  return (
    <IConnectedWeldWrapper>
      <Table
        headers={tableHeaders}
        elements={elements}
        selectable
        onRowClick={handleSelect}
      />
    </IConnectedWeldWrapper>
  );
};

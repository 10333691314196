import React, { FC } from "react";
import { IAddtionalElements } from "../../definitions/additional";
import { getFormDefinition } from "../../definitions/definitionHelper";
import { Form } from "venice-ui";

interface IElementDetails {
  element: IAddtionalElements;
  saveChanges: (element: IAddtionalElements,isSource:boolean) => void;
}
export const ElementDetails: FC<IElementDetails> = ({
  element,
  saveChanges,
}) => {
  const updateForm = (name: string, value: number | string) => {
    const updatedElement = { ...element };
    (updatedElement as any)[name] = value;
    saveChanges(updatedElement,false);
  };
  const elementForm = {
    action: updateForm,
    data: getFormDefinition(element.type, element,false)!,
  };

  return (
    <div>
      <Form formData={elementForm} size={'small'}/>
    </div>
  );
};

import React, { FC } from "react";
import {
  ADDITIONAL_TYPES,
  IAddtionalElements,
} from "../../definitions/additional";
import { DotElement } from "./DotElement";
import { TriageElement } from "./TriageElement";
import { EdgeElement } from "./EdgeElement";
import { CloudElement } from "./CloudElement";

export interface IAdditionalElements {
  element: IAddtionalElements;
  scale: number;
  editMode: boolean;
  active: boolean;
  pdfHeight?: number;
  pdfWidth?: number;
  showDetails: (
    id: string,
    isMapElement: boolean,
    isReference: boolean
  ) => void;
  saveChanges: (element: IAddtionalElements, isSource: boolean) => void;
}

export const AdditionalElements: FC<IAdditionalElements> = ({
  element,
  scale,
  active,
  editMode,
  pdfHeight = 0,
  pdfWidth = 0,
  showDetails,
  saveChanges,
}) => {
  return (
    <>
      {element.type === ADDITIONAL_TYPES.dot && (
        <DotElement
          element={element}
          scale={scale}
          active={active}
          editMode={editMode}
          pdfHeight={pdfHeight}
          pdfWidth={pdfWidth}
          showDetails={showDetails}
          saveChanges={saveChanges}
        />
      )}
      {element.type === ADDITIONAL_TYPES.triage && (
        <TriageElement
          element={element}
          scale={scale}
          active={active}
          editMode={editMode}
          pdfHeight={pdfHeight}
          pdfWidth={pdfWidth}
          showDetails={showDetails}
          saveChanges={saveChanges}
        />
      )}
      {element.type === ADDITIONAL_TYPES.edge && (
        <EdgeElement
          element={element}
          scale={scale}
          active={active}
          editMode={editMode}
          pdfHeight={pdfHeight}
          pdfWidth={pdfWidth}
          showDetails={showDetails}
          saveChanges={saveChanges}
        />
      )}
      {element.type === ADDITIONAL_TYPES.cloud && (
        <CloudElement
          element={element}
          scale={scale}
          active={active}
          editMode={editMode}
          pdfHeight={pdfHeight}
          pdfWidth={pdfWidth}
          showDetails={showDetails}
          saveChanges={saveChanges}
        />
      )}
    </>
  );
};

import React, { FC, useEffect, useState } from "react";
import { Aligment, Section, Tile } from "venice-ui";
import { Colors } from "../../untils/Theme";
import { DetailsPanelText, Spacer } from "./organizations.style";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import {
  ItemDetailsAction,
  ItemDetailsLabel,
  ItemDetailsText,
} from "../ItemDetailPanel/ItemDetailsPanel.styles";
import { getMemberDetails } from "../../helpers/organizationHelper";
import { useStoreState } from "../../store";
import { MemberContractList } from "./MemberContractList";

interface IMemberDetailsPanel {
  memberID: string;
  handleUnassing: (contractID: string) => void;
  handleAssing: (memberID: string) => void;
  handleEdit: (memberID: string) => void;
  handleDelete: (memberID: string) => void;
}
export const MemberDetailsPanel: FC<IMemberDetailsPanel> = ({
  memberID,
  handleUnassing,
  handleAssing,
  handleEdit,
  handleDelete,
}) => {
  const [memberDetails, setMemberDetails] = useState({
    email: "",
    role: "",
  });
  const { members } = useStoreState((state) => state.organization);
  const { uid } = useStoreState((state) => state.user.userDetails);

  useEffect(() => {
    if (memberID) {
      setMemberDetails(getMemberDetails(members, memberID));
    }
  }, [memberID]);


  const editMemeber = (memberID: string) => {
    if (memberID !== uid) {
      handleEdit(memberID);
    }
  };


  return (
    <Tile backgroundColor={Colors.tileGray} height="inherit">
      {memberID === "" ? (
        <Aligment style={{ height: "100%" }}>
          <DetailsPanelText>{t(dictionary.emptyMemberPanel)}</DetailsPanelText>
        </Aligment>
      ) : (
        <>
          <Section
            title={t(dictionary.membersDetails)}
            backgroundColor={Colors.tileGray}
          >
            <Aligment direction="column" align="stretch">
              <Aligment direction="column" align="flex-start">
                <ItemDetailsLabel>{t(dictionary.email)}</ItemDetailsLabel>
                <ItemDetailsText>{memberDetails.email}</ItemDetailsText>
              </Aligment>
              <Aligment direction="column" align="flex-start">
                <ItemDetailsLabel>{t(dictionary.role)}</ItemDetailsLabel>
                <ItemDetailsText>{memberDetails.role}</ItemDetailsText>
              </Aligment>
            </Aligment>
          </Section>
          <Section
            title={t(dictionary.contracts)}
            backgroundColor={Colors.tileGray}
          >
            <Aligment direction="column" align="stretch">
              <MemberContractList
                memberID={memberID}
                handleDelete={handleUnassing}
              />
              <Spacer height="2rem" />
              <Aligment direction="column" width="100%" justify="center">
                <ItemDetailsAction onClick={() => handleAssing(memberID)}>
                  {t(dictionary.addContractToMemeber)}
                </ItemDetailsAction>
                {}
                <ItemDetailsAction onClick={() => editMemeber(memberID)} isDisabled={memberID === uid}>
                  {t(dictionary.editUser)}
                </ItemDetailsAction>
                <ItemDetailsAction onClick={() => handleDelete(memberID)} isDisabled={memberID === uid}>
                  {t(dictionary.deleteUser)}
                </ItemDetailsAction>
              </Aligment>
            </Aligment>
          </Section>
        </>
      )}
    </Tile>
  );
};

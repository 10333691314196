import { IReferenceElement } from "../definitions/element";

export const isReferencePoint = (
  id: string,
  allReferences: IReferenceElement[]
) => {
  return !!allReferences.find((item) => item.id === id);
};
export const ifHaveReferences = (
  id: string,
  allReferences: IReferenceElement[]
) => {
  return allReferences.find((item) => item.sourceElementID === id);
};

import React, { FC, ReactText, useEffect, useState } from "react";
import { Aligment, Input, Modal, Text } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { useStoreState } from "../../store";
import { IntputWrapperBig } from "../SingIn/Login.styles";
import { getMemberEmail } from "../../helpers/organizationHelper";

interface IDeleteProjectModalProps {
  handleCancel: () => void;
  handleDelete: () => void;
  loader: boolean;
  userID: string;
}

export const DeleteUserModal: FC<IDeleteProjectModalProps> = ({
  handleCancel,
  handleDelete,
  loader,
  userID,
}) => {
  const { email, uid } = useStoreState((state) => state.user.userDetails);
  const { members } = useStoreState((state) => state.organization);

  const isSelf = uid === userID;
  const [emailToCheck, setEmailToCheck] = useState("");

  useEffect(() => {
    setEmailToCheck(isSelf ? email : getMemberEmail(members, userID));
  }, []);

  const [pageData, setPageData] = useState({
    email: "",
  });

  const setEmailValue = (field: string, value: string) => {
    setPageData({
      ...pageData,
      [field]: value,
    });
  };

  const validation = () => {
    return pageData.email !== emailToCheck;
  };
  const onClose = () => {
    if (!loader) {
      handleCancel();
    }
  };
  return (
    <Modal
      title={t(dictionary.confirmDelete)}
      labelClose={t(dictionary.cancel)}
      handleClose={onClose}
      handleConfirm={handleDelete}
      labelConfirm={t(dictionary.closeAccount)}
      submitDisabled={validation()}
      submitLoader={loader}
    >
      <Aligment gap={20}>
        <Text>
          {isSelf
            ? t(dictionary.doYouWantDeleteAccount)
            : t(dictionary.doYouWantDeleteUser)}
        </Text>
        <Text>
          {isSelf
            ? t(dictionary.confirmAccountDelete)
            : t(dictionary.confirmUserAccountDelete)}
        </Text>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.enterEmailAddress)}
            type="text"
            labelPosition="top"
            value={pageData.email}
            name="login"
            handleChange={(name: string, value: ReactText) =>
              setEmailValue("email", value as string)
            }
          />
        </IntputWrapperBig>
      </Aligment>
    </Modal>
  );
};

import { veryfiedMoveCordinates } from "../../helpers/pdfHelpers";

export const zoomScale = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3, 4, 5, 6, 7, 8];
export const calculateShift = (direction: string) => {
  const move = {
    x: 0,
    y: 0,
  };
  switch (direction) {
    case "N":
      move.y = 10;
      break;
    case "S":
      move.y = -10;
      break;
    case "E":
      move.x = -10;
      break;
    case "W":
      move.x = 10;
      break;
  }
  return move;
};

export const calculateFitToScreen = (
  canvasWidth: number,
  fileWidth: number,
  scale: number
): number => {
  return Math.round((canvasWidth / (fileWidth / scale)) * 1000) / 1000;
};
export const calculateZoom = (direction: string, scale: number) => {
  let value = scale;

  //zoomScale = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3, 4, 5, 6, 7, 8]
  let scaleIndex = zoomScale.findIndex((item) => item === scale);
  if (scaleIndex === -1) {
    zoomScale.forEach((item, index) => {
      if (item > zoomScale[index] && item < zoomScale[index + 1]) {
        scaleIndex = index;
      }
    });
  }

  switch (direction) {
    case "up":
      value = zoomScale[scaleIndex + 1];
      break;
    case "down":
      value = zoomScale[scaleIndex - 1];
      break;
  }
  return value;
};

export const calculateSetPosition = (
  dimentionX: number,
  dimentionY: number,
  workspacePosition: any
) => {
  const calculateX = workspacePosition.x + dimentionX;
  const calculateY = workspacePosition.y + dimentionY;

  const newPosition = {
    x: veryfiedMoveCordinates(calculateX, 0, workspacePosition.maxLeft),
    y: veryfiedMoveCordinates(calculateY, 0, workspacePosition.maxTop),
  };
  return newPosition;
};

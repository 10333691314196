import React, { FC, useEffect, useState } from "react";
import { Text, Modal } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { useStoreActions, useStoreState } from "../../store";
import { apiCall } from "../../untils/apiCall";
import { getContractName } from "../../helpers/projectHelper";
import { getMemberEmail } from "../../helpers/organizationHelper";

interface IRemoveMemberFromContract {
  handleCancel: () => void;
  memberID: string;
  contractID: string;
}
export const RemoveMemberFromContractModal: FC<IRemoveMemberFromContract> = ({
  handleCancel,
  memberID,
  contractID,
}) => {
  const {
    members,
    id: orgID,
    contracts,
  } = useStoreState((state) => state.organization);

  const { uid } = useStoreState((state) => state.user.userDetails);

  const [memberDetails, setMemberDetails] = useState({
    name: "",
    contractName: "",
  });

  const { removeMemeberFromContract } = useStoreActions(
    (actions) => actions.organization
  );

  const { removeContractFromUser } = useStoreActions((actions) => actions.user);

  useEffect(() => {
    setMemberDetails({
      name: getMemberEmail(members, memberID),
      contractName: getContractName(contractID, contracts),
    });
  }, [memberID]);

  const removeMemeber = async () => {
    const options = {
      orgID: orgID,
      contractID: contractID,
      memberID: memberID,
      value: null,
    };
    await apiCall(
      "organization/toogleMemberInContract",
      options,
      (response: any) => {
        removeMemeberFromContract({
          contractID: contractID,
          memberID: memberID,
        });
        if (uid === memberID) {
          removeContractFromUser(contractID);
        }
        handleCancel();
      }
    );
  };

  return (
    <Modal
      title={t(dictionary.confirmDelete)}
      labelClose={t(dictionary.cancel)}
      handleClose={handleCancel}
      handleConfirm={removeMemeber}
      labelConfirm={t(dictionary.delete)}
    >
      <Text>{`${t(dictionary.confirmRemoveUserFromOrg)} ${
        memberDetails.name
      } ${t(dictionary.fromContract)} ${memberDetails.contractName}`}</Text>
    </Modal>
  );
};

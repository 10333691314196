import React, { FC } from "react";
import { Aligment, Button, Icon, Text } from "venice-ui";

interface IEmptyState {
  title: string;
  action?: any;
  actionLabel?: string;
}
export const EmptyState: FC<IEmptyState> = ({ title, action, actionLabel }) => {
  return (
    <Aligment direction="column" gap={20} vPadding="50px">
      <Icon name="info" size={48} iconBgHoverColor="transparent"/>
      <Text>{title}</Text>
      {action && (
        <Button
          text={actionLabel ? actionLabel : "Click me"}
          onClick={action}
        />
      )}
    </Aligment>
  );
};

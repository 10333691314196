import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../components/SingIn/Login";
import { AutoAuth, PaymentFlow, RequireAuth, SuperAdmin } from "./authHelper";
import { Settings } from "../components/Settings";
import Section from "../components/Section/Section";
import Projects from "../components/Home/Projects";
import ProjectDetails from "../components/ProjectDetails/ProjectDetails";
import { PAYMENT_MODE } from "../helpers/settingsHelper";
import { ServerError } from "../components/Errors";
import { Organization } from "../Pages/Organization";
import { AdminPanel } from "../Pages/AdminPanel";
import { ResetPassword } from "../components/SingIn/ResetPassword";
import { PaymnetError } from "../components/Payments/PaymnetError";

const AppRouter: FC = () => {
  return (
    <>
      <Routes>
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route
          path="/login/:pageMode?/:code?"
          element={
            <AutoAuth>
              <Login />
            </AutoAuth>
          }
        />

        <Route
          path="/projects"
          element={
            <RequireAuth>
              <Projects />
            </RequireAuth>
          }
        />
        <Route
          path="/project/:projectID"
          element={
            <RequireAuth>
              <ProjectDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/project/:projectID/:sectionID"
          element={
            <RequireAuth>
              <Section />
            </RequireAuth>
          }
        />
        <Route
          path="/project/:projectID/:sectionID/:pageID"
          element={
            <RequireAuth>
              <Section />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path="/organization/:tab?/:action?"
          element={
            <RequireAuth>
              <Organization />
            </RequireAuth>
          }
        />
        <Route
          path="/adminpanel"
          element={
            <SuperAdmin>
              <AdminPanel />
            </SuperAdmin>
          }
        />
        <Route
          path="/payment-fail"
          element={
            <PaymentFlow>
              <PaymnetError />
            </PaymentFlow>
          }
        />
        <Route
          path="/server-error"
          element={
            <RequireAuth>
              <ServerError />
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Projects />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
};

export default AppRouter;

import React, { FC, useEffect, useState } from "react";
import { Content } from "../components";
import { apiCall } from "../../untils/apiCall";
import { useStoreState } from "../../store";
import { LoadingContent } from "../layouts/Loading";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { PaymentTemplate } from "./Partial/PaymentTemplate";

export const PaymnetError: FC = () => {
  const { orgRole, orgSesionID } = useStoreState(
    (state) => state.user.userDetails
  );

  const [pageData, setPageData] = useState({
    loading: false,
    sessionLink: "",
  });

  // const getCustomerPortal = async () => {
  //   if (orgRole === "admin") {
  //     const options = {
  //       // ToDo dodac z bazy
  //       sessionID: orgSesionID
  //       ,
  //     };
  //     await apiCall("payments/getCustomerPortal", options, (response: any) => {
  //      console.log(response)
  //       setPageData({
  //         loading: false,
  //         sessionLink: response.portalSession.url,
  //       });
  //     });
  //   }
  // };

  // useEffect(() => {
  //   getCustomerPortal();
  // }, []);

  const handleClick = () => {
    console.log("handleClick");
  };
  return (
    <Content>
      {pageData.loading ? (
        <LoadingContent />
      ) : (
        <PaymentTemplate
          title={t(dictionary.paymentTitleFail)}
          content={
            orgRole === "admin"
              ? t(dictionary.paymentContentFail)
              : t(dictionary.errorPaymentUser)
          }
          isAdmin={orgRole === "admin"}
          onClick={handleClick}
          butonLabel={t(dictionary.errorPaymentAction)}
        />
      )}
    </Content>
  );
};

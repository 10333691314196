import React, { FC } from "react";
import { Aligment } from "venice-ui";
import { ItemDetailsLabel, ItemDetailsText } from "./ItemDetailsPanel.styles";
import { IDetailsProps } from "../../types/formTypes";

interface IDetailsRowProps {
  label: string;
  value: string | number;
  type: string;
}
export const DetailsRow: FC<IDetailsRowProps> = ({
  label,
  value,
  type,
}: IDetailsRowProps) => {
  
  const formatLabel = (
    fieldType: string,
    fieldValue?: string | number
  ): string => {
    if (fieldValue) {
      if (fieldType === "text") {
        return fieldValue as string;
      } else if (fieldType === "number") {
        return fieldValue.toString();
      }
    }
    return "-";
  };

  return (
    <Aligment direction="column" align="flex-start">
      <ItemDetailsLabel>{label}</ItemDetailsLabel>
      <ItemDetailsText>{formatLabel(type, value)}</ItemDetailsText>
    </Aligment>
  );
};

interface IItemDetailsProps {
  data: IDetailsProps[];
}
export const ItemDetails: FC<IItemDetailsProps> = ({
  data,
}: IItemDetailsProps) => {
  return (
    <>
      {data.map((item) => (
        <DetailsRow
          label={item.label}
          value={item.value}
          key={item.label}
          type={item.type}
        />
      ))}
    </>
  );
};

import { action } from "easy-peasy";
import { IProjectDetails } from "../interface/projectDetails";

export const projectDetails: IProjectDetails = {
  projectDetails: {
    id: "",
    name: "",
    contract: "",
    sections: [],
    elements: [],
    references: [],
    mapElements: [],
  },

  setProjectDetails: action((state, payload) => {
    state.projectDetails = payload;
  }),
  resetProjectDetails: action((state) => {
    state.projectDetails = {
      id: "",
      name: "",
      contract: "",
      sections: [],
      elements: [],
      references: [],
      mapElements: [],
    };
  }),
  setProjectName: action((state, payload) => {
    state.projectDetails.name = payload.name;
    state.projectDetails.contract = payload.contract;
  }),

  addSection: action((state, payload) => {
    const newSections = [...state.projectDetails.sections, payload];
    state.projectDetails.sections = newSections;
  }),

  removeSection: action((state, payload) => {
    const newSections = state.projectDetails.sections.filter(
      (_section) => _section.id !== payload
    );
    state.projectDetails.sections = newSections;
  }),

  updateSection: action((state, payload) => {
    const sections = [...state.projectDetails.sections];
    let index = sections.findIndex((_sections) => _sections.id === payload.id);
    if (index !== -1) {
      sections[index] = { ...sections[index], ...payload };
    }
    state.projectDetails.sections = sections;
  }),

  updateRevision: action((state, payload) => {
    const sections = [...state.projectDetails.sections];
    let index = sections.findIndex(
      (_sections) => _sections.id === payload.sectionID
    );
    if (index !== -1) {
      sections[index].file = payload.file;
      sections[index].fileToken = payload.fileToken;
      sections[index].fileName = payload.fileName;
    }
    state.projectDetails.sections = sections;
  }),

  addElement: action((state, payload) => {
    const elements = [...state.projectDetails.elements, payload];
    state.projectDetails.elements = elements;
  }),

  updateElement: action((state, payload) => {
    const elements = [...state.projectDetails.elements];
    let index = elements.findIndex((_element) => _element.id === payload.id);
    if (index !== -1) {
      elements[index] = { ...elements[index], ...payload };
    }
    state.projectDetails.elements = elements;
  }),

  removeElement: action((state, payload) => {
    const elements = state.projectDetails.elements.filter(
      (item) => item.id !== payload
    );
    state.projectDetails.elements = elements;
  }),

  addReference: action((state, payload) => {
    const newReferences = [...state.projectDetails.references, payload];
    state.projectDetails.references = newReferences;
  }),

  updateReference: action((state, payload) => {
    const references = [...state.projectDetails.references];
    let index = references.findIndex(
      (_reference) => _reference.id === payload.id
    );
    if (index !== -1) {
      references[index] = { ...references[index], ...payload };
    }
    state.projectDetails.references = references;
  }),

  removeReference: action((state, payload) => {
    const references = state.projectDetails.references.filter(
      (item) => item.id !== payload
    );
    state.projectDetails.references = references;
  }),

  clearReferences: action((state, payload) => {
    const references = state.projectDetails.references.filter(
      (item) => item.sourceElementID !== payload
    );
    state.projectDetails.references = references;
  }),

  addMapElements: action((state, payload) => {
    const newAdditional = [...state.projectDetails.mapElements, payload];
    state.projectDetails.mapElements = newAdditional;
  }),

  updateMapElements: action((state, payload) => {
    const additional = [...state.projectDetails.mapElements];
    let index = additional.findIndex(
      (_additional) => _additional.id === payload.id
    );
    if (index !== -1) {
      additional[index] = { ...additional[index], ...payload };
    }
    state.projectDetails.mapElements = additional;
  }),

  removeMapElements: action((state, payload) => {
    const additional = state.projectDetails.mapElements.filter(
      (item) => item.id !== payload
    );
    state.projectDetails.mapElements = additional;
  }),
};

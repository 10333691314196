import { FC } from "react";
import { Aligment } from "venice-ui";
import { Tabs } from "../UI/Tabs";
import { t } from "../../helpers/userHelper";
import {dictionary} from "../../untils/dictionary"


interface IProjectDetailsHeader {
  activeTab: string;
  setActiveTab: (value: string) => void;
}
export const ProjectDetailsHeader: FC<IProjectDetailsHeader> = ({
  activeTab,
  setActiveTab,
}) => {
  const menuTabs = [
    {
      value: "sections",
      label: t(dictionary.sections),
    },
    {
      value: "log",
      label: t(dictionary.weldingLog),
    },
  ];

  return (
    <Aligment justify="space-between">
      <Tabs tabs={menuTabs} activeValue={activeTab} onClick={setActiveTab} />
    </Aligment>
  );
};

import { FC, useEffect } from "react";
import { useStoreActions } from "../../store";
import { IUserDetails } from "../../store/interface/user";
import { useNavigate } from "react-router-dom";

interface IAuth {
  details: IUserDetails;
  children: React.ReactNode
}
export const Auth: FC<IAuth> = ({ details,children }) => {
  const setUserDetails = useStoreActions(
    (actions) => actions.user.setUserDetails
  );

  useEffect(() => {
    setUserDetails(details);
  }, [details]);

  return (
    <>
      {children}
    </>
  );
};

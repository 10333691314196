import styled from "styled-components";
import { Colors } from "../../untils/Theme";
import border_svg from '../../svg/border_cloud.svg'
import border_active_svg from '../../svg/border_cloud_active.svg'

interface IAdditionalElementsWrapperProps {
  isEditMode: boolean;
  isActive: boolean;
  origin: boolean;
}
export const AdditionalElementsWrapper = styled.div<IAdditionalElementsWrapperProps>`
  z-index: ${(p) => (p.isActive ? 50 : p.isEditMode ? 40 : 20)};
  position: absolute;
  transform-origin: ${(p) => (p.origin ? "center left" : "center center")};
`;

interface IDotProps {
  isActive: boolean;
}
export const Dot = styled.div<IDotProps>`
  border-radius: 50%;
  background-color: ${(p) => (p.isActive ? Colors.seaBlue : Colors.red)};
  width: 100%;
  height: 100%;
`;

interface ITriageProps {
  size: number;
  isActive: boolean;
}

export const Triage = styled.div<ITriageProps>`
  width: 100%;
  height: 100%;
  border-left: ${(p) => p.size / 2 + "px"} solid transparent;
  border-right: ${(p) => p.size / 2 + "px"} solid transparent;
  border-bottom: ${(p) => p.size + "px"} solid
    ${(p) => (p.isActive ? Colors.seaBlue : Colors.red)};
`;

interface IEdgeProps {
  isActive: boolean;
}
export const Edge = styled.div<IEdgeProps>`
  background-color: ${(p) => (p.isActive ? Colors.seaBlue : Colors.red)};
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ElementControl = styled.div`
  position: absolute;
  z-index: 52;
  width: 24px;
  height:24px;
  border-radius: 50%;
  border: 1px solid ${Colors.seaBlue};
  cursor: grab;
  box-sizing: border-box;
  display:flex;
  justify-content: center;
    align-items: center;
`;

interface ICloudProps {
  isActive: boolean;
  factor:number
}
export const Cloud = styled.div<ICloudProps>`
  width: 100%;
  height: 100%;
  position: relative;
  border:2px solid;
  border-image:url(${p => p.isActive ? border_active_svg : border_svg})  16 / ${p => 10 * p.factor} / 0 round;
  `;
//   ${(p) => (p.isActive ? Colors.seaBlue : Colors.red)};

import React, { FC, useState } from "react";
import { Form, Modal } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { checkCompanyDetails } from "../PasswordCheck/checks";
import { countryScope } from "../SingIn/Partial/RegisterPaymentDetails";

interface IEditBillingModal {
  onClose: () => void;
  handleSave: (
    companyAdressLine1: string,
    companyAdressLine2: string,
    companyCity: string,
    companyCountry: string,
    companyName: string,
    companyNip: number,
    companyPostCode: string
  ) => void;
  companyName: string;
  companyAdressLine1: string;
  companyAdressLine2: string;
  companyPostCode: string;
  companyCity: string;
  companyCountry: string;
  companyNip: number | undefined;
}
export const EditBillingModal: FC<IEditBillingModal> = ({
  onClose,
  handleSave,
  companyAdressLine1,
  companyAdressLine2,
  companyCity,
  companyCountry,
  companyName,
  companyNip,
  companyPostCode,
}) => {
  const [billingAddress, updateBillingAddress] = useState({
    companyAdressLine1,
    companyAdressLine2,
    companyCity,
    companyCountry,
    companyName,
    companyNip,
    companyPostCode,
  });
  const updateBillingInfo = () => {
    handleSave(
      billingAddress.companyAdressLine1,
      billingAddress.companyAdressLine2,
      billingAddress.companyCity,
      billingAddress.companyCountry,
      billingAddress.companyName,
      billingAddress.companyNip as number,
      billingAddress.companyPostCode
    );
  };
  const updateValue = (name: string, value: string | number) => {
    updateBillingAddress({
      ...billingAddress,
      [name]: value,
    });
  };

  const editForm = {
    action: updateValue,
    data: [
      {
        row: [
          {
            type: "text",
            label: t(dictionary.companyName),
            value: billingAddress.companyName,
            name: "companyName",
            autofocus: true,
          },
        ],
      },
      {
        row: [
          {
            type: "text",
            label: t(dictionary.companyAdressLine1),
            value: billingAddress.companyAdressLine1,
            name: "companyAdressLine1",
          },
        ],
      },
      {
        row: [
          {
            type: "text",
            label: t(dictionary.companyAdressLine2),
            value: billingAddress.companyAdressLine2,
            name: "companyAdressLine2",
          },
        ],
      },
      {
        row: [
          {
            type: "text",
            label: t(dictionary.companyPostCode),
            value: billingAddress.companyPostCode,
            name: "companyPostCode",
          },
          {
            type: "text",
            label: t(dictionary.companyCity),
            value: billingAddress.companyCity,
            name: "companyCity",
          },
        ],
      },
      {
        row: [
          {
            type: "select",
            label: t(dictionary.companyCountry),
            value: billingAddress.companyCountry,
            options: countryScope,
            zIndex: 1000,
            position: "left",
            name: "companyCountry",
          },
          {
            type: "text",
            label: t(dictionary.companyNip),
            value: billingAddress.companyNip,
            name: "companyNip",
          },
        ],
      },
    ],
  };

  return (
    <Modal
      title={`${t(dictionary.editBillingAddressModalTitle)}`}
      handleClose={onClose}
      handleConfirm={updateBillingInfo}
      labelClose={t(dictionary.cancel)}
      labelConfirm={t(dictionary.save)}
      submitDisabled={checkCompanyDetails(
        billingAddress.companyName,
        billingAddress.companyAdressLine1,
        billingAddress.companyPostCode,
        billingAddress.companyCity,
        billingAddress.companyCountry,
        billingAddress.companyNip,
        "",
      )}
    >
      <Form formData={editForm} />
    </Modal>
  );
};

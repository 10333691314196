import React, { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../untils/Theme';

interface ITabWrapper {
  active?:boolean;
}
const TabWrapper= styled.div<ITabWrapper>`
  padding:0.4rem 0.8rem;
  font-size:1.4rem;
  border-bottom:2px solid ${p => p.active ? Colors.seaBlue: 'transparent'};
  transition:300ms;
  cursor:pointer;

`
interface ITab{
  label:string,
  value:string,
  onClick:(value: string) => void,
  active:boolean
}
export const Tab:FC<ITab> =({label,active,onClick, value}:ITab)=>{
  return(
    <TabWrapper active={active} onClick={() => onClick(value)}>
      {label}
    </TabWrapper>
  )
}
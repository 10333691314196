import styled from "styled-components";
import { Colors } from "../../untils/Theme";

interface IPlanBoxWrapperProps {
  active: boolean;
}

export const PlanBoxWrapper = styled.div<IPlanBoxWrapperProps>`
  background-color: ${(p) => (p.active ? Colors.appBlue: Colors.white)};
  color: ${(p) => (p.active ? Colors.white : Colors.text)};
  flex: 1;
  padding: 20px 0;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
`;

export const PlanBoxUsers = styled.div`
  font-size: 2.2rem;
  font-weight: bold;
`;

export const PlanBoxPriceDetails = styled.div`
    font-size:1.4rem;
`

export const PlanBoxStatus = styled.div`
    padding-top:20px;
    height:4rem;
    display: flex;
    font-size:1.4rem;
    align-items:center;
    color:${Colors.green};
`

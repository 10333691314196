import React, { FC, useEffect, useRef, useState } from "react";
import { Section, Tile, Aligment } from "venice-ui";
import { IElementType } from "../../definitions/element";
import { Colors } from "../../untils/Theme";
import { ItemDetails } from "./ItemDetails";
import {
  DetailPanelWrapper,
  ItemDetailsAction,
} from "./ItemDetailsPanel.styles";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { IAddtionalElements } from "../../definitions/additional";
import { MapElementsList } from "./MapElementsList";
import { isJoint } from "../Section/sectionModel";
import { ElementDetails } from "./ElementDetails";
import { IJointType, getJointDetails } from "../../definitions/joint";

interface IItemDetailsPanelProps {
  element?: IElementType | IAddtionalElements;
  type?: string;
  handleEdit: (id: string, isReference: boolean) => void;
  handleDuplicate?: (id: string) => void;
  handelSetReference?: (id: string) => void;
  handleRemove: (id: string) => void;
  handleLockChange?: (id: string, lock: boolean, mapElement: boolean) => void;
  handleSaveChanges: (element: IAddtionalElements, isSource: boolean) => void;
  editMode: boolean;
  additional: IAddtionalElements[];
  showDetails: (
    id: string,
    isMapElement: boolean,
    isReference: boolean
  ) => void;
}

export const ItemDetailPanel: FC<IItemDetailsPanelProps> = ({
  element,
  type = "",
  editMode,
  handleEdit,
  handleDuplicate,
  handelSetReference,
  handleRemove,
  handleLockChange,
  handleSaveChanges,
  additional,
  showDetails,
}) => {
  // console.log(element, type)
  const detailPanelRef = useRef<HTMLDivElement>(null);
  const [panelHeight, setPanelHeight] = useState(0);

  useEffect(() => {
    if (detailPanelRef.current) {
      setPanelHeight(detailPanelRef.current.offsetTop);
    }
  }, [panelHeight]);

  const toogleLock = () => {
    if (element?.id) {
      let lock = element.lock ? false : true;
      //ToDo dodać obsługę , bo narazie jest fals ena sztywno
      handleLockChange && handleLockChange(element.id, lock, false);
    }
  };
  const duplicatElement = (elementToDoplicate: IElementType) => {
    let id = elementToDoplicate.id;
    if (
      "sourceElementID" in elementToDoplicate! &&
      elementToDoplicate.sourceElementID !== undefined
    ) {
      id = elementToDoplicate.sourceElementID;
    }
    handleDuplicate && handleDuplicate(id);
  };

  const setReference = (elementToDoplicate: IElementType) => {
    let id = elementToDoplicate.id;
    if (
      "sourceElementID" in elementToDoplicate! &&
      elementToDoplicate.sourceElementID !== undefined
    ) {
      id = elementToDoplicate.sourceElementID;
    }
    handelSetReference && handelSetReference(id);
  };

  const getSectionTitle = () => {
    if (element) {
      return editMode
        ? t(dictionary.selectedElement)
        : t(dictionary.selectedJoint);
    } else {
      return editMode ? t(dictionary.mapElementList) : t(dictionary.jointList);
    }
  };
  return (
    <DetailPanelWrapper
      id="detail_panel"
      ref={detailPanelRef}
      top={panelHeight}
    >
      <Tile backgroundColor={Colors.tileGray} height="inherit">
        <Section title={getSectionTitle()} backgroundColor={Colors.tileGray}>
          {editMode && !element && (
            <MapElementsList
              additional={additional}
              showDetails={showDetails}
            />
          )}
          {element && (
            <Aligment direction="column" align="stretch">
              {isJoint(element) ? (
                <ItemDetails
                  data={getJointDetails( element as IJointType)!}
                />
              ) : (
                <ElementDetails
                  element={element as IAddtionalElements}
                  saveChanges={handleSaveChanges}
                />
              )}
              <Aligment direction="column" width="100%" justify="center">
                {isJoint(element) && (
                  <ItemDetailsAction
                    onClick={() =>
                      handleEdit(
                        element.id,
                        "sourceElementID" in element! &&
                          element.sourceElementID !== undefined
                      )
                    }
                  >
                    {t(dictionary.edit)}
                  </ItemDetailsAction>
                )}
                <>
                  {isJoint(element) && (
                    <>
                      <ItemDetailsAction
                        onClick={() => duplicatElement(element as IElementType)}
                      >
                        {t(dictionary.duplicate)}
                      </ItemDetailsAction>

                      <ItemDetailsAction
                        onClick={() => setReference(element as IElementType)}
                      >
                        {t(dictionary.setReference)}
                      </ItemDetailsAction>
                      {/* <ItemDetailsAction onClick={() => toogleLock()}>
                        {element!.lock
                          ? t(dictionary.unlock)
                          : t(dictionary.lock)}
                        {element!.lock}
                      </ItemDetailsAction> */}
                    </>
                  )}
                </>
                <ItemDetailsAction onClick={() => handleRemove(element.id)}>
                  {t(dictionary.delete)}
                </ItemDetailsAction>
              </Aligment>
            </Aligment>
          )}
        </Section>
      </Tile>
    </DetailPanelWrapper>
  );
};

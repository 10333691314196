import React, { FC, useEffect, useState } from "react";
import { Aligment, Icon } from "venice-ui";
import { ItemElement, ListWrapper } from "../components";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { DetailsText } from "../Organization/organizations.style";
import { getContractsForMemeber } from "../../helpers/organizationHelper";
import { useStoreState } from "../../store";

interface IMemberContractList {
  memberID: string;
  handleDelete: (memberID: string) => void;
}
export const MemberContractList: FC<IMemberContractList> = ({
  memberID,
  handleDelete,
}) => {
  const { contracts, id } = useStoreState((state) => state.organization);
  const [contractList, updateContractList] = useState(
    getContractsForMemeber(memberID, contracts)
  );
  useEffect(() => {
    updateContractList(getContractsForMemeber(memberID, contracts));
  }, [memberID, contracts]);

  return (
    <Aligment>
      {contractList.length > 0 ? (
        <ListWrapper>
          {contractList.map((_contract) => (
            <ItemElement className="item-list">
              {_contract.name}
              <Icon
                name="remove_circle"
                iconBgHoverColor="transparent"
                size={18}
                onClick={() => handleDelete(_contract.id)}
              />
            </ItemElement>
          ))}
        </ListWrapper>
      ) : (
        <Aligment style={{ height: "100%" }}>
          <DetailsText>{t(dictionary.noContractsInMember)}</DetailsText>
        </Aligment>
      )}
    </Aligment>
  );
};

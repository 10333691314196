import React, { FC, useEffect, useState } from "react";
import { Aligment, Section, Tile } from "venice-ui";
import { Colors } from "../../untils/Theme";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { DetailsPanelText, Spacer } from "./organizations.style";
import { useStoreActions, useStoreState } from "../../store";
import {
  getContractMembers,
} from "../../helpers/organizationHelper";
import { MemeberList } from "../ContractDetails/MemeberList";
import { ItemDetailsAction } from "../ItemDetailPanel/ItemDetailsPanel.styles";

interface IContractDetailsPanel {
  contractID: string;
  handleDelete: (memberID: string) => void;
  handleAddModal: (contractID:string)=>void
  handleEditModal: (contractID:string)=>void
}
export const ContractDetailsPanel: FC<IContractDetailsPanel> = ({
  contractID,
  handleDelete,
  handleAddModal,
  handleEditModal
}) => {
  const { members, contracts, id } = useStoreState(
    (state) => state.organization
  );
  const { addMemeberFromContract } = useStoreActions(
    (actions) => actions.organization
  );

  const [contractMemers, setContractMembers] = useState(
    getContractMembers(contractID, contracts, members)
  );


  useEffect(() => {
    if (contractID !== "") {
      setContractMembers(getContractMembers(contractID, contracts, members));
    }
  }, [contractID, contracts, members]);

  
  return (
    <Tile backgroundColor={Colors.tileGray} height="inherit">
      {contractID === "" ? (
        <Aligment style={{ height: "100%" }}>
          <DetailsPanelText>
            {t(dictionary.emptyContractMembers)}
          </DetailsPanelText>
        </Aligment>
      ) : (
        <>
          <Section
            title={t(dictionary.contractMembers)}
            backgroundColor={Colors.tileGray}
          >
            <Aligment direction="column" align="stretch">
              <MemeberList
                orgMembers={contractMemers}
                handleDelete={handleDelete}
              />
              <Spacer height='2rem'/>
              <Aligment direction="column" width="100%" justify="center">
                <ItemDetailsAction onClick={() => handleAddModal(contractID)}>
                  {t(dictionary.addMemeberAction)}
                </ItemDetailsAction>
                <ItemDetailsAction onClick={() => handleEditModal(contractID)}>
                  {t(dictionary.changeName)}
                </ItemDetailsAction>
                <ItemDetailsAction onClick={() => handleDelete(contractID)}>
                  {t(dictionary.deleteContract)}
                </ItemDetailsAction>
              </Aligment>
            </Aligment>
          </Section>
        </>
      )}
    </Tile>
  );
};

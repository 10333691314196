import styled from "styled-components";
import { Colors } from "../untils/Theme";

export const AppWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  font-size: 1.6rem;
  background-color: ${Colors.backgroundGray};
  flex-direction: column;
`;
export const ContentArea = styled.div`
  width: 100%;
  height: calc(100vh - 4rem);
  display: flex;
  font-size: 1.6rem;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const ContentLoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ContentLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  height: calc(100vh - 9rem);
  flex-direction: column;
  justify-content: center;
`;

export const ContentFull = styled.div`
  width: 100%;
  padding: 1rem;
  height: 100%;
`;

interface IContentWrapper {
  height: string;
}
export const ContentWrapper = styled.div<IContentWrapper>`
  height: ${(p) => p.height};
  overflow: auto;
  width: 100%;
`;

export const ExportTileWrapper = styled.div`
  padding-bottom: 5px;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: #333333;
`;
export const LinkSmall = styled.a`
  text-decoration: underline;
  color: #333333;
  font-size:1.4rem;
`;
export const HeaderOption = styled.div`
  cursor: pointer;
  margin-right: 20px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .item-list:nth-child(even) {
    background-color: #f3f3f3;
  }
`;
export const ItemElement = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
`;

export const InfoContent = styled.div`
  height: calc(100vh - 12rem);
  width: 100%;
  max-width: 52rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
export const OrgDetailsLoader = styled.div`
  height: calc(100vh - 16rem);
  width: 100%;
`;

interface IHeaderLinkProps {
  active: boolean;
}
export const HeaderLink = styled.div<IHeaderLinkProps>`
  display: flex;
  color: ${Colors.white};
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  box-sizing: border-box;
  transitions: 300ms;
  font-size: 1.4rem;
  border-bottom: 0.2rem solid
    ${(p) => (p.active ? Colors.seaBlue : Colors.appBlue)};
  &:hover {
    color: ${Colors.seaBlue};
  }
`;


import React, { FC, useEffect, useRef, useState } from "react";
import { IElementType, IExtendElementType } from "../../definitions/element";
import { Table } from "venice-ui";
import { pointsListHeaders } from "../../definitions/ListDefinition";
import { TableWrapper, ItemListContent } from "../ItemList/ItemList.styles";
import {
  getElement,
  setOptionsSaveElement,
  setShowListPoints,
} from "../Section/sectionModel";
import { useStoreActions, useStoreState } from "../../store";
import { PointModal } from "../PointModal/PointModal";
import { EmptyState } from "../UI/EmptyState";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { IAddtionalElements } from "../../definitions/additional";
import { apiCall } from "../../untils/apiCall";
import { ifHaveReferences } from "../../helpers/weldHelpers";

export const PointsList: FC = () => {
  const {
    sections,
    elements,
    references,
    id: projectID,
  } = useStoreState((state) => state.projectDetails.projectDetails);
  const { orgID } = useStoreState((state) => state.user.userDetails);

  const [pointDetails, updatePointDetails] = useState({
    show: false,
    id: "",
    mode: "read",
  });

  const [loading, setLoading] = useState(false);

  const closeLoader = () => {
    setLoading(false);
  };

  const { updateElement, removeElement, removeReference, clearReferences } =
    useStoreActions((actions) => actions.projectDetails);

  const showPointModal = (id: string) => {
    updatePointDetails({
      show: true,
      id: id,
      mode: "read",
    });
  };

  const hidePointModal = () => {
    updatePointDetails({
      show: false,
      id: "",
      mode: "read",
    });
  };

  const deleteElement = async (
    id: string,
    mapElement: boolean,
    isReference: boolean
  ) => {
    await apiCall(
      "projects/removeElement",
      {
        orgID: orgID,
        projectID: projectID,
        elementID: id,
        mapElement: mapElement,
        isReference: isReference,
      },
      (response: any) => {
        setLoading(false);
        if (isReference) {
          removeReference(id);
        } else {
          if (ifHaveReferences(id, references)) {
            clearReferences(id);
          }
          removeElement(id);
        }
        hidePointModal();
      }
    );
  };

  const saveElement = async (element: IElementType | IAddtionalElements) => {
    const options = setOptionsSaveElement(
      element as IElementType,
      orgID,
      projectID!
    );
    await apiCall("projects/saveElement", options, (response: any) => {
      setLoading(false);
      updateElement(element as IElementType);
      hidePointModal();
    });
  };

  const points = setShowListPoints(elements, sections);

  const listRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    if (listRef.current) {
      setTableHeight(listRef.current.offsetTop);
    }
  }, [listRef]);

  return (
    <>
      {points.length > 0 ? (
        <>
          <TableWrapper id="list" ref={listRef} top={tableHeight}>
            <ItemListContent>
              <Table
                headers={pointsListHeaders}
                elements={points}
                onRowClick={showPointModal}
                selectable={false}
                sortable={true}
              ></Table>
            </ItemListContent>
          </TableWrapper>
          {pointDetails.show && (
            <PointModal
              handleCancel={hidePointModal}
              elementID={pointDetails.id}
              element={getElement(pointDetails.id, elements)}
              handleDelete={deleteElement}
              handleSave={saveElement}
              modalMode="read"
              fullSpec={true}
              loading={loading}
              setLoading={closeLoader}
            />
          )}
        </>
      ) : (
        <EmptyState
          title={t(dictionary.emptyStateProjectListTitle)}
          actionLabel={t(dictionary.addSection)}
        />
      )}
    </>
  );
};

import { dictionary } from "../untils/dictionary";
import { t } from "./userHelper";

export enum LOGIN_MODE {
  login = "login",
  singin = "singin",
  forgot = "forgot",
}

export type TLoginMode =
  | LOGIN_MODE.login
  | LOGIN_MODE.singin
  | LOGIN_MODE.forgot;

export const errorMsg = (errorCode: string) => {
  switch (errorCode) {
    case "auth/user-exist":
      return t(dictionary.userExist);
    case "wrong-email":
      return t(dictionary.wrongEmail);
    case "wrong-code":
      return t(dictionary.wrongCode);
    case "auth/incorrect-email":
      return t(dictionary.incorectEmail);
  }
};

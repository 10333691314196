import React, { FC } from "react";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { Aligment, Table,Text } from "venice-ui";
import { useStoreState } from "../../store";
import { getUnassignMemeber } from "../../helpers/organizationHelper";

interface IAddMember {
  contractID: string;
  onSelect: (id: string) => void;
}
export const AddMember: FC<IAddMember> = ({ onSelect, contractID }) => {
  const { members, contracts } = useStoreState((state) => state.organization);

  const handleSelect = (id: string) => {
    onSelect(id);
  };
  const tableHeaders = [
    {
      name: t(dictionary.email),
      valueSource: "email",
    },
  ];
  const membersScope = getUnassignMemeber(contractID, contracts, members);
  return (
    <>
      {membersScope.length > 0 ? (
        <Table
          headers={tableHeaders}
          elements={membersScope}
          selectable
          onRowClick={handleSelect}
        />
      ) : (
        <Aligment vPadding="16px">
            <Text>{t(dictionary.noAvailableMembers)}</Text>
        </Aligment>
      )}
    </>
  );
};

import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Card, Table, Form } from "venice-ui";
import { apiCall } from "../../untils/apiCall";
import { Content, ContentFull, ContentWrapper } from "../components";
import { LoadingContent } from "../layouts/Loading";
import {
  DeleteProjectModal,
  EditProjectData,
  EditProjectModal,
} from "../Modals";

import { IAction } from "venice-ui/dist/types/types";
import {
  getContactsNames,
  getContractName,
  getProjectParams,
} from "../../helpers/projectHelper";
import { EmptyState } from "../UI/EmptyState";
import { dictionary } from "../../untils/dictionary";
import { useStoreActions, useStoreState } from "../../store";
import { t } from "../../helpers/userHelper";
import { IEditProject } from "../../store/interface/projects";
import { EmptyContracts } from "./EmptyContracts";

interface IPageProps {
  loading: boolean;
  actionLoader: boolean;
}

const Projects: FC = () => {
  const navigate = useNavigate();

  const {
    setProjects,
    addProject: addProjectToStore,
    updateProject,
    deleteProject: deleteProjectFromStore,
  } = useStoreActions((actions) => actions.projects);

  const { uid, contracts, orgID, orgRole, email,lang } = useStoreState(
    (state) => state.user.userDetails
  );
  const { projects } = useStoreState((state) => state.projects);

  const [modal, toogleModal] = useState(false);
  const [deleteProjectModal, toggleDeleteProjectModal] = useState({
    show: false,
    id: "",
    projectName: "",
  });

  const [pageProps, updatePageProps] = useState<IPageProps>({
    loading: true,
    actionLoader: false,
  });
  const [project, setProject] = useState({
    name: "",
    contractID: "",
    createdTime: 0,
    updatedTime: 0,
  });

  const [selctedProject, setSelectedProject] = useState<EditProjectData>({
    show: false,
    id: "",
    name: "",
    contract: "",
  });

  const cancelEditProjectModal = () => {
    setSelectedProject({
      show: false,
      id: "",
      name: "",
      contract: "",
    });
  };

  const openEditProjectModal = (id?: string) => {
    if (id) {
      const projectParams = getProjectParams(projects, id);
      setSelectedProject({
        show: true,
        id: id,
        name: projectParams.name,
        contract: projectParams.contract,
      });
    }
  };

  const onSubmitEditProject = (dataSet: EditProjectData) => {
    const editedProject: IEditProject = {
      id: dataSet.id,
      name: dataSet.name,
      contract: dataSet.contract,
      contractLabel: getContractName(dataSet.contract, contracts),
    };
    updateProject(editedProject);
    updatePageProps({
      ...pageProps,
    });
    cancelEditProjectModal();
  };
  const getProjects = async () => {
    const options = {
      orgID: orgID,
      contractIDs: contracts.map((item) => item.id),
      lang: lang
    };
    await apiCall("projects/getProjects", options, (response: any) => {
      const userProjects = getContactsNames(response.projects, contracts);
      setProjects(userProjects);
      updatePageProps({
        ...pageProps,
        loading: false,
      });
    });
  };
  useEffect(() => {
    getProjects();
  }, []);

  const selectProject = async (id: string) => {
    navigate(`/project/${id}`);
  };

  const addProject = async () => {
    if (!pageProps.actionLoader && !validationNewProject()) {
      updatePageProps({ ...pageProps, actionLoader: true });
      const options = {
        project: {
          name: project.name.trim(),
          createdTime: Date.now(),
          updatedTime: Date.now(),
          author: uid || "",
          contract: project.contractID,
        },
        orgID: orgID,
      };

      await apiCall("projects/createProject", options, (response: any) => {
        options.project.author = email;
        addProjectToStore({
          id: response.newProjectID,
          contractLabel: getContractName(options.project.contract, contracts),
          ...options.project,
        });
        updatePageProps({
          ...pageProps,
          actionLoader: false,
        });
        hideModal();
      });
    }
  };
  const hideModal = () => {
    toogleModal(false);
  };
  const showModal = () => {
    setProject({ ...project, name: "", contractID: "" });
    toogleModal(true);
  };

  const updateForm = (field: string, value: string | number) => {
    setProject({ ...project, [field]: value });
  };

  const validationNewProject = () => {
    const name = project.name as string;
    return name.trim().length === 0 || project.contractID === "";
  };
  const contractsScope = contracts.map((item) => {
    return { value: item.id, label: item.name };
  });
  const newProjectForm = {
    action: updateForm,
    data: [
      {
        type: "text",
        label: t(dictionary.projectName),
        value: project.name,
        name: "name",
        autofocus: true,
        submit: () => addProject(),
      },
      {
        type: "select",
        label: t(dictionary.contract),
        value: project.contractID,
        options: contractsScope,
        zIndex: 1000,
        position: "left",
        name: "contractID",
      },
    ],
  };
  const headers = [
    {
      name: t(dictionary.projectName),
      valueSource: "name",
    },

    {
      name: t(dictionary.contract),
      valueSource: "contractLabel",
    },
    {
      name: t(dictionary.author),
      valueSource: "author",
    },
    {
      name: t(dictionary.createDate),
      valueSource: "createdTime",
      date: true,
    },
  ];

  const showDeleteProjectModal = (id?: string) => {
    if (id) {
      toggleDeleteProjectModal({
        show: true,
        id: id,
        projectName: getProjectParams(projects, id).name,
      });
    }
  };

  const hideDeleteProjectModal = () => {
    toggleDeleteProjectModal({
      show: false,
      id: "",
      projectName: "",
    });
  };

  const deleteProject = async (id: string) => {
    await apiCall(
      "projects/removeProject",
      {
        orgID: orgID,
        projectID: id,
      },
      (response: any) => {
        deleteProjectFromStore(id);
        hideDeleteProjectModal();
      }
    );
  };

  const moreActions: IAction[] = [
    {
      label: t(dictionary.changeName),
      action: openEditProjectModal,
    },
    {
      label: t(dictionary.deleteProject),
      action: showDeleteProjectModal,
    },
  ];

  if (pageProps.loading) {
    return (
      <ContentFull>
        <LoadingContent />
      </ContentFull>
    );
  }

  if (contracts.length === 0) {
    return <EmptyContracts isAdmin={orgRole === "admin"} />;
  }
  return (
    <Content>
      <Card
        title={t(dictionary.projects)}
        actionLabel={t(dictionary.addProject)}
        handleClick={showModal}
        shadow={false}
        height="calc(100vh - 6rem)"
      >
        {projects.length > 0 ? (
          <ContentWrapper height="calc(100vh - 13.4rem)">
            <Table
              headers={headers}
              elements={projects}
              onRowClick={selectProject}
              selectable={false}
              sortable={false}
              filtrable={false}
              moreActions={moreActions}
            ></Table>
          </ContentWrapper>
        ) : (
          <EmptyState
            title={t(dictionary.emptyStateProjectTitle)}
            actionLabel={t(dictionary.addProject)}
            action={() => showModal()}
          />
        )}
      </Card>
      {modal && (
        <Modal
          title={t(dictionary.addProject)}
          handleClose={() => hideModal()}
          handleConfirm={() => addProject()}
          labelClose={t(dictionary.cancel)}
          labelConfirm={t(dictionary.addProject)}
          submitDisabled={validationNewProject()}
        >
          <Form formData={newProjectForm} />
        </Modal>
      )}
      {selctedProject.show && (
        <EditProjectModal
          onClose={cancelEditProjectModal}
          onConfirm={onSubmitEditProject}
          dataSet={selctedProject}
        />
      )}
      {deleteProjectModal.show && (
        <DeleteProjectModal
          handleCancel={() => hideDeleteProjectModal()}
          handleDelete={deleteProject}
          id={deleteProjectModal.id}
          name={deleteProjectModal.projectName}
        />
      )}
    </Content>
  );
};

export default Projects;

import React, { FC } from "react";
import { Modal, Text } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";

interface IDeleteProjectModalProps {
  handleCancel: ()=>any
  handleDelete: (id:string)=> void
  id: string
  name: string;
}

export const DeleteProjectModal: FC<IDeleteProjectModalProps> = ({
  handleCancel,
  handleDelete,
  name,
  id,
}) => {
  
  
  return (
    <Modal 
      title={t(dictionary.confirmDelete)}
      labelClose={t(dictionary.cancel)}
      handleClose={handleCancel}
      handleConfirm={()=>handleDelete(id)}
      labelConfirm={t(dictionary.delete)} 
    >
      <Text>
        {name}
      </Text>
      <Text >
      {t(dictionary.doYouWantDeleteProject)} 
      </Text>

    </Modal>
  );
};

import styled from "styled-components";

export const SectionHeaderWrapper = styled.div`
  margin-bottom: 0.8rem;
  width: 100%;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
  align-items: center;
  flex-direction: row;
  display:flex;
`;

export const ZoomSection = styled.div`
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;
`;
export const ZoomScale = styled.div`
  font-size: 1.4rem;
  padding: 0.4rem 0.8rem;
  width: 65px;
  text-align: right;
  overflow: hidden;
`;

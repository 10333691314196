import React, { FC } from "react";
import styled from "styled-components";
import { Tab } from "./Tab";

const TabsWrapper = styled.div`
  display: flex;
`;
export interface ITabConfig {
  value: string;
  label: string;
}

interface ITabs {
  activeValue: string;
  tabs: ITabConfig[];
  onClick: (value: string) => void;
}

export const Tabs: FC<ITabs> = ({ activeValue, tabs, onClick }: ITabs) => {
  return (
    <TabsWrapper>
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.value}
            label={tab.label}
            active={activeValue === tab.value}
            onClick={onClick}
            value={tab.value}
          />
        );
      })}
    </TabsWrapper>
  );
};

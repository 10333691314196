import React, { FC, ReactText } from "react";
import { Input } from "venice-ui";
import { IntputWrapperBig, LoginFormSection, LoginSubtitle } from "../Login.styles";
import { t } from "../../../helpers/userHelper";
import { dictionary } from "../../../untils/dictionary";

interface IRegisterVerificationCodeProps {
  inputValue: string;
  handleChange: (name: string, value: ReactText) => void;
  handleSubmit:()=>void
}
export const RegisterVerificationCode: FC<IRegisterVerificationCodeProps> = ({
  inputValue,
  handleChange,
  handleSubmit
}) => {
  
  
  return (
    <>
      <LoginSubtitle>
      {t(dictionary.verificationCodeInstruction)}
        
      </LoginSubtitle>
      <LoginFormSection>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.inputVerificationCode)}
            type="text"
            labelPosition="top"
            value={inputValue}
            name="verifyCode"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, value as string)
            }
            autoFocus={true}
            handleSubmit={handleSubmit}
          />
        </IntputWrapperBig>
      </LoginFormSection>
    </>
  );
};

import React, { FC, useEffect, useState } from "react";
import { Form, Modal } from "venice-ui";
import { apiCall } from "../../untils/apiCall";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { useStoreState } from "../../store";

export interface IFileUpdate {
  file: string;
  fileToken: string;
  fileName: string;
  sectionID:string;
}
interface IRevisionData {
  show: boolean;
  projectID: string;
  sectionID: string;
  oldFileName: string;
  file: File | undefined;
}

interface IAddRevisionModal {
  onClose: () => void;
  onConfirm: (fileUpdate: IFileUpdate) => void;
  dataSet: IRevisionData;
}

export const AddRevisionModal: FC<IAddRevisionModal> = ({
  onClose,
  onConfirm,
  dataSet,
}) => {
  const { orgID,uid } = useStoreState((state) => state.user.userDetails);
  const [editData, setEditData] = useState<IRevisionData>({
    show: true,
    file: undefined,
    projectID: "",
    sectionID: "",
    oldFileName: "",
  });

  useEffect(() => {
    setEditData({ ...dataSet });
  }, [dataSet]);

  const updateForm = (field: string, value: string) => {
    setEditData({
      ...editData,
      [field]: value,
    });
  };

  const updateProjectFile = async () => {
    let formData = new FormData();
    formData.append("orgID", orgID);
    formData.append("projectID", editData.projectID!);
    formData.append("sectionID", editData.sectionID!);
    formData.append("oldFile", editData.oldFileName!);
    formData.append("file", editData!.file!);
    await apiCall(
      "projects/addRevision",
      formData,
      (response:any) => {
        onConfirm(response.newRevisionDetails);
      }
    );
  };

  const revisionForm = {
    action: updateForm,
    data: [
      {
        type: "file",
        label: t(dictionary.pdfFile),
        value: editData?.file,
        name: "file",
      },
    ],
  };
  return (
    <>
      {editData.show && (
        <Modal
          title={`${t(dictionary.newRevision)}`}
          handleClose={onClose}
          handleConfirm={() => updateProjectFile()}
          labelClose={t(dictionary.cancel)}
          labelConfirm={t(dictionary.save)}
          submitDisabled={editData.file === undefined}
        >
          <Form formData={revisionForm} />
        </Modal>
      )}
    </>
  );
};

import React, { FC } from "react";
import { Aligment, Card, Loader, Tile, Text } from "venice-ui";
import {
  ContentLoading,
  ContentLoadingWrapper,
} from "../components";
import logo from ".././../weldingApp_logo_white.svg";
import { WelcomeLogo } from "../SingIn/Login.styles";
import { GlobalLoaderWrapper } from "../publicComponents";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";

export const LoadingContent: FC = () => {
  return (
    <ContentLoadingWrapper id="bb">
      <Card shadow={false}>
        <ContentLoading>
          <Loader size="medium" />
          <Text>
            <Aligment justify="center" align="center" vPadding="16px">
              {t(dictionary.loading)}
            </Aligment>
          </Text>
        </ContentLoading>
      </Card>
    </ContentLoadingWrapper>
  );
};

export const GlobalLoadingPage: FC = () => {
  return (
    <GlobalLoaderWrapper>
      <WelcomeLogo src={logo} />
      <Loader size="medium" color="#ffffff" />
    </GlobalLoaderWrapper>
  );
};

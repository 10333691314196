import { FC } from "react";
import {
  MapModeContainer,
  MapModeIcon,
  ModeModeSection,
} from "./MapMode.styles";
import { Icon } from "venice-ui";
import { Colors } from "../../untils/Theme";
import {
  ADDITIONAL_TYPES,
  TAdditionalType,
} from "../../definitions/additional";

interface IMapMode {
  activeMode: string;
  setMode: (mode: TAdditionalType) => void;
  editMode: boolean;
  toogleEditMode: (editMode: boolean) => void;
}
export const MapMode: FC<IMapMode> = ({
  activeMode,
  setMode,
  editMode,
  toogleEditMode,
}) => {
  const modes = [
    {
      icon: "radio_checked",
      mode: ADDITIONAL_TYPES.dot,
    },
    {
      icon: "curve",
      mode: ADDITIONAL_TYPES.edge,
    },
    {
      icon: "triage",
      mode: ADDITIONAL_TYPES.triage,
    },
    {
      icon: "cloud",
      mode: ADDITIONAL_TYPES.cloud,
    },
  ];
  return (
    <MapModeContainer>
      <ModeModeSection>
        <MapModeIcon active={!editMode}>
          <Icon
            name="point_here"
            iconColor={!editMode ? Colors.white : Colors.appBlue}
            iconBgColor="transparent"
            onClick={() => toogleEditMode(false)}
            size={16}
            iconBgHoverColor="transparent"
          />
        </MapModeIcon>
        <MapModeIcon active={editMode}>
          <Icon
            name="edit"
            iconColor={editMode ? Colors.white : Colors.appBlue}
            iconBgColor="transparent"
            onClick={() => toogleEditMode(true)}
            size={16}
            iconBgHoverColor="transparent"
          />
        </MapModeIcon>
      </ModeModeSection>
      {editMode && (
        <ModeModeSection>
          {modes.map((item) => (
            <MapModeIcon active={item.mode === activeMode} key={item.icon}>
              <Icon
                name={item.icon}
                iconColor={
                  item.mode === activeMode ? Colors.white : Colors.appBlue
                }
                iconBgColor="transparent"
                onClick={() => setMode(item.mode as TAdditionalType)}
                size={14}
                iconBgHoverColor="transparent"
              />
            </MapModeIcon>
          ))}
        </ModeModeSection>
      )}
    </MapModeContainer>
  );
};

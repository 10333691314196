import styled from "styled-components";
import { Colors } from "../../untils/Theme";

export const NavigatorWrapper = styled.div`
  position: fixed;
  bottom: 3.4rem;
  right: 27.4rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
  z-index: 100;
`;
export const ZoomWrapper = styled.div`
  position: fixed;
  bottom: 3.4rem;
  right: 36.4rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  z-index: 100;
`;
export const NavigatorElement = styled.div`
  width: 8rem;
  height: 8rem;
  padding: 1rem;
  gap: 1rem;
  background-color: ${Colors.appBlue};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: wrap;
  transform: rotate(45deg);
  border-radius: 50%;
`;

export const NavigatorController = styled.div`
  height: calc(50% - 0.5rem);
  width: calc(50% - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :nth-child(1) {
    transform: rotate(225deg);
  }
  :nth-child(2) {
    transform: rotate(315deg);
  }
  :nth-child(3) {
    transform: rotate(315deg);
  }
  :nth-child(4) {
    transform: rotate(225deg);
  }
`;

export const ZoomArea = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
`;

import React, { FC, useState } from "react";
import { PublicContentArea, PublicWrapper } from "../publicComponents";
import logo from ".././../weldingApp_logo_white.svg";
import { Section } from "venice-ui";
import { LoginTile, WelcomeLogo } from "./Login.styles";
import { useParams } from "react-router-dom";
import { ResetPasswordForm } from "./ResetPasswordForm";

export const ResetPassword: FC = () => {
  const { pageMode, code } = useParams();

  return (
    <PublicWrapper>
      <PublicContentArea>
        <WelcomeLogo src={logo} />
        <LoginTile>
          <Section>
          <ResetPasswordForm/>
          </Section>
        </LoginTile>
      </PublicContentArea>
    </PublicWrapper>
  );
};


import { FC } from "react";
import { Aligment, Modal,Text } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";

interface IExportModal {
  handleCancel: () => void;
  handleConfirm: ()=> void;
  weldmap?:boolean;
  sectionLevel?:boolean;
  name:string
}

export const ExportModal: FC<IExportModal> = ({
  handleCancel,
  handleConfirm,
  name,
  weldmap = false,
  sectionLevel = false
}) => {
  return (
    <Modal
      title={t(dictionary.export)}
      labelClose={t(dictionary.cancel)}
      labelConfirm={t(dictionary.export)}
      handleClose={handleCancel}
      handleConfirm={handleConfirm}
    >
        <Aligment direction="row">
          {weldmap ? (
           <Text> {t(dictionary.exportWeldMap)} {t(dictionary.forSection)}: {name} </Text>
          ):(
           <Text> {t(dictionary.exportWeldLog)}  {sectionLevel ? t(dictionary.forSection) : t(dictionary.forProject)}: {name}</Text>)}
           
        </Aligment>
    </Modal>
  );
};

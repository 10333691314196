import React, { FC, useState } from "react";
import { Modal } from "venice-ui";
import { AddMember } from "../ContractDetails/AddMember";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";

interface IAddMemberToContract {
  contractID: string;
  handleCancel: () => void;
  handleConfirm: (contractID: string, memberID: string) => void;
}
export const AddMemberToContract: FC<IAddMemberToContract> = ({
  contractID,
  handleCancel,
  handleConfirm,
}) => {
  const [selectedMemberID, setSelectedMemberID] = useState("");
  const onConfirm = () => {
    handleConfirm(contractID, selectedMemberID);
  };
  return (
    <Modal
      title={t(dictionary.addMemeberToContractTitle)}
      labelClose={t(dictionary.cancel)}
      handleClose={handleCancel}
      handleConfirm={onConfirm}
      labelConfirm={t(dictionary.add)}
      submitDisabled={selectedMemberID === ""}
    >
      <AddMember onSelect={setSelectedMemberID} contractID={contractID} />
    </Modal>
  );
};

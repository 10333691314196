import React, { FC } from "react";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { Modal } from "venice-ui";

interface IUserLimitModal {
    handleClose: () => void;
}
export const UserLimitModal: FC<IUserLimitModal> = ({ handleClose }) => {
  return (
    <Modal
      title={t(dictionary.userLimitModal)}
      labelClose={t(dictionary.close)}
      handleClose={handleClose}
      size="small"
    >
      {t(dictionary.userLimiContent)}
    </Modal>
  );
};

export const memeberRoleScope = [
  { value: "admin", label: "admin" },
  { value: "user", label: "standard" },
];

export const roleScope= [
  { key: "admin", value: "admin" },
  { key: "user", value: "standard" },
]


import React, { FC } from "react";
import { Modal, Text } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { getContractName } from "../../helpers/projectHelper";
import { useStoreActions, useStoreState } from "../../store";
import { apiCall } from "../../untils/apiCall";

interface IDeleteContractModal {
  handleCancel: () => any;
  id: string;
}

export const DeleteContractModal: FC<IDeleteContractModal> = ({
  handleCancel,
  id,
}) => {
  const { id: orgID, contracts } = useStoreState((state) => state.organization);

  const { removeContract } = useStoreActions((actions) => actions.organization);
  const { removeContractFromUser } = useStoreActions((actions) => actions.user);

  const deleteContract = async (contractID: string) => {
    console.log("oooo");
    const options = {
      orgID: orgID,
      contractID: id,
    };
    await apiCall("organization/removeContract", options, (response: any) => {
      removeContract(id);
      removeContractFromUser(id)
      handleCancel();
    });
  };
  return (
    <Modal
      title={t(dictionary.confirmDelete)}
      labelClose={t(dictionary.cancel)}
      handleClose={handleCancel}
      handleConfirm={() => deleteContract(id)}
      labelConfirm={t(dictionary.delete)}
    >
      <Text>{getContractName(id, contracts)}</Text>
      <Text>{t(dictionary.doYouWantDeleteContract)}</Text>
    </Modal>
  );
};

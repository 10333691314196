import React, { FC, useState } from "react";
import { Modal } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { AddContract } from "../ContractDetails/AddContract";

interface IAddContractToMember {
  memberID: string;
  handleCancel: () => void;
  handleConfirm: (contractID: string, memberID: string) => void;
}
export const AddContractToMember: FC<IAddContractToMember> = ({
  memberID,
  handleCancel,
  handleConfirm,
}) => {
  const [selectedMemberID, setSelectedMemberID] = useState("");
  const onConfirm = () => {
    handleConfirm( selectedMemberID,memberID);
  };

  return (
    <Modal
      title={t(dictionary.addContractToMemberTitle)}
      labelClose={t(dictionary.cancel)}
      handleClose={handleCancel}
      handleConfirm={onConfirm}
      labelConfirm={t(dictionary.add)}
      submitDisabled={selectedMemberID === ""}
    >
      <AddContract onSelect={setSelectedMemberID} memberID={memberID} />
    </Modal>
  );
};

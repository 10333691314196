export const getLineLength = (target_x: number, target_y: number): number => {
  const a = target_x - 1;
  const b = target_y - 1;
  return parseFloat(Math.sqrt(a * a + b * b).toFixed(4));
};

export const getLineArc = (target_x: number, target_y: number): number => {
  const angleRadians = Math.atan2(target_x, target_y);
  const angleDegrees = -angleRadians * (180 / Math.PI);
  return parseFloat(angleDegrees.toFixed(4));
};

export const roundHeight = 5;

export const roundWidth = 5;

export const getLabelCordX = (x: number): number => {
  const diff = (roundWidth / 2) * 10;
  return x - diff;
};

export const getLabelCordY = (y: number): number => {
  const diff = (roundHeight / 2) * 10;
  return y - diff;
};

export const getPercentageValue = (value: number, total: number) => {
  return parseFloat(((value / total) * 100).toFixed(2));
};

import { FC } from "react";
import { Content } from "../components";
import { Card } from "venice-ui";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { EmptyState } from "../UI/EmptyState";
import { useNavigate } from "react-router-dom";

interface IEmptyContracts {
  isAdmin: boolean;
}
export const EmptyContracts: FC<IEmptyContracts> = ({ isAdmin }) => {
  const navigate = useNavigate();

  const goToContrats = () => {
    navigate(`/organization/contrats/add`);
  };
  return (
    <Content>
      <Card
        title={''}
        shadow={false}
        height="calc(100vh - 6rem)"
      >
        {isAdmin ? (
          <EmptyState
            title={t(dictionary.emptyStateStartPage)}
            actionLabel={t(dictionary.addContract)}
            action={() => goToContrats()}
          />
        ) : (
          <EmptyState title={t(dictionary.noContractsUserMode)} />
        )}
      </Card>
    </Content>
  );
};

import React, { FC, useEffect, useState } from "react";
import { Aligment, Icon, Section, Text, TextLabel } from "venice-ui";
import { useStoreActions, useStoreState } from "../../store";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { apiCall } from "../../untils/apiCall";
import { ProgressBar } from "../UI/ProgressBar/ProgressBar";
import { encryptNip, getLimits } from "../../helpers/organizationHelper";
import { getPercentageValue } from "../../helpers/mathHelpers";
import { LoadingContent } from "../layouts/Loading";
import { EditBillingModal } from "../Modals/EditBillingModal";
import { SettingRow } from "../Settings/SettingsRow";
import { EditOrgMailModal } from "../Modals/EditOrgMailModal";
import { ContentFull, Link, OrgDetailsLoader } from "../components";
import { Colors } from "../../untils/Theme";
import { InvoicesModal } from "../Modals";
import { useNavigate } from "react-router-dom";
import { CancelSubscriptioModal } from "../Modals/CancelSubscriptioModal";
import { PAYMENT_MODE } from "../../helpers/settingsHelper";

export const OrganizationDetails: FC = () => {
  const navigate = useNavigate();
  const { id, creationTime, members, usedStorage, planKey } = useStoreState(
    (state) => state.organization
  );
  const { orgPaymentID, orgSesionID, orgStatus } = useStoreState(
    (state) => state.user.userDetails
  );

  const [bilingModal, toogleBilingModal] = useState(false);
  const [emailModal, toogleEmailModal] = useState(false);
  const [invoicesModal, toogleInvoicesModal] = useState(false);
  const [cancelSubscriptionModal, toogleCancelSubscriptionModal] = useState({
    show: false,
    loader: false,
    confirm: false,
  });

  const { setOrgStatus } = useStoreActions((actions) => actions.user);
  const hideBillingModal = () => {
    toogleBilingModal(false);
  };
  const showBillingModal = () => {
    toogleBilingModal(true);
  };

  const hideEmailModal = () => {
    toogleEmailModal(false);
  };
  const showEmailModal = () => {
    toogleEmailModal(true);
  };
  const hideInvoiceModal = () => {
    toogleInvoicesModal(false);
  };
  const showInvoiceModal = () => {
    toogleInvoicesModal(true);
  };
  const hideCancelSubscriptionModal = () => {
    toogleCancelSubscriptionModal({
      loader: false,
      confirm: false,
      show: false,
    });
  };
  const showCancelSubscriptionModal = () => {
    toogleCancelSubscriptionModal({
      ...cancelSubscriptionModal,
      show: true,
      loader: false,
    });
  };
  const [pageData, setPageData] = useState({
    loading: true,
    sessionLink: "",
    editAddressLink: "",
    taxID: "",
    endOfsubscription: 0,
    invoiceEmail: "",
    address: {
      name: "",
      line1: "",
      line2: "",
      postal_code: "",
      city: "",
      state: "",
      country: "",
    },
    card: {
      type: "",
      number: "****",
      expire: "",
    },
  });

  const getCustomerPortal = async () => {
    const options = {
      sessionID: orgSesionID,
      userStripeID: orgPaymentID,
      orgID: id,
    };
    await apiCall("user/getUserSettings", options, (response: any) => {
      setPageData({
        loading: false,
        sessionLink: response.portalSession.url,
        editAddressLink: response.userDetails.editAddressUrl,
        taxID: response.userDetails.tax,
        endOfsubscription: response.userDetails.endOfsubscription,
        invoiceEmail: response.orgMail,
        address: {
          name: response.userDetails.name,
          line1: response.userDetails.address.line1 || "",
          line2: response.userDetails.address.line2 || "",
          postal_code: response.userDetails.address.postal_code || "",
          city: response.userDetails.address.city || "",
          state: response.userDetails.address.state || "",
          country: response.userDetails.address.country || "",
        },
        card: {
          type: response.card.brand,
          number: response.card.number,
          expire: response.card.expire,
        },
      });
    });
  };

  useEffect(() => {
    getCustomerPortal();
  }, []);

  const handleEmailSave = async (email: string) => {
    const options = {
      orgID: id,
      value: email,
      property: "invoiceEmail",
    };
    await apiCall("organization/updateOrgDetail", options, (response: any) => {
      setPageData({
        ...pageData,
        invoiceEmail: email,
      });
      hideEmailModal();
    });
  };

  const handleCancelSubscription = async () => {
    console.log("handleCancelSubscription");
    const options = {
      orgID: id,
      stripeCustomerId: orgPaymentID,
    };
    await apiCall("payments/unsubscribe", options, (response: any) => {
      toogleCancelSubscriptionModal({
        ...cancelSubscriptionModal,
        loader: false,
        confirm: true,
      });
    });
  };
  const handleConfirmCancelSubscription = () => {
    console.log("handleConfirmCancelSubscription");
    //redux axion
    setOrgStatus(PAYMENT_MODE.CANCEL);
    hideCancelSubscriptionModal();
  };

  const handleBillingDataSave = async (
    companyAdressLine1: string,
    companyAdressLine2: string,
    companyCity: string,
    companyCountry: string,
    companyName: string,
    companyNip: number,
    companyPostCode: string
  ) => {
    const options = {
      customerId: orgPaymentID,
      companyAdressLine1,
      companyAdressLine2,
      companyCity,
      companyCountry,
      companyName,
      companyNip,
      companyPostCode,
    };
    await apiCall("user/updateBillingDetails", options, (response: any) => {
      setPageData({
        ...pageData,
        taxID: companyCountry + companyNip,
        address: {
          name: companyName,
          line1: companyAdressLine1,
          line2: companyAdressLine2,
          postal_code: companyPostCode,
          city: companyCity,
          state: "",
          country: companyCountry,
        },
      });
      hideBillingModal();
    });
  };
  if (pageData.loading) {
    return (
      <Aligment direction="column">
        <OrgDetailsLoader>
          <ContentFull>
            <LoadingContent />
          </ContentFull>
        </OrgDetailsLoader>
      </Aligment>
    );
  }
  return (
    <Aligment direction="column" vPadding="10PX" align="flex-start">
      <Section noPadding={true} title={t(dictionary.organizationInfos)}>
        <Aligment direction="column" align="flex-start">
          <SettingRow title={t(dictionary.activeSince)}>
            <Text>
              {format(new Date(creationTime), "dd-MMM-yyyy", { locale: pl })}
            </Text>
          </SettingRow>
          {orgStatus === PAYMENT_MODE.CANCEL && (
            <SettingRow title={t(dictionary.activeTo)}>
              <Text>
                {format(new Date(pageData.endOfsubscription), "dd-MMM-yyyy", {
                  locale: pl,
                })}
              </Text>
            </SettingRow>
          )}
          <SettingRow title={t(dictionary.licenses)}>
            <Text>
              {members.length} / {getLimits(planKey).users}
            </Text>
          </SettingRow>
          <SettingRow title={t(dictionary.usageSpace)}>
            <Text>
              {parseFloat(usedStorage.toFixed(3))} MB /{" "}
              {getLimits(planKey).space} MB
            </Text>
            <ProgressBar
            width='25rem'
              value={getPercentageValue(
                parseFloat(usedStorage.toFixed(3)),
                getLimits(planKey).space
              )}
            />
          </SettingRow>
        </Aligment>
      </Section>
      <Section title={t(dictionary.paymentDetails)} noPadding={true}>
        <Aligment direction="column" align="flex-start">
          {orgStatus === PAYMENT_MODE.ACTIVE && (
            <SettingRow title={t(dictionary.nextPay)}>
              <Text>
                {format(new Date(pageData.endOfsubscription), "dd-MMM-yyyy", {
                  locale: pl,
                })}
              </Text>
            </SettingRow>
          )}
          <SettingRow>
            <Aligment gap={10} justify="flex-start">
              <TextLabel>{t(dictionary.paymentMethod)}</TextLabel>
              <Link href={pageData.editAddressLink} target="_blank">
                <Icon
                  name="edit"
                  size={16}
                  noPadding
                  iconBgColor="transparent"
                  iconBgHoverColor="transparent"
                />
              </Link>
            </Aligment>
            <Aligment direction="column" align="flex-start">
              <Text>
                {pageData.card.type} {pageData.card.number}
              </Text>
              <Text>
                {t(dictionary.validUntil)}: {pageData.card.expire}
              </Text>
            </Aligment>
          </SettingRow>
        </Aligment>
      </Section>
      <Section title={t(dictionary.invoicesAndSettlements)} noPadding={true}>
        <Aligment direction="column" align="flex-start">
          <SettingRow>
            <Aligment onClick={showInvoiceModal}>
              <Text color={Colors.seaBlue} action={true}>
                {t(dictionary.invoices)}
              </Text>
              <Icon name="list" size={16} iconBgHoverColor="transparent" />
            </Aligment>
          </SettingRow>
          <SettingRow
            title={t(dictionary.invoiceEmail)}
            onClick={showEmailModal}
          >
            <Text>{pageData.invoiceEmail}</Text>
          </SettingRow>
          <SettingRow
            title={t(dictionary.billingAddress)}
            onClick={showBillingModal}
          >
            <Text>{pageData.address.name}</Text>
            <Text>{pageData.address.line1}</Text>
            <Text>{pageData.address.line2}</Text>
            <Text>
              {pageData.address.postal_code} {pageData.address.city}{" "}
              {pageData.address.country}
            </Text>
            <Text>{pageData.taxID}</Text>
          </SettingRow>
          <Section title={t(dictionary.administration)} noPadding={true}>
            {orgStatus === PAYMENT_MODE.ACTIVE && (
              <Text
                onClick={showCancelSubscriptionModal}
                action
                color={Colors.red}
              >
                {t(dictionary.unsubscribe)}
              </Text>
            )}
          </Section>
        </Aligment>
      </Section>
      {bilingModal && (
        <EditBillingModal
          onClose={hideBillingModal}
          handleSave={handleBillingDataSave}
          companyName={pageData.address.name}
          companyAdressLine1={pageData.address.line1}
          companyAdressLine2={pageData.address.line2}
          companyPostCode={pageData.address.postal_code}
          companyCity={pageData.address.city}
          companyCountry={pageData.address.country}
          companyNip={encryptNip(pageData.taxID)}
        />
      )}
      {emailModal && (
        <EditOrgMailModal
          orgEmail={pageData.invoiceEmail}
          onClose={hideEmailModal}
          handleSave={handleEmailSave}
        />
      )}
      {invoicesModal && (
        <InvoicesModal show={invoicesModal} onClose={hideInvoiceModal} />
      )}
      {cancelSubscriptionModal.show && (
        <CancelSubscriptioModal
          handleCancel={hideCancelSubscriptionModal}
          handleAction={handleCancelSubscription}
          handleConfirm={handleConfirmCancelSubscription}
          loader={cancelSubscriptionModal.loader}
          confirm={cancelSubscriptionModal.confirm}
        />
      )}
    </Aligment>
  );
};

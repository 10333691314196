import axios from "axios";
import Cookies from "js-cookie";
import { apiURL, appURL } from "./const";

export const authCall = async (path: string, options: {}, cb: any) => {
  await axios
    .post(`${apiURL}/auth/${path}`, {
      ...options,
    })
    .then((response) => {
      cb(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const apiCall = async (path: string, options: any, cb: any) => {
  
  await axios
    .post(`${apiURL}/${path}`,
      options,
      {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Bearer " + Cookies.get("idToken"),
      },
    })
    .then((response) => {
      if(response.data.error){
        window.location.href = `${appURL}server-error`
      } else{
        cb(response.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};




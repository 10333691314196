import React, { FC } from "react";
import { Aligment, Icon } from "venice-ui";
import { IOrgMemberSimple } from "../../store/interface/organization";
import { ItemElement, ListWrapper } from "../components";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { DetailsText } from "../Organization/organizations.style";

interface IMemeberList {
  orgMembers: IOrgMemberSimple[];
  handleDelete: (memberID: string) => void;
}
export const MemeberList: FC<IMemeberList> = ({ orgMembers, handleDelete }) => {
  return (
    <Aligment>
      {orgMembers.length > 0 ? (
        <ListWrapper>
          {orgMembers.map((_member) => (
            <ItemElement className="item-list">
              {_member.email}
              <Icon
                name="remove_circle"
                iconBgHoverColor="transparent"
                size={18}
                onClick={() => handleDelete(_member.id)}
              />
            </ItemElement>
          ))}
        </ListWrapper>
      ) : (
        <Aligment style={{ height: "100%" }} >
          <DetailsText>
            {t(dictionary.noMembersInContract)}
          </DetailsText>
        </Aligment>
      )}
    </Aligment>
  );
};

import styled from "styled-components";
import { Colors } from "../untils/Theme";

export const PublicWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  font-size: 1.6rem;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  background-color: ${Colors.appBlue};
  //background: #0f0c29;  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const PublicContentArea = styled.div`
  padding: 10px;
  width: 100%;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GlobalLoaderWrapper = styled.div`
  width:100%;
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items: center;
    justify-content: center;
  background-color:${Colors.appBlue};
`


///to delete
export const PageContentArea = styled.div`
  min-height: 100vh;
  padding: 1rem;
  width: 100%;
  display: flex;
  font-size: 1.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

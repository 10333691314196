import { ListSectionProps } from "../../types/sectionTypes";

export const emptySection: ListSectionProps = {
  id: "",
  name: "",
  file: "",
  fileName: "",
  fileToken: "",
  status: "",
  projectID: "",
  createdTime: 0,
  updatedTime: 0,
  kks: "",
};

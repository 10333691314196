import { IJointFormType, IJointType } from "./joint";

export interface IItemType {
  id: string;
  name: string;
  sectionID?: string;
  page: number;
  x: number;
  y: number;
  label_x: number;
  label_y: number;
  type: string;
  lock: boolean;
  createdDate: number;
  sourceElementID?: string;
  marked?: boolean;
}

export const serializeElement = (element: IJointFormType): IElementType => {
  return {
    id: element.id,
    name: element.name,
    sectionID: element.sectionID,
    page: element.page,
    x: element.x,
    y: element.y,
    label_x: element.label_x,
    label_y: element.label_y,
    type: element.type,
    lock: element.lock,
    joint_type: element.joint_type,
    element_type: element.element_type,
    diameter_1: parseFloat(element.diameter_1),
    diameter_2: parseFloat(element.diameter_2),
    thickness_1: parseFloat(element.thickness_1),
    thickness_2: parseFloat(element.thickness_2),
    material_1: element.material_1,
    material_2: element.material_2,
    createdDate: element.createdDate,
    sourceElementID: element.sourceElementID,
    welder: element.welder,
    welding_date: element.welding_date,
    melt_number_1: element.melt_number_1,
    melt_number_2: element.melt_number_2,
    vt_examination_number: element.vt_examination_number,
    vt_examination_date: element.vt_examination_date,
    //ToDo 
    // vt_examination_result: element.vt_examination_result,
    vt_examination_result: '-',
    rt_examination_number: element.rt_examination_number,
    rt_examination_date: element.rt_examination_date,
    // rt_examination_result: element.rt_examination_result,
    rt_examination_result:'-',
    pt_examination_number: element.pt_examination_number,
    pt_examination_date: element.pt_examination_date,
    // pt_examination_result: element.pt_examination_result,
    pt_examination_result: '-',
    ut_examination_number: element.ut_examination_number,
    ut_examination_date: element.ut_examination_date,
    // ut_examination_result: element.ut_examination_result,
    ut_examination_result: '-',
    mt_examination_number: element.mt_examination_number,
    mt_examination_date: element.mt_examination_date,
    // mt_examination_result: element.mt_examination_result,
    mt_examination_result: '-',
  };
};

export type IElementType = IItemType | IJointType;
export type IElementFormType = IJointFormType;

export interface IExtendElementType extends IJointType {
  kks?: string;
}

export interface IReferenceElement {
  id: string;
  sectionID: string;
  page: number;
  sourceElementID: string;
  x: number;
  y: number;
  label_x: number;
  label_y: number;
  lock: boolean;
  createdDate: number;
}

export interface IOption {
  value: string | number
  label: string | number
}

export const resultScope = [
  {
    value: 'ok',
    label: 'ok'
  },
  {
    value: 'not_ok',
    label: 'not ok'
  },
  {
    value: '-',
    label: '-'
  }
]
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "venice-ui";
import { Colors } from "../../untils/Theme";
import {
  AdditionalElementsWrapper,
  Cloud,
  ElementControl,
} from "./AdditionalElements.styles";
import {
  calculateMapElementHeight,
  calculateMapElementWidth,
  calculateMapElementArc,
  checkPropInElement,
  getArc,
  getHeight,
  getWidth,
  getCordinates,
} from "./additionalHelper";
import { IAdditionalElements } from "./AdditionalElements";
import {
  ADDITIONAL_TYPES,
  IElementData,
  TAdditionalType,
} from "../../definitions/additional";

export const CloudElement: FC<IAdditionalElements> = ({
  element,
  scale,
  active,
  editMode,
  pdfHeight = 0,
  pdfWidth = 0,
  showDetails,
  saveChanges,
}) => {
  const pointRef = useRef<HTMLDivElement>(null);
  const nwRef = useRef<HTMLDivElement>(null);
  const neRef = useRef<HTMLDivElement>(null);
  const seRef = useRef<HTMLDivElement>(null);
  const swRef = useRef<HTMLDivElement>(null);

  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [pointPos, setPointPos] = useState({
    startLeft: 0,
    startTop: 0,
    endLeft: 0,
    endTop: 0,
  });

  const [elementData, setElementData] = useState<IElementData>({
    move: false,
    editMode: editMode,
    x: element.x,
    y: element.y,
    elementMode: element.type,
    pageHeight: pdfHeight,
    pageWidth: pdfWidth,
    rotate: getArc(element),
    width: getWidth(element),
    height: getHeight(element),
    size: (checkPropInElement(element, "size", "number") as number) || 0,
    end_x: (checkPropInElement(element, "end_x", "number") as number) || 0,
    end_y: (checkPropInElement(element, "end_y", "number") as number) || 0,
    mode: "element",
    scale: scale,
  });

  const updateElement = useCallback(() => {
    saveChanges(
      {
        ...element,
        x: elementData.x,
        y: elementData.y,
        end_x: elementData.end_x,
        end_y: elementData.end_y,
        size: elementData.size,
        rotation: elementData.rotate,
        height: elementData.height,
      },
      false
    );
  }, [element, elementData, saveChanges]);

  const startMove = useCallback(
    (event: MouseEvent, mode: string) => {
      event.stopPropagation();
      if (element.lock) return;

      showDetails(element.id!, true, false);
      setStartPos({ x: event.clientX, y: event.clientY });
      setPointPos({
        startLeft: elementData.x * elementData.scale,
        startTop: elementData.y * elementData.scale,
        endLeft: elementData.end_x * elementData.scale,
        endTop: elementData.end_y * elementData.scale,
      });
      setElementData((prev) => ({
        ...prev,
        size: element.size,
        move: true,
        mode: mode,
      }));
    },
    [element, elementData, showDetails]
  );

  const move = useCallback(
    (event: MouseEvent) => {
      if (!elementData.move || element.lock) return;
      event.stopPropagation();
      const newDelta = {
        left: event.clientX - startPos.x,
        top: event.clientY - startPos.y,
      };
      const change_x = (pointPos.startLeft + newDelta.left) / elementData.scale;
      const change_y = (pointPos.startTop + newDelta.top) / elementData.scale;
      const change_end_x =
        (pointPos.endLeft + newDelta.left) / elementData.scale;
      const change_end_y = (pointPos.endTop + newDelta.top) / elementData.scale;

      if (elementData.mode === "nwPoint") {
        setElementData((prev) => ({
          ...prev,
          x: change_x,
          y: change_y,
        }));
      } else if (elementData.mode === "nePoint") {
        setElementData((prev) => ({
          ...prev,
          end_x: change_end_x,
          y: change_y,
        }));
      } else if (elementData.mode === "sePoint") {
        setElementData((prev) => ({
          ...prev,
          end_x: change_end_x,
          end_y: change_end_y,
        }));
      } else if (elementData.mode === "swPoint") {
        setElementData((prev) => ({
          ...prev,
          x: change_x,
          end_y: change_end_y,
        }));
      } else {
        setElementData((prev) => ({
          ...prev,
          x: (pointPos.startLeft + newDelta.left) / elementData.scale,
          y: (pointPos.startTop + newDelta.top) / elementData.scale,
          end_x: (pointPos.endLeft + newDelta.left) / elementData.scale,
          end_y: (pointPos.endTop + newDelta.top) / elementData.scale,
        }));
      }
    },
    [elementData.move, element.lock, element.height, startPos, pointPos]
  );

  const endMove = useCallback(
    (event: MouseEvent) => {
      if (!elementData.move) return;
      event.stopPropagation();
      setElementData((prev) => ({
        ...prev,
        move: false,
        mode: "",
      }));

      updateElement();
    },
    [elementData.move, updateElement]
  );

  const handlePointDown = useCallback(
    (e: MouseEvent) => startMove(e, "element"),
    [startMove]
  );
  const handleNWDown = useCallback(
    (e: MouseEvent) => startMove(e, "nwPoint"),
    [startMove]
  );
  const handleNEDown = useCallback(
    (e: MouseEvent) => startMove(e, "nePoint"),
    [startMove]
  );
  const handleSEDown = useCallback(
    (e: MouseEvent) => startMove(e, "sePoint"),
    [startMove]
  );
  const handleSWDown = useCallback(
    (e: MouseEvent) => startMove(e, "swPoint"),
    [startMove]
  );

  useEffect(() => {
    const point = pointRef.current;
    const nwPoint = nwRef.current;
    const nePoint = neRef.current;
    const sePoint = seRef.current;
    const swPoint = swRef.current;
    if (point) {
      point.addEventListener("mousedown", handlePointDown);
    }
    if (nwPoint) {
      nwPoint.addEventListener("mousedown", handleNWDown);
    }
    if (nePoint) {
      nePoint.addEventListener("mousedown", handleNEDown);
    }
    if (sePoint) {
      sePoint.addEventListener("mousedown", handleSEDown);
    }
    if (swPoint) {
      swPoint.addEventListener("mousedown", handleSWDown);
    }
    window.addEventListener("mousemove", move, { passive: true });
    window.addEventListener("mouseup", endMove);
    window.addEventListener("mouseleave", endMove);
    return () => {
      if (point) {
        point.removeEventListener("mousedown", handlePointDown);
      }
      if (nwPoint) {
        nwPoint.removeEventListener("mousedown", handleNWDown);
      }
      if (nePoint) {
        nePoint.removeEventListener("mousedown", handleNEDown);
      }
      if (sePoint) {
        sePoint.removeEventListener("mousedown", handleSEDown);
      }
      if (swPoint) {
        swPoint.removeEventListener("mousedown", handleSWDown);
      }
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", endMove);
      window.removeEventListener("mouseleave", endMove);
    };
  }, [startMove, move, endMove]);

  useEffect(() => {
    setElementData((prev) => ({
      ...prev,
      x: element.x,
      y: element.y,
      end_x: element.end_x,
      end_y: element.end_y,
      width: getWidth(element),
      height: getHeight(element),
      pageHeight: pdfHeight,
      pageWidth: pdfWidth,
      editMode: editMode,
      rotate: element.rotation,
      scale: scale,
    }));
  }, [element, pdfHeight, pdfWidth, scale, editMode]);

  return (
    <AdditionalElementsWrapper
      isActive={false}
      isEditMode={elementData.editMode}
      origin={element.type === "edge"}
      style={{
        left:
          getCordinates(
            elementData.x * elementData.scale,
            elementData.width * elementData.scale,
            ADDITIONAL_TYPES.cloud as TAdditionalType
          ) + "px",
        top:
          getCordinates(
            elementData.y * elementData.scale,
            elementData.height * elementData.scale,
            ADDITIONAL_TYPES.cloud as TAdditionalType
          ) + "px",
        height: `${
          calculateMapElementHeight(elementData) * elementData.scale
        }px`,
        width: `${calculateMapElementWidth(elementData) * elementData.scale}px`,
        transform: `rotate(${calculateMapElementArc(elementData)}deg)`,
      }}
    >
      <>
        <Cloud isActive={active} factor={scale} ref={pointRef} />
        {active && (
          <>
            <ElementControl
              ref={nwRef}
              style={{
                left: "-16px",
                top: "-16px",
              }}
            >
              <Icon
                name="move_nw"
                iconBgHoverColor="tranparent"
                iconColor={Colors.seaBlue}
                size={16}
                noPadding={true}
              />
            </ElementControl>
            <ElementControl
              ref={neRef}
              style={{
                right: "-16px",
                top: "-16px",
              }}
            >
              <Icon
                name="move_ne"
                iconBgHoverColor="tranparent"
                iconColor={Colors.seaBlue}
                size={16}
                noPadding={true}
              />
            </ElementControl>
            <ElementControl
              ref={seRef}
              style={{
                right: "-16px",
                bottom: "-16px",
              }}
            >
              <Icon
                name="move_se"
                iconBgHoverColor="tranparent"
                iconColor={Colors.seaBlue}
                size={16}
                noPadding={true}
              />
            </ElementControl>
            <ElementControl
              ref={swRef}
              style={{
                left: "-16px",
                bottom: "-16px",
              }}
            >
              <Icon
                name="move_sw"
                iconBgHoverColor="tranparent"
                iconColor={Colors.seaBlue}
                size={16}
                noPadding={true}
              />
            </ElementControl>
          </>
        )}
      </>
    </AdditionalElementsWrapper>
  );
};

import React, { FC, ReactText, useState } from "react";
import { Aligment, Button, Input, TextSmall } from "venice-ui";
import {
  ErrorArea,
  IntputWrapperBig,
  LoginActionLink,
  LoginFooterSection,
  LoginFormSection,
  LoginSubtitle,
  LoginTite,
} from "./Login.styles";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import {
  errorMsg,
  LOGIN_MODE,
  TLoginMode,
} from "../../helpers/loginHelper";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { appURL } from "../../untils/const";
import { emailFormatCheck } from "../PasswordCheck/checks";

interface IForgotFormProps {
  changeMode: (mode: TLoginMode) => void;
}

export const ForgotForm: FC<IForgotFormProps> = ({ changeMode }) => {
  const [sectionData, updateSectionData] = useState({
    email: "",
    loading: false,
    error: false,
    errorCode: "",
    send: false,
  });
  const setLoginValue = (field: string, value: string) => {
    updateSectionData({
      ...sectionData,
      [field]: value,
    });
  };
  const auth = getAuth();

  const forgotAction = async () => {
    if (sectionData.email.length > 0 && !sectionData.loading) {
      if (emailFormatCheck(sectionData.email)) {
        updateSectionData({
          ...sectionData,
          error: false,
          errorCode: "",
          loading: true,
          send: false,
        });
        try {
          const actionCodeSettings = {
            url: `${appURL}resetpassword`, // Strona, na którą zostanie przekierowany użytkownik po zresetowaniu hasła
            handleCodeInApp: false,
          };
          await sendPasswordResetEmail(
            auth,
            sectionData.email,
            actionCodeSettings
          )
            .then(() => {
              console.log("E-mail z resetem hasła został wysłany");
            })
            .catch((error) => {
              console.error("Wystąpił błąd:", error);
            });
          updateSectionData({
            ...sectionData,
            loading: false,
            send: true,
          });
        } catch (error) {
          console.log(error);
          updateSectionData({
            ...sectionData,
            error: false,
            errorCode: "",
            loading: false,
            send: true,
          });
        }
      } else {
        updateSectionData({
          ...sectionData,
          error: true,
          errorCode: "wrong-email",
          loading: false,
        });
      }
    }
  };
  return (
    <Aligment justify="center" direction="column">
      {!sectionData.send && (
        <>
          <LoginTite>{t(dictionary.forgetTitle)}</LoginTite>
          <LoginSubtitle>{t(dictionary.forgotSubtitle)}</LoginSubtitle>
          <LoginFormSection>
            <IntputWrapperBig>
              <Input
                label={t(dictionary.inputLabelEmail)}
                type="text"
                labelPosition="top"
                value={sectionData.email}
                name="email"
                handleChange={(name: string, value: ReactText) =>
                  setLoginValue(name, value as string)
                }
                handleSubmit={forgotAction}
                autoFocus={true}
              />
            </IntputWrapperBig>
          </LoginFormSection>
          <>
            {sectionData.error && (
              <LoginFormSection>
                <ErrorArea>{errorMsg(sectionData.errorCode)}</ErrorArea>
              </LoginFormSection>
            )}
            <Button
              text={t(dictionary.actionReset)}
              onClick={forgotAction}
              loader={sectionData.loading}
            />
          </>
          <LoginFooterSection>
            <TextSmall>
              {t(dictionary.actionRemember)}
              <LoginActionLink onClick={() => changeMode(LOGIN_MODE.login)}>
                {t(dictionary.loginAccount)}
              </LoginActionLink>
            </TextSmall>
          </LoginFooterSection>
        </>
      )}
      {sectionData.send && (
        <>
          <LoginTite>{t(dictionary.emailSend)}</LoginTite>
          <LoginSubtitle>{t(dictionary.sendSubtitle)}</LoginSubtitle>
          <LoginFooterSection>
            <TextSmall>
              <LoginActionLink onClick={() => changeMode(LOGIN_MODE.login)}>
                {t(dictionary.backToHomePage)}
              </LoginActionLink>
            </TextSmall>
          </LoginFooterSection>
        </>
      )}
    </Aligment>
  );
};

import styled from "styled-components";
import { Colors } from "../../../untils/Theme";

interface IProgressBarWrapperProps{
  width:string
}
export const ProgressBarWrapper = styled.div<IProgressBarWrapperProps>`
  width: ${p=>p.width};
  height: 1rem;
  background-color: ${Colors.appBlueAccent};
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  margin-top: 0.4rem;
`;

interface IProgressProps {
  value: number;
}
export const Progress = styled.div<IProgressProps>`
  width: ${(p) => p.value}%;
  display: flex;
  height: 1rem;
  background-color: ${Colors.appBlue};
  ${(p) =>
    p.value > 85 &&
    `
    background-color: ${Colors.warrningOrange};
    `}
  ${(p) =>
    p.value > 97 &&
    `
      background-color: ${Colors.red};
      `}
`;


export const ModalProgresWrapper = styled.div`
    display:flex;
    gap:0.4rem;
    flex-direction: column;
    margin-top: 0.4rem;
`
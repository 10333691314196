import React, { FC } from "react";
import { IPracingProps } from "../register.types";
import { LoginFormSection, LoginSubtitle } from "../Login.styles";
import { dictionary } from "../../../untils/dictionary";
import { t } from "../../../helpers/userHelper";
import { Aligment } from "venice-ui";
import { PlanBox } from "../../PlanBox/PlanBox";

interface IRegisterPlan {
  plans: IPracingProps[];
  activePlan: string;
  handleSelect: (planID: string, planName: string) => void;
}
export const RegisterPlan: FC<IRegisterPlan> = ({
  plans,
  activePlan,
  handleSelect,
}) => {
  return (
    <>
      <LoginSubtitle> {t(dictionary.planSubtitle)}</LoginSubtitle>
      <LoginFormSection>
        <Aligment gap={10}>
          {plans.map((plan) => {
            return (
              <PlanBox
                plan={plan}
                key={plan.id}
                activePlan={activePlan}
                handleClick={handleSelect}
              />
            );
          })}
        </Aligment>
      </LoginFormSection>
    </>
  );
};

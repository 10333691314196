import styled from "styled-components";
import { Colors } from "../../untils/Theme";

export const MapModeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
`;

interface IMapModeIconProps {
  active: boolean;
}

export const ModeModeSection = styled.div`
  z-index: 100;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const MapModeIcon = styled.div<IMapModeIconProps>`
  width: 2.4rem;
  height: 2.4rem;
  background-color: ${(p) =>
    p.active ? Colors.appBlue : Colors.appBlueAccent};
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const ZoomIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-color: ${Colors.appBlueAccent};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React, { FC } from "react";
import { Modal, Text } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";

interface IDeleteSectionModalProps {
  handleCancel: () => any;
  handleDelete: (id: string) => void;
  id: string;
  name: string;
}

export const DeleteSectionModal: FC<IDeleteSectionModalProps> = ({
  handleCancel,
  handleDelete,
  name,
  id,
}) => {
  
  
  return (
    <Modal
      title={t(dictionary.confirmDelete)}
      labelClose={t(dictionary.cancel)}
      handleClose={handleCancel}
      handleConfirm={() => handleDelete(id)}
      labelConfirm={t(dictionary.delete)}
    >
      <Text>{name}</Text>
      <Text>{t(dictionary.doYouWantDeleteSection)}</Text>
    </Modal>
  );
};

import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Aligment, Dropdown, Icon, Selector, Toogle } from "venice-ui";
import { IElementType } from "../../definitions/element";
import { IOptions } from "../../types/sectionTypes";
import { Tabs } from "../UI/Tabs";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { Colors } from "../../untils/Theme";
import { MapMode } from "../MapMode/MapMode";
import { TAdditionalType } from "../../definitions/additional";
import { ZoomIcon } from "../MapMode/MapMode.styles";
import { zoomScale } from "./readerHelper";
import {
  SectionHeaderWrapper,
  ZoomScale,
  ZoomSection,
} from "./SectionHeader.style";
import { useStoreState } from "../../store";

const HorizontalSpacer = styled.div`
  width: 1rem;
  display: flex;
`;
const SectionWrapper = styled.div`
  width: 8rem;
  display: flex;
`;
interface ISectionHeaderProps {
  activeTab: string;
  setActiveTab: (value: string) => void;
  activePage: number;
  pageCount: number;
  referal?: string;
  setReferal: (value: string) => void;
  resetSelection: () => void;
  drawMode: TAdditionalType;
  setMode: (drawMode: TAdditionalType) => void;
  editMode: boolean;
  toogleEditMode: (editMode: boolean) => void;
  scale: number;
  updateScale: (direction: string) => void;
  showJointWeld:boolean;
  toogleShowJointWeld: (mode:boolean) => void
}
export const SectionHeader: FC<ISectionHeaderProps> = ({
  activeTab = "map",
  setActiveTab,
  activePage,
  pageCount,
  referal,
  setReferal,
  resetSelection,
  drawMode,
  setMode,
  editMode,
  toogleEditMode,
  scale,
  updateScale,
  showJointWeld,
  toogleShowJointWeld
}) => {
  const navigate = useNavigate();
  const { sectionID, projectID } = useParams();
  const {elements} = useStoreState((state)=> state.projectDetails.projectDetails)

  const menuTabs = [
    {
      value: "map",
      label: t(dictionary.map),
    },
    {
      value: "list",
      label: t(dictionary.list),
    },
  ];
  const getPages = (count: number): IOptions[] => {
    const options = [];
    for (let i = 0; i < count; i++) {
      let obj = {
        label: (i + 1).toString(),
        value: i + 1,
      };
      options.push(obj);
    }
    return options;
  };

  const getPointScope = (): IOptions[] => {
    let pointsScope: IOptions[] = [];
    elements.forEach((element) => {
      let obj = {
        label: element.name,
        value: element.id,
      };
      pointsScope.push(obj);
    });
    return pointsScope;
  };

  const setPage = (name: string, value: number | string) => {
    resetSelection();
    navigate(`/project/${projectID}/${sectionID}/${value}`);
  };

  const setPoint = (name: string, value: number | string | undefined): void => {
    setReferal(value as string);
  };
  const isAvailableZoomOut = () => {
    let scaleIndex = zoomScale.findIndex((item) => item === scale);
    return scaleIndex > 0;
  };

  const isAvailableZoomIn = () => {
    let scaleIndex = zoomScale.findIndex((item) => item === scale);
    return scaleIndex < zoomScale.length - 1;
  };
  const formatScale = (scale: number): string => {
    const textScale = (scale * 100).toString();
    const splited = textScale.split(".");

    if (splited.length === 1) {
      return textScale;
    } else {
      return (scale * 100).toFixed(2);
    }
  };

  return (
    <SectionHeaderWrapper>
      <Aligment justify="start-flex" width={"auto"} gap={16}>
        <Tabs tabs={menuTabs} activeValue={activeTab} onClick={setActiveTab} />
        {activeTab === "list" &&
        <Toogle handleClick={()=>toogleShowJointWeld(!showJointWeld)} value={showJointWeld} size={'small'} label="Pokaz spoiny łączące" labelPosition={'left'}/>
        }
        {activeTab === "map" && (
          <>
            <ZoomSection>
              <ZoomScale>{formatScale(scale)}%</ZoomScale>
              <ZoomIcon>
                <Icon
                  name="zoom_in"
                  iconColor={Colors.appBlue}
                  iconHoverColor={Colors.seaBlue}
                  onClick={() => updateScale("up")}
                  size={16}
                  isDisabled={!isAvailableZoomIn()}
                  iconBgHoverColor="transparent"
                  iconBgColor="transparent"
                />
              </ZoomIcon>
              <ZoomIcon>
                <Icon
                  name="zoom_out"
                  iconColor={Colors.appBlue}
                  iconHoverColor={Colors.seaBlue}
                  size={16}
                  onClick={() => updateScale("down")}
                  isDisabled={!isAvailableZoomOut()}
                  iconBgHoverColor="transparent"
                />
              </ZoomIcon>
              <ZoomIcon>
                <Icon
                  name="fit_to_screen"
                  iconColor={Colors.appBlue}
                  iconHoverColor={Colors.seaBlue}
                  size={16}
                  onClick={() => updateScale("fit")}
                  iconBgHoverColor="transparent"
                />
              </ZoomIcon>
              <ZoomIcon>
                <Icon
                  name="orginal_size"
                  iconColor={Colors.appBlue}
                  iconHoverColor={Colors.seaBlue}
                  size={16}
                  onClick={() => updateScale("reset")}
                  iconBgHoverColor="transparent"
                />
              </ZoomIcon>
            </ZoomSection>
            <MapMode
              setMode={setMode}
              activeMode={drawMode}
              editMode={editMode}
              toogleEditMode={toogleEditMode}
            />
          </>
        )}
      </Aligment>
      {activeTab === "map" && (
        <>
          <Aligment width="auto" wrap="nowrap">
            <Selector
              name="referal"
              label={t(dictionary.reference)}
              labelPosition="left"
              size="small"
              value={referal}
              width="auto"
              options={getPointScope()}
              handleSelect={setPoint}
              handleClear={() => setReferal("")}
              optionsPanelTitle={t(dictionary.referencePanelTitle)}
              optionsPanelEmptyMsg={t(dictionary.referencePanelEmpty)}
            />
            <HorizontalSpacer />
            <SectionWrapper>
              <Dropdown
                label={t(dictionary.page)}
                labelPosition="left"
                size="small"
                value={activePage}
                width="auto"
                options={getPages(pageCount)}
                handleSelect={setPage}
                name="pageSelector"
              />
            </SectionWrapper>
          </Aligment>
        </>
      )}
    </SectionHeaderWrapper>
  );
};

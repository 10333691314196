import React, { FC, useState } from "react";
import { Modal, Form, TextSmall } from "venice-ui";
import { getPercentageValue } from "../../helpers/mathHelpers";
import { getLimits } from "../../helpers/organizationHelper";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { ProgressBar } from "../UI/ProgressBar/ProgressBar";
import { ModalProgresWrapper } from "../UI/ProgressBar/progressBar.styles";
import { ListSectionProps } from "../../types/sectionTypes";
import { apiCall } from "../../untils/apiCall";
import { useStoreState } from "../../store";

interface NewSectionProps {
  name: string;
  file: File | undefined;
  kks: string;
  fileSize: number;
}

interface IAddSectionModal {
  handleClose: () => void;
  handleAddSection: (newSection: ListSectionProps) => void;
  storage: number;
  planKey: string;
  projectID: string;
}

export const AddSectionModal: FC<IAddSectionModal> = ({
  handleClose,
  handleAddSection,
  storage,
  planKey,
  projectID,
}) => {
  const { orgID } = useStoreState((state) => state.user.userDetails);
  const [modalLoader, toggleModalLoader] = useState(false);
  const [section, setSection] = useState<NewSectionProps>({
    name: "",
    file: undefined,
    kks: "",
    fileSize: 0,
  });

  const clearState = () => {
    setSection({
      name: "",
      file: undefined,
      kks: "",
      fileSize: 0,
    });
  };

  const onClose = () => {
    clearState();
    handleClose();
  };
  const updateForm = (field: string, value: string | number | File) => {
    if (field === "file") {
      const fileValue = value as File;
      const kks = value ? fileValue.name.split(".pdf")[0] : "";
      const fileSize = fileValue.size / (1024 * 1024);

      setSection({
        ...section,
        file: value as File,
        kks: kks,
        fileSize: fileSize,
      });
    } else {
      setSection({ ...section, [field]: value });
    }
  };

  const addSectionFile = async () => {
    if (!modalLoader) {
      toggleModalLoader(true);
      let formData = new FormData();
      formData.append("file", section!.file!);
      formData.append("sectionName", section!.name!);
      formData.append("kks", section!.kks!);
      formData.append("orgID", orgID);
      formData.append("projectID", projectID!);
      formData.append("updatedTime", Date.now().toString());
      formData.append("createdTime", Date.now().toString());

      await apiCall("projects/setNewSection", formData, (response: any) => {
        toggleModalLoader(false);
        clearState();
        handleAddSection(response.newSection);
      });
    }
  };

  const validationNewSection = () => {
    const sectionName = section.name as string;
    const availableSize = getLimits(planKey).space;
    const currentSize =
      parseFloat(storage.toFixed(3)) + parseFloat(section.fileSize.toFixed(3));
    return (
      sectionName.trim().length === 0 ||
      section.file === undefined ||
      currentSize > availableSize
    );
  };

  const newSectionForm = {
    action: updateForm,
    data: [
      {
        type: "text",
        label: t(dictionary.sectionName),
        value: section?.name,
        name: "name",
        autofocus: true,
      },
      {
        type: "file",
        label: t(dictionary.pdfFile),
        value: section?.file,
        name: "file",
      },
      {
        type: "text",
        label: t(dictionary.kks),
        value: section?.kks,
        name: "kks",
      },
    ],
  };

  return (
    <Modal
      title={t(dictionary.newSection)}
      handleClose={onClose}
      handleConfirm={() => addSectionFile()}
      labelClose={t(dictionary.cancel)}
      labelConfirm={t(dictionary.createSection)}
      submitDisabled={validationNewSection()}
      submitLoader={modalLoader}
    >
      <Form formData={newSectionForm} />
      <ModalProgresWrapper>
        <TextSmall>
          {t(dictionary.usageSpace)} (
          {parseFloat(storage.toFixed(3)) +
            parseFloat(section.fileSize.toFixed(3))}{" "}
          MB / {getLimits(planKey).space} MB )
        </TextSmall>
        <ProgressBar
          value={getPercentageValue(
            parseFloat(storage.toFixed(3)) +
              parseFloat(section.fileSize.toFixed(3)),
            getLimits(planKey).space
          )}
        />
      </ModalProgresWrapper>
    </Modal>
  );
};

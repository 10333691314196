import React, { FC } from "react";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { Aligment, Table,Text } from "venice-ui";
import { useStoreState } from "../../store";
import { getUnassignContracts } from "../../helpers/organizationHelper";

interface IAddContract {
  memberID: string;
  onSelect: (id: string) => void;
}
export const AddContract: FC<IAddContract> = ({ onSelect, memberID }) => {
  const { contracts } = useStoreState((state) => state.organization);

  const handleSelect = (id: string) => {
    console.log(id)
    onSelect(id);
  };
  const tableHeaders = [
    {
      name: t(dictionary.contractNameTable),
      valueSource: "name",
    },
  ];
  const contractsScope = getUnassignContracts(memberID, contracts);
  return (
    <>
      {contractsScope.length > 0 ? (
        <Table
          headers={tableHeaders}
          elements={contractsScope}
          selectable
          onRowClick={handleSelect}
        />
      ) : (
        <Aligment vPadding="16px">
            <Text>{t(dictionary.noAvailableContract)}</Text>
        </Aligment>
      )}
    </>
  );
};

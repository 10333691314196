import React, { FC, useEffect, useState } from "react";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";
import { Form, Modal } from "venice-ui";
import { memeberRoleScope } from "../../definitions/organizations";
import { apiCall } from "../../untils/apiCall";
import { user } from "../../store/state/user";
import { useStoreActions, useStoreState } from "../../store";
import { getMemberDetails } from "../../helpers/organizationHelper";

interface IUserModal {
  handleClose: () => void;
  memberID?: string;
}
export const UserModal: FC<IUserModal> = ({ handleClose, memberID }) => {
  const { orgID } = useStoreState((state) => state.user.userDetails);
  const { members } = useStoreState((state) => state.organization);

  const [loadAction, setLoadAction] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    role: "user",
  });

  const { saveMemberRole, addInvitation } = useStoreActions(
    (actions) => actions.organization
  );

  useEffect(() => {
    if (memberID) {
      const details = getMemberDetails(members, memberID);
      setUserDetails({
        email: details.email,
        role: details.role,
      });
    }
  }, [memberID]);

  const updateForm = (field: string, value: string) => {
    setUserDetails({
      ...userDetails,
      [field]: value,
    });
  };
  const sendInvatation = async () => {
    setLoadAction(true);
    const options = {
      email: userDetails.email,
      role: userDetails.role,
      orgID: orgID,
      date: Date.now(),
    };
    await apiCall("organization/sendInvitation", options, (response: any) => {
      addInvitation({
        id: response.id,
        orgID: orgID,
        date: options.date,
        role: userDetails.role,
        user: userDetails.email,
      });
      setLoadAction(false);
      handleClose();
    });
  };

  const saveMember = async () => {
    const options = {
      memberID: memberID,
      role: userDetails.role,
      orgID: orgID,
    };
    await apiCall(
      "organization/saveMemberDetails",
      options,
      (response: any) => {
        saveMemberRole({ memberID: memberID || "", role: userDetails.role });
        handleClose();
      }
    );
  };

  const submitAction = () => {
    if (memberID) {
      saveMember();
    } else {
      sendInvatation();
    }
  };
  const userForm = {
    action: updateForm,
    data: [
      {
        type: "text",
        label: t(dictionary.email),
        value: userDetails?.email,
        name: "email",
        autofocus: true,
        submit: () => submitAction(),
        isDisabled: !!memberID,
      },
      {
        type: "select",
        label: t(dictionary.role),
        value: userDetails.role,
        options: memeberRoleScope,
        zIndex: 1000,
        position: "left",
        name: "role",
      },
    ],
  };
  return (
    <Modal
      title={memberID ? t(dictionary.editUser) : t(dictionary.addUser)}
      labelClose={t(dictionary.close)}
      labelConfirm={t(dictionary.add)}
      handleClose={handleClose}
      handleConfirm={submitAction}
      submitDisabled={userDetails.email.length === 0}
      size="small"
      submitLoader={loadAction}
    >
      <Form formData={userForm} />
    </Modal>
  );
};

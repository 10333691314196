import React, { FC, useEffect, useState } from "react";
import { Content } from "../../components/components";
import { Aligment, Card } from "venice-ui";
import { Tabs } from "../../components/UI/Tabs";
import { useStoreActions, useStoreState } from "../../store";
import { apiCall } from "../../untils/apiCall";
import { UsersList } from "../../components/AdminPanel/UsersList";
import { OrganizationsList } from "../../components/AdminPanel/OrganizationsList";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";

export const AdminPanel: FC = () => {
  const { uid } = useStoreState((state) => state.user.userDetails);

  const { setData, addOrganizations } = useStoreActions(
    (actions) => actions.panel
  );

  const [activeTab, setActiveTab] = useState("orgs");
  const menuTabs = [
    {
      value: "orgs",
      label: t(dictionary.organizations),
    },
    {
      value: "users",
      label: t(dictionary.users),
    },
  ];


  const getAdminDetails = async () => {
    const options = {
      userID: uid,
    };
    await apiCall("admin/getAdminDetails", options, (response: any) => {
      setData({
        organizations: response.organizations,
        users: response.users,
      });
    });
  };
  useEffect(() => {
    getAdminDetails();
  }, []);


  return (
    <Content>
      <Card
        title={"Admin Panel"}
        shadow={false}
      >
        <Aligment direction="column" align="flex-start">
          <Tabs
            tabs={menuTabs}
            activeValue={activeTab}
            onClick={setActiveTab}
          />
          {activeTab === "orgs" && <OrganizationsList />}
          {activeTab === "users" && <UsersList />}
        </Aligment>
      </Card>
    </Content>
  );
};

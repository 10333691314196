import { action } from "easy-peasy";
import { IProjects } from "../interface/projects";

export const projects: IProjects = {
    projects:[],

    setProjects: action((state, payload) => {
        state.projects = payload
    }),
    
    addProject: action((state, payload) => {
        const newProjects = [...state.projects,payload]
        state.projects = newProjects
    }),

    updateProject: action((state, payload) => {
        const newProjects = [...state.projects]
        const finded = newProjects.find(item => item.id === payload.id)
        if(finded){
            finded.name = payload.name
            finded.contract = payload.contract
            finded.contractLabel = payload.contractLabel
        }

    }),
    deleteProject: action((state, payload) => {
        const newProjects = state.projects.filter(item=> item.id !== payload)
        state.projects = newProjects
    }),
    clearProjects: action(state => {
        state.projects = []
    }),
}
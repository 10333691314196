export const dictionary = {
  loginTitle: {
    pl: "Logowanie",
    en: "Log in",
  },
  registerTitle: {
    pl: "Rejestracja",
    en: "Sing up",
  },
  forgetTitle: {
    pl: "Przypomnienie hasła",
    en: "Password reminder",
  },
  emailSend: {
    pl: "Wiadomość wysłana",
    en: "Message sent",
  },
  resetTitle: {
    pl: "Ustaw nowe hasło",
    en: "Set new password",
  },
  afterResetTitle: {
    pl: "Gotowe",
    en: "Done",
  },
  sendSubtitle: {
    pl: "Jeśli istnieje konto przypisane do tego adresu email to wysłaliśmy tam dalsze instrukcje",
    en: "If there is an account assigned to this email address, we have sent further instructions there",
  },

  loginSubtitle: {
    pl: "Witaj z powrotem, zaloguj się na swoje konto",
    en: "Welcome back, log in to your account",
  },
  afterResetSubtitle: {
    pl: "Hasło zostało pomyślnie zmienione, możesz zalogować się do aplikacji",
    en: "The password has been successfully changed, you can log in to the application",
  },
  forgotSubtitle: {
    pl: "Podaj swój email i podążaj za instrukcjami",
    en: "Enter your email and follow the instructions",
  },
  registerSubtitle: {
    pl: "Utwórz konto aby korzystać z aplikacji",
    en: "Create an account to use the application",
  },
  passwordSubtitle: {
    pl: "Utwórz hasło dla swojego konta.",
    en: "Create a password for your account.",
  },
  invoiceData: {
    pl: "Dane do faktury.",
    en: "Invoice details.",
  },
  planSubtitle: {
    pl: "Wybierz plan.",
    en: "Select a plan.",
  },
  inputLabelEmail: {
    pl: "Email",
    en: "E-mail",
  },
  inputLabelOrganization: {
    pl: "Nazwa organizacji",
    en: "Organization name",
  },
  inputLabelPassword: {
    pl: "Hasło",
    en: "Password",
  },
  inputLabelRePassword: {
    pl: "Powtórz hasło",
    en: "Repeat password",
  },
  inputVerificationCode: {
    pl: "Kod weryfikujący",
    en: "Verification code",
  },

  errorWrongLoginOrPassword: {
    pl: "Błędny login lub hasło",
    en: "Incorrect login or password",
  },
  actionLogin: {
    pl: "Zaloguj",
    en: "Log in",
  },
  backToLogin: {
    pl: "Powrót do strony logowania",
    en: "Back to login",
  },
  actionReset: {
    pl: "Wyślij",
    en: "Send",
  },
  setPassword: {
    pl: "Ustaw hasło",
    en: "Set password",
  },

  actionRemember: {
    pl: "Przypomniałeś sobie?",
    en: "Did you remember?",
  },
  backToHomePage: {
    pl: "Strona logowania",
    en: "Login page",
  },
  dontRemeberPassword: {
    pl: "Nie pamiętasz hasła?",
    en: "Forgot password?",
  },
  dontHaveAccount: {
    pl: "Nie masz konta?",
    en: "Do not have an account yet?",
  },
  doYouHaveAccount: {
    pl: "Masz już konto?",
    en: "Already have an account?",
  },
  registerAccount: {
    pl: "Zarejestruj się",
    en: "Register",
  },
  loginAccount: {
    pl: "Zaloguj się",
    en: "Sign in",
  },
  verificationCodeInstruction: {
    pl: "Na podany adres wysłaliśmy wiadomość z kodem weryfikującym, wpisz kod z wiadomości w pole poniżej",
    en: "We have sent a message with a verification code to the provided address, enter the code from the message in the field below",
  },
  sendCodeAgain: {
    pl: "Wyślij nowy kod",
    en: "Send new code",
  },

  setting: {
    pl: "Ustawienia",
    en: "Settings",
  },
  logout: {
    pl: "Wyloguj",
    en: "Logout",
  },
  projectName: {
    pl: "Nazwa projektu",
    en: "Project name",
  },
  createDate: {
    pl: "Data utworzenia",
    en: "Created date",
  },
  changeName: {
    pl: "Zmień nazwę",
    en: "Change name",
  },
  deleteProject: {
    pl: "Usuń projekt",
    en: "Delete project",
  },
  addProject: {
    pl: "Dodaj projekt",
    en: "Add project",
  },
  newProject: {
    pl: "Nowy projekt",
    en: "New project",
  },
  editProject: {
    pl: "Edytuj projekt",
    en: "Edit project",
  },
  createProject: {
    pl: "Utwórz projekt",
    en: "Create project",
  },
  newSection: {
    pl: "Nowa sekcja",
    en: "New section",
  },
  addSection: {
    pl: "Dodaj sekcje",
    en: "Add section",
  },
  sectionName: {
    pl: "Nazwa sekcji",
    en: "Section name",
  },
  createSection: {
    pl: "Utwórz sekcje",
    en: "Create section",
  },
  editSection: {
    pl: "Edytuj sekcje",
    en: "Edit section",
  },
  deleteSection: {
    pl: "Usuń sekcje",
    en: "Delete section",
  },
  newRevision: {
    pl: "Wgraj nową rewizję",
    en: "Add new revision",
  },
  kks: {
    pl: "KKS",
    en: "KKS",
  },
  pdfFile: {
    pl: "Plik .pdf z projektem sekcji",
    en: ".pdf file with section project",
  },
  cancel: {
    pl: "Anuluj",
    en: "Cancel",
  },
  next: {
    pl: "Dalej",
    en: "Next",
  },
  back: {
    pl: "Wróć",
    en: "Back",
  },
  save: {
    pl: "Zapisz",
    en: "Save",
  },
  confirm: {
    pl: "Zatwierdź",
    en: "Confirm",
  },
  edit: {
    pl: "Edytuj",
    en: "Edit",
  },
  close: {
    pl: "Zamknij",
    en: "Close",
  },
  delete: {
    pl: "Usuń",
    en: "Delete",
  },
  duplicate: {
    pl: "Duplikuj",
    en: "Duplicate",
  },
  lock: {
    pl: "Zablokuj",
    en: "Lock",
  },
  add: {
    pl: "Dodaj",
    en: "Add",
  },
  unlock: {
    pl: "Odblokuj",
    en: "Unlock",
  },
  order: {
    pl: "Zamawiam",
    en: "Upgrade",
  },
  verify: {
    pl: "Weryfikuj",
    en: "Verify",
  },
  createAnAccount: {
    pl: "Załóż konto",
    en: "Create an account",
  },
  closeAccount: {
    pl: "Zamknij konto",
    en: "Close account",
  },

  setReference: {
    pl: "Ustaw referencje",
    en: "Set reference",
  },
  export: {
    pl: "Export",
    en: "Export",
  },
  selectFilters: {
    pl: "Wybierz filtry",
    en: "Select filters",
  },
  clearFilters: {
    pl: "Wyczyść filtry",
    en: "Reset filters",
  },
  clearSort: {
    pl: "Wyczyść sortowanie",
    en: "Reset sort",
  },

  editModalTitle: {
    pl: "Edytujesz",
    en: "Edit",
  },
  map: {
    pl: "Mapa",
    en: "Map",
  },
  list: {
    pl: "Lista",
    en: "List",
  },
  pointsMap: {
    pl: "Mapa punktów",
    en: "Points map",
  },
  pointsList: {
    pl: "Lista punktów",
    en: "Points list",
  },
  page: {
    pl: "Strona",
    en: "Page",
  },
  selectExistingJoint: {
    pl: "Wybierz istniejącą spoiną",
    en: "Select an existing weld",
  },
  reference: {
    pl: "Referencja",
    en: "Reference",
  },
  referencePanelTitle: {
    pl: "Wybierz punkt referencyjny",
    en: "Select reference point",
  },
  referencePanelEmpty: {
    pl: "Musisz dodać punkt aby móc wskazać go jako referencje",
    en: "You need to add a point to be able to point it as a reference",
  },
  noSelectedItem: {
    pl: "Nie jest wybrany żaden element",
    en: "No item is selected",
  },
  jointNumber: {
    pl: "Numer spoiny",
    en: "Weld joint number",
  },
  jointType: {
    pl: "Typ spoiny",
    en: "Weld joint type",
  },
  element_type: {
    pl: "Typ elementu",
    en: "Element type",
  },
  diameter_1: {
    pl: "Średnica 1",
    en: "Diameter 1",
  },
  diameter_2: {
    pl: "Średnica 2",
    en: "Diameter 2",
  },
  thickness_1: {
    pl: "Grubość 1",
    en: "Thickness 1",
  },
  thickness_2: {
    pl: "Grubość 2",
    en: "Thickness 2",
  },
  material_1: {
    pl: "Materiał 1",
    en: "Material 1",
  },
  material_2: {
    pl: "Materiał 2",
    en: "Material 2",
  },
  loading: {
    pl: "Trwa ładowanie ...",
    en: "Loading ...",
  },
  confirmDelete: {
    pl: "Potwierdź usunięcie",
    en: "Confirm delete",
  },
  doYouWantDeletePoint: {
    pl: "Czy chcesz usunąć ten punkt?",
    en: "Do you want delete this point",
  },
  doYouWantDeletePointWithReferences: {
    pl: "Ta spoina jest takze uzyta na innych rysunkach. Usuwając ją, usuniesz wszystkie pozostałe jej wystąpienia. Czy chcesz usunąć ten punkt?",
    en: "This weld is also used in other drawings. By deleting it, you will delete all other instances of it. Do you want to delete this point?",
  },
  doYouWantDeleteElement: {
    pl: "Czy chcesz usunąć ten element?",
    en: "Do you want delete this element",
  },
  doYouWantDeleteSection: {
    pl: "Czy chcesz usunąć tę sekcję wraz z wszystkimi punktami?",
    en: "Do you want to delete this section with all the points?",
  },
  doYouWantDeleteProject: {
    pl: "Czy chcesz usunąć ten projekt wraz z wszystkimi sekcjami oraz punktami?",
    en: "Do you want to delete this project with all sections and points?",
  },
  doYouWantDeleteContract: {
    pl: "Czy chcesz usunąć ten kontract wraz z wszystkimi projektami?",
    en: "Do you want to delete this contract along with all projects?",
  },
  doYouWantDeleteAccount: {
    pl: "Chcesz usunąć swoje konto. Nieodwracalnie utracisz dostęp do aplikacji. Czy jesteś pewien że chcesz to zrobić?",
    en: "You want to delete your account. You will irreversibly lose access to the application. Are you sure you want to do this?",
  },
  doYouWantDeleteUser: {
    pl: "Chcesz usunąć konto tego użytkownika. Nieodwracalnie utraci on dostęp do aplikacji. Czy jesteś pewien że chcesz to zrobić?",
    en: "You want to delete this user's account. He will irreversibly lose access to the application. Are you sure you want to do this?",
  },
  confirmAccountDelete: {
    pl: "Aby potwierdzić usunięcie konta wpisz swój adres email w pole poniżej",
    en: "To confirm account deletion, enter your e-mail address in the field below",
  },
  confirmUserAccountDelete: {
    pl: "Aby potwierdzić usunięcie konta wpisz adres email konta które chcesz usunąć w pole poniżej",
    en: "To confirm account deletion, enter the email address of the account you want to delete in the field below",
  },
  enterEmailAddress: {
    pl: "Wpisz adres email",
    en: "Enter your email address",
  },
  newItem: {
    pl: "Nowy element",
    en: "New item",
  },
  details: {
    pl: "Szczegóły",
    en: "Details",
  },
  edition: {
    pl: "Edycja:",
    en: "Edition:",
  },
  generateXlsxFile: {
    pl: "Generuj plik .xlsx",
    en: "Generate .xlsx file",
  },
  generatePdfFile: {
    pl: "Generuj mapę .pdf",
    en: "Generate .pdf map",
  },
  invoicesList: {
    pl: "Lista faktur",
    en: "Invoices list",
  },
  userLimitModal: {
    pl: "Limit użytkowników",
    en: "Users limit",
  },
  userLimiContent: {
    pl: "Osiągnąłeś limit użytkowników, nie można dodać nowego użytkownika. Aby dodać więcej użytkowników zmień plan",
    en: "You have reached your user limit, you cannot add a new user. To add more users, change your plan",
  },
  subscribtion: {
    pl: "Subskrypcja",
    en: "Subscription",
  },
  passwordCheckLength: {
    pl: "Minimum 6 znaków",
    en: "Minimum 6 characters",
  },
  passwordCheckLowercase: {
    pl: "Przynajmniej jedna mała litera",
    en: "At least one lowercase letter",
  },
  passwordCheckUppercase: {
    pl: "Przynajmniej jedna wielka litera",
    en: "At least one capital letter",
  },
  passwordCheckDigit: {
    pl: "Przynajmniej jedna cyfra",
    en: "At least one digit",
  },
  passwordCheckSpecial: {
    pl: "Przynajmniej jeden znak specjalny",
    en: "At least one special character",
  },
  passwordMatchCheckSpecial: {
    pl: "Hasła muszą być takie same",
    en: "Passwords must be the same",
  },

  paymentTitleFail: {
    pl: "Błąd płatności",
    en: "Payment error",
  },
  paymentTitleMissed: {
    pl: "Opłać usługi",
    en: "Pay for the services",
  },
  paymentTitleNotComplete: {
    pl: "Nieopłacona subskrypcja",
    en: "Unpaid subscription",
  },
  paymentContentSuccess: {
    pl: "Płatność zakończyła się sukcesem. Twoja subskrypcja jest aktywna. Możesz korzystać z aplikacji.",
    en: "The payment was successful. Your subscription is active. You can use the app.",
  },
  paymentContentFail: {
    pl: "Nie mogliśmy pobrać płatności za następny okres subskrypcji. Aby dalej korzystać z aplikacji aktualizuj ustawienia metody płatności.",
    en: "We were unable to collect payment for your next subscription period. To continue using the application, update your payment method settings.",
  },
  paymentContentMissed: {
    pl: "Aby dalej korzystać z systemu opłać swoją subskrypcję.",
    en: "To continue using the system, pay your subscription.",
  },
  finishSubskrypcjeAdmin: {
    pl: "Aby korzystać z aplikacji, sfinalizuj subskrypcje",
    en: "To use the app, please finalize your subscriptions.",
  },
  finishSubskrypcjeUser: {
    pl: "Twoja organizacja nie jest aktywna, skontaktuj się z administratorem swojej organizacji, aby dokończył proces rejestracji.",
    en: "Your organization is not active, please contact your organization administrator to complete the registration process.",
  },
  errorPaymentUser: {
    pl: "Twoja organizacja nie jest aktywna, skontaktuj się z administratorem swojej organizacji, aby opłacił subskrypcje.",
    en: "Your organization is not active, please contact your organization's administrator to pay for your subscriptions.",
  },
  finishSubsrybcionAction: {
    pl: "Dokończ płatność",
    en: "Complete your payment",
  },

  errorPaymentAction: {
    pl: "Zaktualizuj dane płatności",
    en: "Update your payment details",
  },
  email: {
    pl: "Email",
    en: "E-mail",
  },
  role: {
    pl: "Rola użytkownika",
    en: "User role",
  },
  accountType: {
    pl: "typ konta",
    en: "account type",
  },
  upgrade: {
    pl: "ulepsz",
    en: "upgrade",
  },
  billingAddress: {
    pl: "Adres rozliczeniowy",
    en: "Billing address",
  },
  billingSettings: {
    pl: "Ustawienia płatności",
    en: "Billing settings",
  },
  invoices: {
    pl: "Faktury",
    en: "Invoices",
  },
  deleteAccount: {
    pl: "Usuń konto",
    en: "Delete account",
  },
  unsubscribe: {
    pl: "Anuluj subskrypcję",
    en: "Cancel Subscription",
  },
  appVersion: {
    pl: "wersja aplikacji",
    en: "app version",
  },
  account: {
    pl: "Konto",
    en: "Account",
  },
  payment: {
    pl: "Płatności",
    en: "Payment",
  },
  paymentDetails: {
    pl: "Szczegóły płatności",
    en: "Payment details",
  },
  organizationInfos: {
    pl: "Informacje o organizacji",
    en: "Org info",
  },
  invoicesAndSettlements: {
    pl: "Faktury i rozliczenia",
    en: "Invoices and settlements",
  },
  administration: {
    pl: "Administracja",
    en: "Administration",
  },
  monthly: {
    pl: "miesięcznie",
    en: "monthly",
  },
  planComparison: {
    pl: "Porównanie planów",
    en: "Comparison of plans",
  },
  emptyStateProjectTitle: {
    pl: "Nie masz dodanego żadnego projektu",
    en: "You don't have any project added",
  },
  noContractsUserMode: {
    pl: "W twojej organizacji nie ma dodanych żadnych kontraktów, skontaktuj się z administratorem organizacji",
    en: "There are no contracts added in your organization, please contact your organization's administrator",
  },
  emptyStateSectionTitle: {
    pl: "Nie masz dodanej żadnej sekcji",
    en: "You don't have any section added",
  },
  emptyStateProjectListTitle: {
    pl: "Brak spoin w projekcie",
    en: "No welds in the project",
  },
  emptyStateContractsListTitle: {
    pl: "Nie masz dodanych żadnych kontraktów",
    en: "You have not added any contracts",
  },
  emptyStateStartPage: {
    pl: 'Aby rozpocząć dodaj najpierw kontrakt, potem możesz nimi zarządzać w zakładce "Organizacja"',
    en: 'To get started, first add a contract, then you can manage them in the "Organization" tab',
  },
  invoiceNumber: {
    pl: "Numer faktury",
    en: "Invoice number",
  },
  date: {
    pl: "Data",
    en: "Date",
  },
  price: {
    pl: "Kwota",
    en: "Price",
  },
  userExist: {
    pl: "Użytkownik już istnieje",
    en: "User exist",
  },
  wrongEmail: {
    pl: "Błędny format adresu email",
    en: "Wrong e-mail address format",
  },
  wrongCode: {
    pl: "Błędny kod",
    en: "Wrong code",
  },
  incorectEmail: {
    pl: "Adres email nie pasuje do zaproszenia",
    en: "The email address does not match the invitation",
  },
  serverError: {
    pl: "Wystąpił błąd w połączeniu z serwerem, spróbuj ponownie lub skontaktuj się z nami",
    en: "There was an error connecting to the server, please try again or contact us",
  },
  serverErrorTitle: {
    pl: "Błąd serwera",
    en: "Server error",
  },
  confirmSubscribtionPlan: {
    pl: "Wybrany plan",
    en: "Selected plan",
  },
  confirmSubscribtionPrice: {
    pl: "Kwota",
    en: "Price",
  },
  confirmSubscribtionFree: {
    pl: "Po kliknięciu 'Zamawiam' zostaniesz przeniesiony do systemu płatności, gdzie sfinalizujesz płatność.",
    en: "After clicking 'Order', you will be transferred to the system payments, where you will finalize your payment.",
  },
  confirmSubscribtionPaid: {
    pl: "Twoja subskrypcja zostanie zaktualizowana",
    en: "Your subscription will be updated",
  },
  scale: {
    pl: "Skala",
    en: "Scale",
  },
  organization: {
    pl: "Organizacja",
    en: "Organization",
  },
  organizationPanel: {
    pl: "Panel Organizacji",
    en: "Organization Panel",
  },
  users: {
    pl: "Użytkownicy",
    en: "Users",
  },
  contracts: {
    pl: "Kontrakty",
    en: "Contracts",
  },
  addContract: {
    pl: "Dodaj kontrakt",
    en: "Add contract",
  },
  addUser: {
    pl: "Dodaj użytkownika",
    en: "Add user",
  },
  createNewContract: {
    pl: "Utwórz nowy kontrakt",
    en: "Create new contract",
  },
  editContractName: {
    pl: "Edycja kontraktu",
    en: "Contract name",
  },
  contractName: {
    pl: "Edycja kontraktu",
    en: "Edit a contact",
  },
  contractNameTable: {
    pl: "Nazwa kontraktu",
    en: "Contract name",
  },
  contract: {
    pl: "Kontrakt",
    en: "Contract",
  },
  author: {
    pl: "Autor",
    en: "Author",
  },
  organizationDetails: {
    pl: "Organizacja",
    en: "Organization",
  },
  mapElementDot: {
    pl: "Punkt",
    en: "Point",
  },
  mapElementTriage: {
    pl: "Trójkąt",
    en: "Triage",
  },
  mapElementLine: {
    pl: "Linia",
    en: "Line",
  },
  mapElementCloud: {
    pl: "Chmura",
    en: "Cloud",
  },
  mapElementList: {
    pl: "Lista elementów",
    en: "Elements list",
  },
  jointList: {
    pl: "Lista spoin",
    en: "Joints list",
  },
  selectedElement: {
    pl: "Wybrany element",
    en: "Selected element",
  },
  selectedJoint: {
    pl: "Wybrana spoina",
    en: "Selected joint",
  },
  size: {
    pl: "Rozmiar",
    en: "Size",
  },
  rotation: {
    pl: "Obrót",
    en: "Rotation",
  },
  length: {
    pl: "Długość",
    en: "Length",
  },
  thickness: {
    pl: "Grubość",
    en: "Thickness",
  },
  sections: {
    pl: "Lista sekcji",
    en: "Sections list",
  },
  weldingLog: {
    pl: "Dziennik spawania",
    en: "Welding log",
  },
  existingJoint: {
    pl: "Istniejąca spoina",
    en: "Existing joint",
  },
  weldingDate: {
    pl: "Data spawania",
    en: "Welding date",
  },
  welder: {
    pl: "Spawacz",
    en: "Welder",
  },
  meltNumber_1: {
    pl: "Numer wytopu 1",
    en: "Melt number 1",
  },
  meltNumber_2: {
    pl: "Numer wytopu 2",
    en: "Melt number 2",
  },
  vTExaminationNumber: {
    pl: "Numer badania VT",
    en: "VT examination number",
  },
  vTExaminationDate: {
    pl: "Data badania VT",
    en: "VT examination date",
  },
  vTExaminationResult: {
    pl: "Wynik badania VT",
    en: "VT examination result",
  },
  rTExaminationNumber: {
    pl: "Numer badania RT",
    en: "RT examination number",
  },
  rTExaminationDate: {
    pl: "Data badania RT",
    en: "RT examination date",
  },
  rTExaminationResult: {
    pl: "Wynik badania RT",
    en: "RT examination result",
  },
  pTExaminationNumber: {
    pl: "Numer badania PT",
    en: "PT examination number",
  },
  pTExaminationDate: {
    pl: "Data badania PT",
    en: "PT examination date",
  },
  pTExaminationResult: {
    pl: "Wynik badania PT",
    en: "PT examination result",
  },
  uTExaminationNumber: {
    pl: "Numer badania UT",
    en: "UT examination number",
  },
  uTExaminationDate: {
    pl: "Data badania UT",
    en: "UT examination date",
  },
  uTExaminationResult: {
    pl: "Wynik badania UT",
    en: "UT examination result",
  },

  mTExaminationNumber: {
    pl: "Numer badania MT",
    en: "MT examination number",
  },
  mTExaminationDate: {
    pl: "Data badania MT",
    en: "MT examination date",
  },
  mTExaminationResult: {
    pl: "Wynik badania MT",
    en: "MT examination result",
  },
  exportWeldMap: {
    pl: "Eksport mapy spoin",
    en: "Joint map export",
  },
  exportWeldLog: {
    pl: "Eksport dziennika spawania",
    en: "Welding log export",
  },
  forSection: {
    pl: "dla sekcji",
    en: "for section",
  },
  forProject: {
    pl: "dla projektu",
    en: "for project",
  },
  projects: {
    pl: "Projekty",
    en: "Projects",
  },
  contractMembers: {
    pl: "Użytkownicy w kontrakcie",
    en: "Users in contract",
  },
  emptyContractMembers: {
    pl: "Wybierz kontrakt aby wyświetlić jego szczegóły",
    en: "Select a contract to view its details",
  },
  emptyMemberPanel: {
    pl: "Wybierz użytkownika aby wyświetlić jego szczegóły",
    en: "Select a member to view their details",
  },
  noMembersInContract: {
    pl: "Do tego kontraktu nie jest przypisany żaden użytkownik",
    en: "No user is assigned to this contract",
  },
  noContractsInMember: {
    pl: "Ten użytkownik nie jest przypisany do żadnego kontraktu",
    en: "This user is not assigned to any contract",
  },
  confirmRemoveUserFromOrg: {
    pl: "Czy chesz usunąć",
    en: "Do you want remove",
  },
  fromContract: {
    pl: "z kontraktu: ",
    en: "from contract: ",
  },
  addMemeberAction: {
    pl: "Przypisz użytkownika",
    en: "Assign a memeber",
  },
  deleteContract: {
    pl: "Usuń kontrakt",
    en: "Delete a contract",
  },
  addMemeberToContractTitle: {
    pl: "Przypisz użytkownika do kontraktu",
    en: "Assign a user to the contract",
  },
  addContractToMemberTitle: {
    pl: "Dodaj kontrakt do użytkownika",
    en: "Add a contract to the user",
  },
  noAvailableMembers: {
    pl: "Wszyscy użytkownicy są przypisani do tego kontraktu",
    en: "All users are assigned to this contract",
  },
  noAvailableContract: {
    pl: "Użytkownik przypisany jest do wszystkich projektów",
    en: "The user is assigned to all projects",
  },
  membersDetails: {
    pl: "Szczegóły użytkownika",
    en: "User details",
  },
  addContractToMemeber: {
    pl: "Przypisz do kontraktu",
    en: "Assign to contract",
  },
  editUser: {
    pl: "Edytuj użytkownika",
    en: "Edit user",
  },
  deleteUser: {
    pl: "Usuń użytkownika",
    en: "Delete user",
  },
  cancelInvitation: {
    pl: "Anuluj zaproszenie",
    en: "Cancel invitation",
  },
  dateSent: {
    pl: "Data wysłania",
    en: "Date sent",
  },
  invitationsSent: {
    pl: "Wysłane zaproszenia",
    en: "Invitations sent",
  },
  organizations: {
    pl: "Organizacje",
    en: "Organizations",
  },
  organizationName: {
    pl: "Nazwa organizacji",
    en: "Organization name",
  },
  licenses: {
    pl: "Licencje",
    en: "Licenses",
  },
  usageSpace: {
    pl: "Użyta przestrzeń dyskowa",
    en: "Disk space used",
  },
  activeSince: {
    pl: "Aktywna od",
    en: "Active since",
  },
  activeTo: {
    pl: "Aktywna od",
    en: "Active to",
  },
  nextPay: {
    pl: "Następna płatność",
    en: "Next pay",
  },
  validUntil: {
    pl: "ważna do",
    en: "valid until",
  },
  paymentMethod: {
    pl: "Metoda płatności",
    en: "Payment method",
  },
  drawingNumber: {
    pl: "Numer rysunku",
    en: "Drawing number",
  },
  noUsersInOrg: {
    pl: "W organizacji nie żadnych użytkowników",
    en: "There are no users in the organization",
  },
  fiveUsers: {
    pl: "5 uzytkowników",
    en: "5 users",
  },
  vatPerMonth: {
    pl: "VAT / miesiąc",
    en: "VAT / month",
  },
  companyName: {
    pl: "Nazwa firmy",
    en: "Company name",
  },
  companyAdressLine1: {
    pl: "Adres firmy",
    en: "Company address",
  },
  companyAdressLine2: {
    pl: "Adres firmy cd ..",
    en: "ompany address cd ..",
  },
  companyPostCode: {
    pl: "Kod pocztowy",
    en: "Post code",
  },
  companyCity: {
    pl: "Miasto",
    en: "City",
  },
  companyCountry: {
    pl: "Kraj",
    en: "Country",
  },
  companyNip: {
    pl: "NIP",
    en: "VAT EU",
  },
  invoiceEmail: {
    pl: "Adres email do wysyłki faktur",
    en: "E-mail address for sending invoices",
  },

  editBillingAddressModalTitle: {
    pl: "Edycja danych rozliczeniowych",
    en: "Editing billing data",
  },
  editOrgNameModalTitle: {
    pl: "Edycja nazwy organizacji",
    en: "Editing organization name",
  },
  editOrgEmailModalTitle: {
    pl: "Edycja adresu email",
    en: "Editing email address",
  },
  poland: {
    pl: "Polska",
    en: "Poland",
  },
  confirmCancelSubscription: {
    pl: "Potwierdź anulowanie subskrypcji",
    en: "Confirm your subscription cancellation",
  },
  ubscriptionCanceled: {
    pl: "Subskrypcja anulowana",
    en: "Subscription canceled",
  },
  doYouWantCanelSubscription: {
    pl: "Czy na pewno chcesz anulować subskrypcję? Po anulowaniu dostęp do usługi wygaśnie z końcem bieżącego okresu rozliczeniowego. Zostaną usunięte wtedy wszystkie dane oraz wszyscy użytkownicy Twojej organizacji.",
    en: "Are you sure you want to cancel your subscription? Once canceled, your access to the service will expire at the end of your current billing period. All data and all users of your organization will then be deleted",
  },
  enterCANCEL: {
    pl: "wpisz THE END aby potwierdzić",
    en: "type THE END to confirm",
  },
  cancelSubscription: {
    pl: "Anuliuj subskrypcje",
    en: "Cancel subscription",
  },
  alreadyCanceled: {
    pl: "Subskrypcja została anulowana, możesz korzystać z aplikacji do końca bieżącego okresu rozliczeniowego. Po tym okresie dostęp do aplikacji zostanie zablokowany a wszystkie dane nieodwracalnie usunięte",
    en: "The subscription has been canceled, you can use the application until the end of the current billing period. After this period, access to the application will be blocked and all data will be irreversibly deleted",
  },
  youCanRevert: {
    pl: "Do końca bieżącego okresu rozliczeniowego możesz cofnąć tą akcję",
    en: "You can undo this action until the end of the current billing period",
  },
  registerTerms_prefix:{
    pl:'Zapoznałem/am się z',
    en:'I have read the'
  },
  registerTerms_termsOfUSe:{
    pl:'Regulaminem',
    en:'Terms of use'
  },
  registerTerms_and:{
    pl:'i',
    en:'and'
  },
  registerTerms_policy:{
    pl:'Polityką prywatności',
    en:'Privacy Policy'
  },
  registerTerms_sufix:{
    pl:'oraz akceptuję ich treść.',
    en:'and accept their content.'
  },
  registerTerms_emailSubscription:{
    pl:'Wyrażam zgodę na otrzymywanie newslettera oraz informacji handlowych, w tym o promocjach i nowościach, na podany adres e-mail. Wiem, że mogę wycofać zgodę w dowolnym momencie.',
    en:'I consent to receiving the newsletter and commercial information, including promotions and new products, to the e-mail address provided. I know that I can withdraw my consent at any time.'
  },
};

/*
  pl:'',
  en:''
  
  {t(dictionary.loginTitle)}
  
  */

import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Section, Aligment, Text, TextLabel } from "venice-ui";
import { Content, ContentFull, OrgDetailsLoader } from "../components";
import packageJson from "../../../package.json";
import { getContractsIDs, t } from "../../helpers/userHelper";
import { apiCall } from "../../untils/apiCall";
import { DeleteUserModal } from "../Modals";
import { dictionary } from "../../untils/dictionary";
import { useStoreState } from "../../store";
import { SettingRow } from "./SettingsRow";
import { LoadingContent } from "../layouts/Loading";
import { Colors } from "../../untils/Theme";

export const Settings: FC = () => {
  const navigate = useNavigate();

  const { email, uid, orgRole, orgID,contracts } = useStoreState(
    (state) => state.user.userDetails
  );

  const [deleteUserModal, toogleDeleteUserModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [pageData, setPageData] = useState({
    loading: true,
    isLastAdmin: false,
  });

  useEffect(() => {
    if (orgRole === "admin") {
      const options = {
        orgID: orgID,
        userID: uid,
      };
      apiCall("organization/isLastAdmin", options, (response: any) => {
        setPageData({
          loading: false,
          isLastAdmin: response.isLastAdmin,
        });
      });
    } else {
      setPageData({
        loading: false,
        isLastAdmin: false,
      });
    }
  }, []);

  const handleDeleteButtonClick = () => {
    if (!pageData.isLastAdmin) {
      toogleDeleteUserModal(true);
    }
  };
  const deleteUser = async () => {
    setDeleteLoader(true);

    const options = {
      userID: uid,
      contractsIDs: getContractsIDs(uid!, contracts)
    };
    await apiCall("user/deleteUser", options, (response: any) => {
      if (response.error) {
        navigate("/server-error");
      } else {
        navigate("/");
      }
    });
  };
  if (pageData.loading) {
    return (
      <Aligment direction="column">
        <OrgDetailsLoader>
          <ContentFull>
            <LoadingContent />
          </ContentFull>
        </OrgDetailsLoader>
      </Aligment>
    );
  }

  return (
    <Content>
      <Card
        title={t(dictionary.setting)}
        handleBack={() => navigate(-1)}
        shadow={false}
      >
        <Aligment direction="row">
          <Section noPadding={true} title={t(dictionary.account)}>
            <Aligment direction="column" align="flex-start">
              <SettingRow title={t(dictionary.email)}>
                <Text>{email}</Text>
              </SettingRow>
            </Aligment>
          </Section>
          <Section title={t(dictionary.administration)} noPadding={true}>
            <Text
              color={Colors.red}
              isDisabled={pageData.isLastAdmin}
              action
              onClick={handleDeleteButtonClick}
            >
              {t(dictionary.deleteAccount)}
            </Text>
          </Section>
          {t(dictionary.appVersion)}: {packageJson.version}
        </Aligment>
      </Card>

      {deleteUserModal && (
        <DeleteUserModal
          handleCancel={() => toogleDeleteUserModal(false)}
          handleDelete={deleteUser}
          loader={deleteLoader}
          userID={uid || ""}
        />
      )}
    </Content>
  );
};

import React, { FC } from "react";
import { DeleteModal, DeleteSectionModal } from "../Modals";
import { SiteProps } from "./Section";
import { getItemName } from "./sectionModel";
import { useStoreState } from "../../store";

interface ISectionModalsProps {
  siteProps: SiteProps;
  hideDeleteModal: () => void;
  hideDeleteSectionModal: () => void;
  deleteElement: (id: string, isMapElement: boolean,isReference: boolean) => void;
  deleteSection: (id: string) => void;
}

export const SectionModals: FC<ISectionModalsProps> = ({
  siteProps,
  hideDeleteModal,
  hideDeleteSectionModal,
  deleteElement,
  deleteSection,
}) => {
  const { elements } =
    useStoreState((state) => state.projectDetails.projectDetails);
  return (
    <>
      {siteProps.delete.show && (
        <DeleteModal
          handleCancel={hideDeleteModal}
          handleDelete={deleteElement}
          id={siteProps.delete.id}
          type={siteProps.details.type}
          name={getItemName(elements, siteProps.delete.id)}
          
        />
      )}
      
      {siteProps.deleteSection.show && (
        <DeleteSectionModal
          handleCancel={hideDeleteSectionModal}
          handleDelete={deleteSection}
          id={siteProps.deleteSection.id}
          name={siteProps.name}
        />
      )}
    </>
  );
};

import React, { FC, ReactText, useState } from "react";
import { Aligment, Input, Modal, Text } from "venice-ui";
import { t } from "../../helpers/userHelper";
import { dictionary } from "../../untils/dictionary";
import { IntputWrapperBig } from "../SingIn/Login.styles";

interface ICancelSubscriptioModal {
  handleCancel: () => void;
  handleAction: () => void;
  handleConfirm: () => void;
  loader: boolean;
  confirm: boolean;
}

export const CancelSubscriptioModal: FC<ICancelSubscriptioModal> = ({
  handleCancel,
  handleAction,
  handleConfirm,
  loader,
  confirm,
}) => {
  const phaseToCheck = "THE END";
  const [pageData, setPageData] = useState({
    phase: "",
  });

  const setEmailValue = (field: string, value: string) => {
    setPageData({
      ...pageData,
      [field]: value,
    });
  };

  const validation = () => {
    return confirm ? true : pageData.phase !== phaseToCheck;
  };
  const onClose = () => {
    if (!loader) {
      handleCancel();
    }
  };
  return (
    <Modal
      title={
        confirm
          ? t(dictionary.ubscriptionCanceled)
          : t(dictionary.confirmCancelSubscription)
      }
      labelClose={confirm ? t(dictionary.close) : t(dictionary.back)}
      handleClose={confirm ? handleConfirm : onClose}
      handleConfirm={confirm ? undefined : handleAction}
      labelConfirm={confirm ? undefined : t(dictionary.cancelSubscription)}
      submitDisabled={validation()}
      submitLoader={loader}
    >
      <Aligment gap={20}>
        {confirm ? (
          <>
          <Text>{t(dictionary.alreadyCanceled)}</Text>
          <Text>{t(dictionary.youCanRevert)}</Text>
          </>
        ):(
          <Text> {t(dictionary.doYouWantCanelSubscription)}</Text> 
        )}
        
        {!confirm && (
          <IntputWrapperBig>
            <Input
              label={t(dictionary.enterCANCEL)}
              type="text"
              labelPosition="top"
              value={pageData.phase}
              name="phase"
              handleChange={(name: string, value: ReactText) =>
                setEmailValue("phase", value as string)
              }
            />
          </IntputWrapperBig>
        )}
      </Aligment>
    </Modal>
  );
};

import React, { FC, ReactText } from "react";
import { Aligment, Input, TextSmall, Toogle } from "venice-ui";
import {
  IntputWrapperBig,
  LoginFormSection,
  LoginSubtitle,
} from "../Login.styles";
import { t } from "../../../helpers/userHelper";
import { dictionary } from "../../../untils/dictionary";
import { LinkSmall } from "../../components";

interface IRegisterEmailProps {
  loginValue: string;
  termsValue: boolean;
  emailSubscriptionValue: boolean;
  handleChange: (name: string, value: ReactText) => void;
  handleToogle: (name: string, value: boolean) => void;
  handleSubmit: () => void;
}

export const RegisterEmail: FC<IRegisterEmailProps> = ({
  loginValue,
  termsValue,
  emailSubscriptionValue,
  handleChange,
  handleToogle,
  handleSubmit,
}) => {
  return (
    <>
      <LoginSubtitle>{t(dictionary.registerSubtitle)}</LoginSubtitle>
      <LoginFormSection>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.inputLabelEmail)}
            type="text"
            labelPosition="top"
            value={loginValue}
            name="login"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, (value as string).trim())
            }
            autoFocus={true}
            handleSubmit={handleSubmit}
          />
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Aligment align="flex-start" justify="flex-start" gap={8} direction="row" wrap="nowrap">
            <Toogle
              handleClick={(value: boolean) =>
                handleToogle("acceptTerms", value)
              }
              value={termsValue}
              size="small"
            />
            <TextSmall>
              {`${t(dictionary.registerTerms_prefix)} `}{" "}
              <LinkSmall href="#">
                {t(dictionary.registerTerms_termsOfUSe)}
              </LinkSmall>
              {` ${t(dictionary.registerTerms_and)} `}
              <LinkSmall href="#">
                {t(dictionary.registerTerms_policy)}
              </LinkSmall>
              {` ${t(dictionary.registerTerms_sufix)}`}
            </TextSmall>
          </Aligment>
        </IntputWrapperBig>
        <IntputWrapperBig>
          <Aligment align="flex-start" justify="flex-start" gap={8} direction="row" wrap="nowrap">
            <Toogle
              handleClick={(value: boolean) =>
                handleToogle("emailSubscription", value)
              }
              value={emailSubscriptionValue}
              size="small"
            />
            <TextSmall>
              {t(dictionary.registerTerms_emailSubscription)}{" "}
            </TextSmall>
          </Aligment>
        </IntputWrapperBig>
      </LoginFormSection>
    </>
  );
};

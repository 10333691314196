import React, { FC, ReactText } from "react";
import { Input } from "venice-ui";
import { PasswordCheck } from "../../PasswordCheck";
import { IntputWrapperBig, LoginFormSection, LoginSubtitle } from "../Login.styles";
import { t } from "../../../helpers/userHelper";
import { dictionary } from "../../../untils/dictionary";

interface IRegisterPasswordProps {
  inputValue: string;
  handleChange: (name: string, value: ReactText) => void;
  handleSubmit:()=>void
}
export const RegisterPassword: FC<IRegisterPasswordProps> = ({
  inputValue,
  handleChange,
  handleSubmit
}) => {
  
  

  return (
    <>
      <LoginSubtitle> {t(dictionary.passwordSubtitle)} 
      </LoginSubtitle>
      <LoginFormSection>
        <IntputWrapperBig>
          <Input
            label={t(dictionary.inputLabelPassword)}
            type="password"
            labelPosition="top"
            value={inputValue}
            name="password"
            handleChange={(name: string, value: ReactText) =>
              handleChange(name, value as string)
            }
            handleSubmit={handleSubmit}
            autoFocus={true}
          />
          <PasswordCheck password={inputValue} />
        </IntputWrapperBig>
      </LoginFormSection>
    </>
  );
};

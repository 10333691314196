import { getDownloadURL, getStorage, ref } from "firebase/storage";

export const getFileUrl = async (pathToFile: string) => {
  let fileUrl =''
  const storage = getStorage();
  await getDownloadURL(ref(storage, pathToFile)).then((url) => {
    fileUrl=url
  });

  return fileUrl;
};

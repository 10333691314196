import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppWrapper, ContentArea } from "../components/components";
import Header from "../components/layouts/Header";
import { GlobalLoadingPage } from "../components/layouts/Loading";
import { PAYMENT_MODE } from "../helpers/settingsHelper";
import { useStoreState } from "../store";
import { ACCOUNT_TYPE } from "../store/interface/user";
import { PaymenetMiss } from "../components/Payments/PaymenetMiss";
import { PaymnetError } from "../components/Payments/PaymnetError";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { uid, loading, orgStatus } = useStoreState(
    (state) => state.user.userDetails
  );

  let location = useLocation();
  if (loading) {
    return <GlobalLoadingPage />;
  }
  if (!uid) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const getPage = () => {
    switch (orgStatus) {
      case PAYMENT_MODE.ACTIVE:
        return children;
        break;
      case PAYMENT_MODE.CANCEL:
        return children;
        break;
      case PAYMENT_MODE.NEW:
        return <PaymenetMiss />;
        break;
      case PAYMENT_MODE.PAYMENT_ERROR:
        return <PaymnetError />;
        break;

      default:
        break;
    }
  };
  return (
    <AppWrapper>
      <Header />
      <ContentArea>{getPage()}</ContentArea>
    </AppWrapper>
  );
}

export function PaymentFlow({ children }: { children: JSX.Element }) {
  const { uid, loading } = useStoreState((state) => state.user.userDetails);
  let location = useLocation();
  if (loading) {
    return <GlobalLoadingPage />;
  }
  if (!uid) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <AppWrapper>
      <Header />
      <ContentArea>{children}</ContentArea>
    </AppWrapper>
  );
}

export function AutoAuth({ children }: { children: JSX.Element }) {
  const { uid, loading } = useStoreState((state) => state.user.userDetails);

  let location = useLocation();
  if (loading) {
    return <GlobalLoadingPage />;
  }
  if (uid) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}

export function SuperAdmin({ children }: { children: JSX.Element }) {
  const { uid, loading, orgStatus, accountType } = useStoreState(
    (state) => state.user.userDetails
  );
  let location = useLocation();

  if (loading) {
    return <GlobalLoadingPage />;
  }
  if (!uid) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (accountType === ACCOUNT_TYPE.normal) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <AppWrapper>
      <Header />
      <ContentArea>{!orgStatus ? <PaymnetError /> : children}</ContentArea>
    </AppWrapper>
  );
}

import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Aligment } from "venice-ui";
import { Colors } from "../../untils/Theme";
import { Account } from "../Account";
import logo from "../../icon_white.svg";
import { useStoreState } from "../../store";
import { HeaderLink, HeaderOption } from "../components";
import { dictionary } from "../../untils/dictionary";
import { t } from "../../helpers/userHelper";

const HeaderWrapper = styled.div`
  height: 4rem;
  background-color: ${Colors.appBlue};
  color: ${Colors.white};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  position: sticky;
  z-index: 1000;
  top: 0;
`;
const AppName = styled.div`
  font-size: 1.6rem;
  padding: 0 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
`;

const Logo = styled.img`
  max-height: 26px;
`;
const Header: FC = () => {
  const { orgRole, accountType } = useStoreState(
    (state) => state.user.userDetails
  );
  const location = useLocation();
  const isOrganizationPage = location.pathname.includes("organization");
  const navigate = useNavigate();
  return (
    <HeaderWrapper>
      <Aligment justify={"flex-start"} direction="" wrap="nowrap" gap={10}>
        <AppName onClick={() => navigate(`/`)}>
          <Logo src={logo} />
        </AppName>
        <Aligment width={"auto"} gap={10}>
          <HeaderLink
            active={!isOrganizationPage}
            onClick={() => navigate(`/`)}
          >
            {t(dictionary.projects)}
          </HeaderLink>

          {orgRole === "admin" && (
            <HeaderLink
              active={isOrganizationPage}
              onClick={() => navigate(`/organization`)}
            >
              {t(dictionary.organization)}
            </HeaderLink>
          )}
        </Aligment>
      </Aligment>
      <Aligment justify={"flex-end"}>
        {accountType === "superAdmin" && (
          <HeaderOption onClick={() => navigate(`/adminpanel`)}>
            admin panel
          </HeaderOption>
        )}
        <Account />
      </Aligment>
    </HeaderWrapper>
  );
};

export default Header;

import { FC } from "react";
import { Card, Section, Aligment, Icon, Button, Text } from "venice-ui";
import { InfoContent } from "../../components";

interface IPaymentTemplate {
  title: string;
  content: string;
  isAdmin: boolean;
  onClick?: () => void;
  butonLabel?: string;
}
export const PaymentTemplate: FC<IPaymentTemplate> = ({
  title,
  content,
  isAdmin,
  onClick,
  butonLabel = "",
}) => {
  return (
    <Card title={title} shadow={false} >
      <InfoContent>
        <Aligment direction="column" gap={20}>
          <Icon name="error" size={56} iconColor="#ad1616" isActive={false} iconBgHoverColor="transparent" />
          <Text>{content}</Text>
          {isAdmin && onClick && <Button onClick={onClick} text={butonLabel} />}
        </Aligment>
        </InfoContent>
    </Card>
  );
};
